import * as React from 'react';
import { AddPeopleScreen } from './PermissionWizardScreen/AddPeopleScreen';
import { ConfirmationScreen } from './PermissionWizardScreen/ConfirmationScreen';
import { MessageScreen } from './PermissionWizardScreen/MessageScreen';
import { SelectProductsScreen } from './PermissionWizardScreen/SelectProductsScreen';
import { SelectShopsScreen } from './PermissionWizardScreen/SelectShopsScreen';
import { SetPermissionsScreen } from './PermissionWizardScreen/SetPermissionsScreen';

interface IWizardScreen<T = any> {
  key: string;
  title?: string;
  shortTitle?: string;
  description?: string | ((...args: unknown[]) => string);
  component: React.FC<T>;
}

export const WIZARD_SCREENS: IWizardScreen[] = [
  {
    key: 'add-people',
    title: 'Invite new person',
    shortTitle: 'Details',
    description:
      'Enter the details below for the recipient of these insights.\nOnce added, you will be able to configure permissions before they receive supplier insights.',
    component: AddPeopleScreen,
  },
  {
    key: 'select-products',
    title: 'Select products',
    shortTitle: 'Products',
    description:
      'Select the product(s) of which you would like to share supplier insights.',
    component: SelectProductsScreen,
  },
  {
    key: 'select-shops',
    title: 'Select Shops',
    shortTitle: 'Shops',
    description:
      'Select the shop(s) of which you would like to share supplier insights.',
    component: SelectShopsScreen,
  },
  {
    key: 'set-permissions',
    title: 'Select Measures',
    shortTitle: 'Measures',
    description: 'Select the measure(s) you wish to share.',
    component: SetPermissionsScreen,
  },
  {
    key: 'message',
    title: 'Send Access',
    shortTitle: 'Message',
    description: (email) =>
      `An email will be sent to ${email} giving them the ability to accept the invitation. \n You can add a personal message below.`,
    component: MessageScreen,
  },
  {
    key: 'confirm',
    component: ConfirmationScreen,
  },
];
