import { getCurrentAppInfo } from '@tapestry/shared/utils';
import { IMetric, THREAD_TYPE, MeasureSlug } from '@tapestry/types';
import { checkMetricPermissions } from '../../utils';
import { METRICS } from '@tapestry/shared/constants';

const {
  appInfo: { slug },
} = getCurrentAppInfo();

const byThread = (
  measure: IMetric,
  activeThreadKind: THREAD_TYPE,
  isGroupView: boolean
) => {
  return checkMetricPermissions(measure, activeThreadKind, null, {
    isGroupView,
    app: slug,
  });
};

const byFeatureFlags = (measure: IMetric, flags) => {
  const {
    tempRetailHeartbeatStockLevelMeasure: hasStockLevelMeasureFlag,
    supplierMarketShareMeasure,
  } = flags;

  if (measure.key === MeasureSlug.StockLevel) {
    return hasStockLevelMeasureFlag;
  }

  if (measure.key === MeasureSlug.SupplierMarketShare) {
    return supplierMarketShareMeasure;
  }

  return true;
};

export const getThreadEnabledMetricList = (
  flags,
  activeThreadKind: THREAD_TYPE,
  isGroupView: boolean
) => {
  return METRICS.filter((metric) =>
    byThread(metric, activeThreadKind, isGroupView)
  ).filter((metric) => byFeatureFlags(metric, flags));
};

export const getActiveMetricKeys = (
  flags,
  activeThreadKind: THREAD_TYPE,
  isGroupView: boolean
) => {
  const threadEnabledMetricList = getThreadEnabledMetricList(
    flags,
    activeThreadKind,
    isGroupView
  );

  const activeMetricKeys = threadEnabledMetricList
    .filter(({ disabled }) => !disabled)
    .map(({ key }) => key);

  return activeMetricKeys;
};
