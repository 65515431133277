import * as React from 'react';
import { CalendarIcon, CloseIcon } from '@tapestry/shared/icons';
import { Circle, DynamicDateRangePicker } from '@tapestry/weave';
import { IsoString } from '@tapestry/types';
import { APP_DEFAULT_TIMEZONE } from '@tapestry/shared/constants';
import { twMerge } from 'tailwind-merge';
import { useAppMediaQuery } from '@tapestry/weave';
import {
  convertUTCdatetimeAsIfItWasInShopTimezone,
  mapISODatetimeRangetoDateRange,
  getDateLabel,
} from '../../utils';
import { dateTime } from '@tapestry/shared/utils';

interface IHeartbeatDateRangeSelectorProps {
  startDate: string;
  endDate: string;
  onReset?: () => void;
  currentShopTimezone?: string;
  popperPosition?: 'bottom-end' | 'bottom-start';
  onDateSelection: (period: { startDate: string; endDate: string }) => void;
}

const maxDate = dateTime.now().endOf('year').toDate();

const HeartbeatDateRangeSelector: React.FC<
  React.PropsWithChildren<IHeartbeatDateRangeSelectorProps>
> = ({
  startDate,
  endDate,
  onReset,
  currentShopTimezone = APP_DEFAULT_TIMEZONE,
  popperPosition,
  onDateSelection,
}) => {
  const dateRange = mapISODatetimeRangetoDateRange([startDate, endDate]);

  const { isPhone, isComputerAndUp } = useAppMediaQuery();

  const handleDateChange = (values: [IsoString, IsoString]) => {
    const customPeriod = convertUTCdatetimeAsIfItWasInShopTimezone(
      values,
      currentShopTimezone
    );

    onDateSelection(customPeriod);
  };

  const handleOnReset = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();

    if (onReset) {
      onReset();
    }
  };

  return (
    <DynamicDateRangePicker
      value={dateRange}
      onChange={handleDateChange}
      withPresets
      showDoubleView={!isPhone}
      popperPosition={
        popperPosition || (isComputerAndUp ? 'bottom-end' : undefined)
      }
      minDate={undefined}
      maxDate={maxDate}
    >
      {({ toggleButtonProps }) => (
        <button
          className={twMerge(
            'hover:border-primary focus:border-primary active:border-primary-dark flex w-full flex-shrink-0 items-center  justify-center rounded-full border-2 bg-white px-4 py-4 text-sm font-bold text-black shadow-sm focus:outline-none md:mx-auto md:w-max md:px-6',
            onReset ? 'border-primary' : 'border-gray-border'
          )}
          {...toggleButtonProps}
        >
          <CalendarIcon className="xs:block mr-4 hidden h-4 hover:ring-1 md:h-6" />

          <span className="truncate-2-lines">
            {getDateLabel(dateRange[0], dateRange[1], currentShopTimezone)}
          </span>

          {onReset ? (
            <div
              role="button"
              className="group ml-4 flex h-6 w-6 items-center justify-center"
              onClick={handleOnReset}
            >
              <Circle
                size="h-4 w-4"
                className="group-hover:bg-primary bg-black text-white"
              >
                <CloseIcon className="h-2 w-2" fillColor="currentColor" />
              </Circle>
            </div>
          ) : null}
        </button>
      )}
    </DynamicDateRangePicker>
  );
};

export { HeartbeatDateRangeSelector };
export type { IHeartbeatDateRangeSelectorProps };
