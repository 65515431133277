import * as React from 'react';
import { twMerge } from 'tailwind-merge';

import { CloseIcon, GraphMetricIcon } from '@tapestry/shared/icons';
import { Circle } from '@tapestry/weave';

interface IComparisonButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  onReset?: () => void;
  label?: string;
}

const ComparisonButton: React.FC<IComparisonButtonProps> = ({
  onClick,
  onReset,
  label = 'Compare',
}) => {
  const handleOnReset = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();

    if (onReset) {
      onReset();
    }
  };

  return (
    <button
      onClick={onClick}
      title="Compare"
      className={twMerge(
        'hover:border-primary focus:border-primary active:border-primary-dark flex flex-shrink-0 items-center justify-center rounded-full border-2 bg-white p-4 font-bold text-black shadow-sm focus:outline-none lg:mx-auto lg:w-max lg:px-6 lg:py-4',
        onReset ? 'border-primary' : 'border-gray-border'
      )}
    >
      <GraphMetricIcon className="h-5 md:h-6 lg:mr-4" />
      <span className="hidden lg:block">{label}</span>

      {onReset ? (
        <div
          role="button"
          aria-label="reset"
          className="group ml-4 flex h-6 w-6 items-center justify-center"
          onClick={handleOnReset}
        >
          <Circle
            size="h-4 w-4"
            className="group-hover:bg-primary bg-black text-white"
          >
            <CloseIcon className="h-2 w-2" fillColor="currentColor" />
          </Circle>
        </div>
      ) : null}
    </button>
  );
};

export { ComparisonButton };
export type { IComparisonButtonProps };
