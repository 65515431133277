import { ITopSupplierSortByEnum } from '@tapestry/shared/graphql';
import {
  UseSortMenuOpenCloseAction,
  UseSortMenuSortAction,
} from '@tapestry/shared/hooks';

export const SORT_ITEMS = [
  {
    label: 'Highest Value First',
    shorthand: 'Highest First',
    id: ITopSupplierSortByEnum.ValueDesc,
  },
  {
    label: 'Lowest Value First',
    shorthand: 'Lowest First',
    id: ITopSupplierSortByEnum.ValueAsc,
  },
  {
    label: 'Alphabetical A > Z',
    shorthand: 'A > Z',
    id: ITopSupplierSortByEnum.AZ,
  },
  {
    label: 'Alphabetical Z > A',
    shorthand: 'Z > A',
    id: ITopSupplierSortByEnum.ZA,
  },
];

// **********************
type TopSupplierSortMenuState = {
  shouldOpenSortMenu: boolean;
  sortBy: ITopSupplierSortByEnum;
};

export const initialState: TopSupplierSortMenuState = {
  shouldOpenSortMenu: false,
  sortBy: ITopSupplierSortByEnum.ValueDesc,
};

export const sortReducer = (
  state = initialState,
  {
    type,
  }: UseSortMenuSortAction<ITopSupplierSortByEnum> | UseSortMenuOpenCloseAction
): TopSupplierSortMenuState => {
  switch (type) {
    case ITopSupplierSortByEnum.AZ:
      return {
        ...state,
        shouldOpenSortMenu: false,
        sortBy: ITopSupplierSortByEnum.AZ,
      };

    case ITopSupplierSortByEnum.ZA:
      return {
        ...state,
        shouldOpenSortMenu: false,
        sortBy: ITopSupplierSortByEnum.ZA,
      };

    case ITopSupplierSortByEnum.ValueAsc:
      return {
        ...state,
        shouldOpenSortMenu: false,
        sortBy: ITopSupplierSortByEnum.ValueAsc,
      };

    case ITopSupplierSortByEnum.ValueDesc:
      return {
        ...state,
        shouldOpenSortMenu: false,
        sortBy: ITopSupplierSortByEnum.ValueDesc,
      };

    default:
      return state;
  }
};
