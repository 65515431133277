import { useToast } from '@tapestry/shared/client';
import { ROUTE_PATHS } from '@tapestry/shared/constants';
import {
  Maybe,
  useGetHeartbeatChartSimilarProductLazyQuery,
} from '@tapestry/shared/graphql';
import {
  HeartbeatVisualizationQueriesBaseVariables,
  THREAD_TYPE,
} from '@tapestry/types';
import { MainHeading, Panel, SliderTray } from '@tapestry/weave';
import Link from 'next/link';
import * as React from 'react';
import Skeleton from 'react-loading-skeleton';
import { SellWellWithCard } from './SellWellWithCard';

interface ISellWellWithProps {
  queryVariables: HeartbeatVisualizationQueriesBaseVariables;
  stringifyParams: (param?: Record<string, any>) => string;
}

const SellWellWithWidget: React.FC<
  React.PropsWithChildren<ISellWellWithProps>
> = ({
  queryVariables: { startDate, endDate, scopeId, ...queryVariables },
  stringifyParams,
}) => {
  const { addToast } = useToast();

  const similarProductVariables = React.useMemo(
    () => ({
      ...queryVariables,
      startDate: startDate ? startDate : null,
      endDate: endDate ? endDate : null,
      scopeId: scopeId || '',
    }),
    [endDate, queryVariables, scopeId, startDate]
  );

  const [
    getSimilarProducts,
    { data: similarProductsData, loading: isSimilarProductLoading },
  ] = useGetHeartbeatChartSimilarProductLazyQuery({
    variables: similarProductVariables,
    onError: () => {
      addToast({
        type: 'error',
        content: `An error has occured whilst retrieving the data for similar products. `,
      });
    },
  });

  const similarProducts =
    similarProductsData?.heartbeatChartSimilarProduct?.data;

  const handleMakeURL = React.useCallback(
    (productId: Maybe<string> | undefined) => {
      const params = productId
        ? {
            productId,
          }
        : {};

      return `${ROUTE_PATHS.heartbeat}?${stringifyParams(params)}`;
    },
    [stringifyParams]
  );

  const productList = React.useMemo(
    () =>
      similarProducts?.map(({ id, value: price, name }) => (
        <Link key={id} href={handleMakeURL(id)}>
          <SellWellWithCard
            item={{ name: name || '', price: price ? `$${price}` : 'n/a' }}
            threadType={THREAD_TYPE.PRODUCT}
          />
        </Link>
      )),
    [handleMakeURL, similarProducts]
  );

  React.useEffect(() => {
    if (scopeId) {
      getSimilarProducts();
    }
  }, [getSimilarProducts, scopeId]);

  if (
    !isSimilarProductLoading &&
    (!similarProducts || similarProducts.length === 0)
  ) {
    return null;
  }

  if (isSimilarProductLoading) {
    return (
      <Panel>
        <div className="mb-4" id="top-selling">
          <MainHeading>
            <Skeleton width={220} />
          </MainHeading>
        </div>
        <SliderTray>
          {Array(8)
            .fill('')
            .map((_, index) => (
              <Skeleton
                key={index}
                width={220}
                className="min-w-56 max-w-56 h-64 w-56 p-4"
              />
            ))}
        </SliderTray>
      </Panel>
    );
  }

  return (
    <Panel>
      <div className="mb-4" id="top-selling">
        <MainHeading>Commonly Sold With</MainHeading>
      </div>
      <SliderTray spacing="large">{productList}</SliderTray>
    </Panel>
  );
};

export { SellWellWithWidget };
