import { APP_DEFAULT_TIMEZONE } from '@tapestry/shared/constants';
import { dateTime, extractDate } from '@tapestry/shared/utils';
import { IsoString } from '@tapestry/types';
import { DateString, ISOStringsDateRange } from '@tapestry/weave';

/**
 * Map a ISO date time to the date component of it
 *
 * 2023-04-15T23:00:00.000Z to 2023-04-15
 */
export const mapISODatetimeRangetoDateRange = (
  datetimes: [string, string]
): [DateString, DateString] => {
  // TODO[low]: heartbeatQueryParam - fix typing
  const [start, end] = datetimes as [IsoString, IsoString];

  return [extractDate(start), extractDate(end)];
};

const PARSING_FORMAT = 'YYYY-MM-DDTHH:mm:ssZ';

/**
 * convert for example '2022-11-10T10:00:00.000Z' to '2022-11-10T10:00:00.000+11:00'
 */
export const convertUTCdatetimeAsIfItWasInShopTimezone = (
  datetimes: ISOStringsDateRange,
  shopTimezone = APP_DEFAULT_TIMEZONE
) => {
  const startDate = dateTime
    .parseInTimezone(datetimes[0], shopTimezone, PARSING_FORMAT)
    .format();
  const endDate = dateTime
    .parseInTimezone(datetimes[1], shopTimezone, PARSING_FORMAT)
    .format();

  return {
    startDate,
    endDate,
  };
};
