import { THREAD_TYPE } from '@tapestry/types';

/**
 * This relates to the `scopeType` variable on the charts's query. As the queries is always done in the context of a shop or group (i.e. `filters.shop_id` / `filters.group_id`), we need to filter out THREAD_TYPE.SHOP from entering the `scopeType` variable.
 * This is similar for suppliers ID as it got implemented in the back-end as a filter and not a scope type
 */
export const UNALLOWED_SCOPE_TYPE = [
  THREAD_TYPE.SHOP,
  THREAD_TYPE.SUPPLIER,
  THREAD_TYPE.LIST,
];
