import { IDataValue } from '@tapestry/shared/graphql';
import { IDataValuePlaceholder } from '@tapestry/types';

const sameDaythan =
  (placeholderDay: IDataValuePlaceholder) => (data: IDataValue) =>
    data?.name === placeholderDay?.name;

/**
 * Using the index to directly find the compare data
 */
const findMatchingDayIndex = (
  apiData: IDataValue[],
  placeholderDay: IDataValuePlaceholder
) => {
  const apiResultIndex = apiData.findIndex(sameDaythan(placeholderDay));

  return apiResultIndex;
};

export const getDayData = (
  apiData: IDataValue[],
  placeholderDay: IDataValuePlaceholder
) => {
  const matchingDayIndex = findMatchingDayIndex(apiData, placeholderDay);
  const resultOfDay = apiData[matchingDayIndex] || {
    ...placeholderDay,
    value: 0,
  };
  const noDataFound = matchingDayIndex === -1;

  return { resultOfDay, matchingDayIndex, noDataFound };
};

/**
 * Uses the same index to get the comparison data IF it's not the matching date (i.e. 2020-09-09)
 * Using index matching, will emphasis different matching criteria depending on the comparison strategy.
 * For YoY comparison of calendar month, quarter and year, it will align on the date (i.e. 1st with 1st, 2nd with 2nd, etc...)
 * For all other comparison (currently, Week vs Week and non-calendar periods), it will emphasise matching on the day of the week (i.e. Tuesday with Tuesdy)
 */
export const getComparisonData = (
  compareData: IDataValue[],
  matchingDayIndex: number,
  placeholderDay: IDataValuePlaceholder,
  placeArray: IDataValuePlaceholder[]
) => {
  const hasDataForSameDate = compareData.find(sameDaythan(placeholderDay));
  const indexMatch = compareData[matchingDayIndex];
  const indexMatchIsPartOfDataset =
    placeArray.findIndex((date) => date.name === indexMatch?.name) !== -1;

  if (hasDataForSameDate?.value) {
    return hasDataForSameDate.value;
  }

  if (indexMatch?.value && !indexMatchIsPartOfDataset) {
    return indexMatch?.value;
  }

  return 0;
};
