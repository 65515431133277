export const calculatePercentage = (a: number, b: number): number => {
  return a / b;
};

export const caculateOpacity = (percentage: number) => {
  const defaultOpacity = 0.5;
  const minOpacity = 0.08;

  if (!percentage) return defaultOpacity;

  return percentage < minOpacity ? minOpacity : percentage;
};
