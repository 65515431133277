import React, { FC } from 'react';
import { mapThreadTypeToThreadIcon } from '@tapestry/shared/utils';
import { Circle } from '@tapestry/weave';
import { THREAD_TYPE } from '@tapestry/types';

export interface IProductVariantVisualisationCardProps {
  item: {
    name: string;
    price: string;
    img?: string;
  };
  threadType: string;
}

export const ProductVariantVisualisationCard: FC<
  IProductVariantVisualisationCardProps
> = ({ item: { name, price, img }, threadType }) => {
  const Icon = mapThreadTypeToThreadIcon(threadType as THREAD_TYPE);

  return (
    <div
      className={`font-base min-w-56 max-w-56 from-pink-light relative flex h-64 w-56 flex-col justify-between rounded-lg border  bg-gradient-to-b to-black p-4 text-left text-xs ${
        img ? 'bg-cover bg-center bg-no-repeat text-white' : 'text-white'
      }`}
      style={img ? { backgroundImage: `url("${img}")` } : {}}
    >
      <div className="relative flex h-full w-full flex-col justify-end">
        <div>
          <Circle className="bg-pink">
            <Icon fillColor="#fff" />
          </Circle>
          <h6 className="mt-2 capitalize">{name}</h6>
          <p>{price}</p>
        </div>
      </div>
    </div>
  );
};

export default ProductVariantVisualisationCard;
