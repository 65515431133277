import { ROUTE_PATHS } from '@tapestry/shared/constants';
import {
  ISortByEnum,
  Maybe,
  useGetPaginatedThreads,
} from '@tapestry/shared/graphql';
import { useLongLoading, useSortMenu } from '@tapestry/shared/hooks';
import { SortDownIcon } from '@tapestry/shared/icons';
import { IMetric, THREAD_TYPE } from '@tapestry/types';
import {
  MainHeading,
  Panel,
  ResponsiveDropdownMenu,
  SliderTray,
} from '@tapestry/weave';
import {
  randomIntegerBetween,
  safeJSONStringify,
} from '@tapestry/shared/utils';
import * as React from 'react';
import Skeleton from 'react-loading-skeleton';
import { StaffCard } from './StaffCard';

export interface IStaffVisualisationProps {
  stringifyParams: (param: Record<'staffId', string>) => string;
  activeMetricTitle: string | undefined;
  activeMeasureIcon: Maybe<IMetric['icon']>;
}

const StaffVisualisation: React.VFC<IStaffVisualisationProps> = ({
  activeMetricTitle,
  activeMeasureIcon,
  stringifyParams,
}) => {
  const {
    items,
    state: { sortBy },
  } = useSortMenu();

  const { data, loading: isLoadingThreads } = useGetPaginatedThreads({
    variables: {
      page: 1,
      limit: 7,
      filters: safeJSONStringify({
        thread_type: THREAD_TYPE.STAFF,
      }),
      search: null,
      sortBy: ISortByEnum.Newest,
      extraData: false,
    },
  });
  const { loadingMessage } = useLongLoading({
    mode: 'interval',
    isLoading: isLoadingThreads,
  });

  const handleMakeURL = (staffId: string) => {
    if (!staffId) {
      return '#';
    }

    return ROUTE_PATHS.build.heartbeat({
      threadType: THREAD_TYPE.STAFF,
      qp: stringifyParams({ staffId }),
    });
  };

  const staffs = React.useMemo(
    () =>
      data?.threadPaginationV6?.data?.map((data) => {
        const title = data.title || '';
        const [firstName, lastName] = title.split(' ');

        return {
          id: data.id,
          firstName,
          lastName,
          role: 'staff',
          value: String(randomIntegerBetween(1000, 100000)),
        };
      }) || [],
    [data]
  );

  if (staffs.length === 0) {
    return null;
  }

  return (
    <Panel>
      <header className="mb-5 flex items-center justify-between">
        <div className="flex items-center">
          <MainHeading>Top Staff {activeMetricTitle}</MainHeading>
        </div>

        <div className="flex items-center justify-end space-x-3">
          <ResponsiveDropdownMenu>
            <ResponsiveDropdownMenu.Button
              title="Sort Staff"
              className="h-8 rounded-full bg-gray-200 p-2 hover:bg-gray-300 focus:bg-gray-300 focus:outline-none sm:h-6 sm:p-1"
            >
              <span className="sr-only">Sort Staff</span>
              <SortDownIcon />
            </ResponsiveDropdownMenu.Button>

            <ResponsiveDropdownMenu.Menu>
              {items.map((item) => (
                <ResponsiveDropdownMenu.StyledMenuItem
                  key={item.label}
                  label={item.label}
                  onClick={item.onclick}
                  isActive={item.id === sortBy}
                />
              ))}
            </ResponsiveDropdownMenu.Menu>
          </ResponsiveDropdownMenu>
        </div>
      </header>
      <SliderTray spacing="large" noArrows={false}>
        {isLoadingThreads ? (
          <>
            <Skeleton height={320} width={224} />
            <Skeleton height={320} width={224} />
            <Skeleton height={320} width={224} />
            <Skeleton height={320} width={224} />
            {loadingMessage ? (
              <p className="absolute top-1/2 left-1/2 z-10 -translate-x-1/2 -translate-y-1/2 transform text-center font-medium tracking-wide text-gray-700">
                {loadingMessage}
              </p>
            ) : null}
          </>
        ) : (
          staffs.map((staff) => (
            <StaffCard
              key={staff.id}
              id={staff.id}
              firstName={staff.firstName}
              lastName={staff.lastName}
              role={staff.role}
              value={staff.value}
              handleMakeUrl={handleMakeURL}
              activeMeasureIcon={activeMeasureIcon}
            />
          ))
        )}
      </SliderTray>
    </Panel>
  );
};

export { StaffVisualisation };
