import { THREAD_TYPE } from '@tapestry/types';

export const getScopeId = (
  threadType: THREAD_TYPE,
  threadId: string | null,
  tagId: string | null | undefined
) => {
  if (!threadId || (typeof threadId === 'string' && threadId === 'null')) {
    return null;
  }

  if (threadType === THREAD_TYPE.COLLECTION && !tagId) {
    throw new Error('tagId is required for collection threads');
  }

  if (threadType === THREAD_TYPE.COLLECTION && tagId) {
    return tagId;
  }

  return threadId;
};
