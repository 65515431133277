import React, { FC, ReactElement } from 'react';
import {
  IMetric,
  Nullable,
  Visualization,
  THREAD_TYPE,
  AppName,
} from '@tapestry/types';
import { getCurrentAppInfo } from '@tapestry/shared/utils';

interface IWidgetPermissinGate {
  widget: Visualization;
  activeMetric: IMetric | null;
  activeThread: THREAD_TYPE;
  isGroupView?: boolean;
  children: ReactElement;
}

const {
  appInfo: { slug },
} = getCurrentAppInfo();

const runEnableFor = (
  result: boolean | Visualization[] | null,
  widget: Nullable<Visualization>
) => {
  const possibleVisList = result;

  if (widget && Array.isArray(possibleVisList)) {
    return possibleVisList.includes(widget) ? true : false;
  }

  return !!possibleVisList ?? false;
};

export const checkMetricPermissions = (
  activeMetric: IMetric | null,
  activeThread: THREAD_TYPE,
  widget: Nullable<Visualization> = null,
  variables?: { isGroupView?: boolean; app?: AppName }
): boolean => {
  if (!activeMetric || !activeMetric.enableFor) return false;

  const { enableFor } = activeMetric;

  // array of threadtype
  if (Array.isArray(enableFor)) {
    // should I render as a vis?
    // if metric is enable for thread type, then yes
    return enableFor.includes(activeThread) ? true : false;
  }

  if (typeof enableFor === 'function') {
    return runEnableFor(enableFor(activeThread, widget, variables), widget);
  }

  // disable by default
  return false;
};

export const VisualizationPermissionGate: FC<
  React.PropsWithChildren<IWidgetPermissinGate>
> = ({ widget, activeMetric, activeThread, isGroupView = false, children }) => {
  const hasPermissions = checkMetricPermissions(
    activeMetric,
    activeThread,
    widget,
    { isGroupView, app: slug }
  );

  if (hasPermissions) {
    return children;
  }

  return null;
};
