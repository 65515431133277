import { FC, useState } from 'react';
import { ChartErrorView } from '@tapestry/shared/components';
import { SearchIcon, SortDownIcon } from '@tapestry/shared/icons';
import {
  MainHeading,
  Panel,
  ResponsiveDropdownMenu,
  SliderTray,
} from '@tapestry/weave';
import {
  ITopShopSortByEnum,
  useGetHeartbeatTopShops,
} from '@tapestry/shared/graphql';
import { ShopListItem, TopShopsLoadingState } from './ShopsList';
import { ErrorBoundary, useToast } from '@tapestry/shared/client';
import {
  HeartbeatVisualizationQueriesBaseVariables,
  IMetric,
  Nullable,
} from '@tapestry/types';
import { VisualizationSearchModal } from '@tapestry/shared/visualisation';
import { Maybe } from 'graphql/jsutils/Maybe';
import { ROUTE_PATHS } from '@tapestry/shared/constants';
import { useLongLoading, useModal, useSortMenu } from '@tapestry/shared/hooks';
import isEmpty from 'lodash/isEmpty';

export interface ITopShopsVisualisationProps {
  queryVariables: HeartbeatVisualizationQueriesBaseVariables;
  stringifyParams: (param?: Record<string, any>) => string;
  activeMeasureUnit: Nullable<IMetric['unit']>;
  activeMetricTitle: string | undefined;
}

/**
 * A visualisation that displays the top shop within a group
 */
const TopShopsVisualisation: FC<ITopShopsVisualisationProps> = ({
  queryVariables,
  stringifyParams,
  activeMeasureUnit,
  activeMetricTitle,
}) => {
  const visualisationTitle = `Top Shops ${activeMetricTitle}`;

  /**
   * Hooks
   */
  const searchModalState = useModal();
  const [search, setSearch] = useState<string | null>(null);
  const {
    items,
    state: { sortBy },
  } = useSortMenu();
  const { addToast } = useToast();

  const {
    data: dataTopShop,
    loading: isLoadingShops,
    refetch,
  } = useGetHeartbeatTopShops({
    variables: {
      ...queryVariables,
      search,
      sortBy: sortBy as ITopShopSortByEnum,
      limit: 40, // arbitrary limit being more than all the shops we have in a group as of the 30 Jan 2024. Faster than pagination.
    },
    onError: () => {
      addToast({
        type: 'error',
        content: 'An error has occured whislt getting your top shops',
      });
    },
  });
  const topShops = dataTopShop?.heartbeatChartTopShopsV2?.data;

  const { loadingMessage } = useLongLoading({
    mode: 'interval',
    isLoading: isLoadingShops,
  });

  const handleMakeURL = (shopId: Maybe<string>) => {
    const params = shopId
      ? {
          shopId,
          groupId: undefined,
        }
      : {};

    return `${ROUTE_PATHS.heartbeat}?${stringifyParams(params)}`;
  };

  const handleSortbyChange = (sortBy: string) => {
    refetch({ sortBy: sortBy as ITopShopSortByEnum });
  };

  return (
    <ErrorBoundary errorView={<ChartErrorView />}>
      <Panel>
        <header className="mb-5 flex items-center justify-between">
          <div className="flex items-center">
            <MainHeading>{visualisationTitle}</MainHeading>
          </div>

          <div className="flex items-center space-x-4">
            <button
              title={`Search top shops`}
              className="h-8 rounded-full bg-gray-200 p-2 hover:bg-gray-300 focus:bg-gray-300 focus:outline-none sm:h-6 sm:p-1"
              onClick={searchModalState.open}
              onKeyDown={({ key }) => {
                if (key === 'Enter') {
                  searchModalState.open();
                }
              }}
            >
              <p className="sr-only">Search top shops</p>
              <SearchIcon />
            </button>

            <ResponsiveDropdownMenu>
              <ResponsiveDropdownMenu.Button
                title="Sort Top Shops"
                className="h-8 rounded-full bg-gray-200 p-2 hover:bg-gray-300 focus:bg-gray-300 focus:outline-none sm:h-6 sm:p-1"
              >
                <span className="sr-only">Sort Top Shops</span>
                <SortDownIcon />
              </ResponsiveDropdownMenu.Button>

              <ResponsiveDropdownMenu.Menu>
                {items.map((item) => (
                  <ResponsiveDropdownMenu.StyledMenuItem
                    key={item.label}
                    label={item.label}
                    onClick={item.onclick}
                    isActive={item.id === sortBy}
                  />
                ))}
              </ResponsiveDropdownMenu.Menu>
            </ResponsiveDropdownMenu>
          </div>
        </header>

        <div className="relative">
          {loadingMessage ? (
            <p className="absolute top-1/2 left-1/2 z-10 -translate-x-1/2 -translate-y-1/2 transform text-center font-medium tracking-wide text-gray-700">
              {loadingMessage}
            </p>
          ) : null}

          <SliderTray spacing="large" noArrows={isLoadingShops}>
            {isLoadingShops
              ? [1, 2, 3, 4, 5, 6].map((unit) => (
                  <TopShopsLoadingState key={unit} />
                ))
              : topShops &&
                !isEmpty(topShops) &&
                topShops?.map((shop) => (
                  <ShopListItem
                    shop={shop}
                    handleMakeURL={handleMakeURL}
                    activeMeasureUnit={activeMeasureUnit}
                  />
                ))}
          </SliderTray>
        </div>

        {searchModalState.isOpen && (
          <VisualizationSearchModal
            title={visualisationTitle}
            data={topShops || []}
            unit={activeMeasureUnit || undefined}
            defaultSortBy={sortBy}
            isLoading={isLoadingShops}
            modalState={searchModalState}
            onInputChange={(value) => {
              setSearch(value || null);
            }}
            onSortbyChange={handleSortbyChange}
            onLinkClicked={(shop) => handleMakeURL(shop?.id)}
          />
        )}
      </Panel>
    </ErrorBoundary>
  );
};

export { TopShopsVisualisation };
export default TopShopsVisualisation;
