import { MainHeading, Panel } from '@tapestry/weave';
import * as React from 'react';
import { IMetric } from '@tapestry/types';
import { Maybe } from 'graphql/jsutils/Maybe';
import { SearchIcon } from '@tapestry/shared/icons';
import dynamic from 'next/dynamic';

const DynamicMap = dynamic(() => import('./Map'), {
  // suspense: true,
  ssr: false,
});

const MapSuspenseState = () => {
  return <div className="bg-gray-pageBackground h-[300px] w-full rounded" />;
};

interface PostcodeVisualisationProps {
  activeMetricTitle: string | undefined;
  unit: IMetric['unit'];
  comparisonTitle: string | undefined; //TOFIX: change this one to a comparisonVariable
  stringifyParams: (param: Record<'postcode', string>) => string;
  comparisonLabels: { tooltipLabel: Maybe<string>; headerString: string }; //TOFIX: change this type to match with one in heartbeat
}

const PostcodeVisualisation: React.VFC<PostcodeVisualisationProps> = ({
  activeMetricTitle,
  unit,
  stringifyParams,
  comparisonLabels,
}) => (
  <Panel>
    <header className="mb-5 flex items-center justify-between">
      <div className="flex items-center">
        <MainHeading>{activeMetricTitle} by Postcode</MainHeading>
      </div>

      <div className="flex items-center justify-end space-x-3">
        <button
          title="Search Postcode"
          className="h-8 rounded-full bg-gray-200 p-2 hover:bg-gray-300 focus:bg-gray-300 focus:outline-none sm:h-6 sm:p-1"
        >
          <SearchIcon />
        </button>
      </div>
    </header>

    <React.Suspense fallback={<MapSuspenseState />}>
      <DynamicMap
        activeMetricTitle={activeMetricTitle}
        unit={unit}
        stringifyParams={stringifyParams}
        comparisonLabels={comparisonLabels}
      />
    </React.Suspense>
  </Panel>
);

export { PostcodeVisualisation };
