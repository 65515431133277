import { IMetric, Nullable } from '@tapestry/types';

export const getDimensionsLabelFromActiveMetric = (
  metric: Nullable<IMetric>
) => {
  const topDepartmentLabel = metric?.dimensionsLabels?.topDepartments ?? null;
  const topCategoriesLabel = metric?.dimensionsLabels?.topCategories ?? null;
  const topProductsLabel = metric?.dimensionsLabels?.topProducts ?? null;
  const betsTimesLabel = metric?.dimensionsLabels?.bestTimes ?? null;
  const topSpacesLabel = metric?.dimensionsLabels?.topsSpaces ?? null;

  return {
    topDepartmentLabel,
    topCategoriesLabel,
    topProductsLabel,
    betsTimesLabel,
    topSpacesLabel,
  };
};
