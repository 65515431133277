import { IUserProfile } from '@tapestry/shared/graphql';
import { Maybe } from 'graphql/jsutils/Maybe';

export const decideIfShouldShowAnnouncement = (
  shouldShowAnnouncement: boolean,
  profile: Maybe<IUserProfile>
) => {
  if (typeof window === 'undefined') return false;

  // Override
  if (shouldShowAnnouncement) {
    return true;
  }

  // demo shop account user hasn't seen it OR a real account has not seen it
  const hasDemoShopUserHasSeenOnboarding = Boolean(
    localStorage.getItem('demoShopUserHasSeenOnboarding')
  );

  if (
    !profile?.onboarded_status?.welcome &&
    !hasDemoShopUserHasSeenOnboarding
  ) {
    return true;
  }

  return false;
};
