import { HeartbeatComparisonPeriodOptions } from '../../../../constants';
import { METRICS } from '@tapestry/shared/constants';
import {
  ICustomHeartbeatListSimpleType,
  IShopSelectorType,
} from '@tapestry/shared/graphql';
import {
  HearbeatComparisonKey,
  HeartbeatComparisonPeriod,
  HeartbeatComparisonQueriesVariable,
} from '@tapestry/types';
import { Maybe } from 'graphql/jsutils/Maybe';
import invariant from 'ts-invariant';

export interface IComparisonLabel {
  tooltipLabel: Maybe<string>;
  headerString: string;
}

const getTooltipLabel = (
  comparisonVariable: HeartbeatComparisonQueriesVariable
) => {
  if (comparisonVariable.value === HeartbeatComparisonPeriod.UserDefined) {
    return 'vs comparison';
  }

  return HeartbeatComparisonPeriodOptions.find(
    (option) => option.value === comparisonVariable.value
  )?.label;
};

export const getComparisonResultLabels = (
  comparisonVariable: HeartbeatComparisonQueriesVariable,
  compareStartDate: string,
  compareEndDate: string,
  lists: ICustomHeartbeatListSimpleType[],
  shops?: IShopSelectorType['allShops'],
  groups?: IShopSelectorType['groups']
): IComparisonLabel => {
  const periodString = `${compareStartDate} - ${compareEndDate}`;

  if (comparisonVariable.key === HearbeatComparisonKey.Period) {
    const tooltipLabel = getTooltipLabel(comparisonVariable);

    return { tooltipLabel, headerString: `compared to ${periodString}` };
  }

  if (comparisonVariable.key === HearbeatComparisonKey.Measure) {
    const measureTitle = METRICS.find(
      ({ key }) => key === comparisonVariable.value
    )?.title?.toLocaleLowerCase();

    return {
      tooltipLabel: measureTitle,
      headerString: `compared to ${
        measureTitle || comparisonVariable.value
      } across ${periodString}`,
    };
  }

  if (comparisonVariable.key === HearbeatComparisonKey.ShopId) {
    invariant(shops, 'Shops are required to get comparison result label');

    const shopTitle = shops.find(
      ({ id }) => id === comparisonVariable.value
    )?.title;

    return {
      tooltipLabel: shopTitle,
      headerString: `compared to ${shopTitle} across ${periodString}`,
    };
  }

  if (comparisonVariable.key === HearbeatComparisonKey.GroupId) {
    invariant(groups, 'Groups are required to get comparison result label');

    const groupTitle = groups.find(
      ({ id }) => id === comparisonVariable.value
    )?.title;

    return {
      tooltipLabel: groupTitle,
      headerString: `compared to ${groupTitle} across ${periodString}`,
    };
  }

  if (comparisonVariable.key === HearbeatComparisonKey.ListId) {
    invariant(lists, 'Lists are required to get comparison result label');

    const listTitle = lists.find(
      ({ id }) => id === comparisonVariable.value
    )?.name;

    return {
      tooltipLabel: listTitle,
      headerString: `compared to ${listTitle} across ${periodString}`,
    };
  }

  return { tooltipLabel: null, headerString: '' };
};
