import React, { ReactElement } from 'react';
import { Transition } from '@headlessui/react';
import { useUIContext } from '@tapestry/shared/client';
import { getStylesFromState, IMetricTile } from './MeasuresMenuUtils';
import Skeleton from 'react-loading-skeleton';
import Link from 'next/link';
import { twMerge } from 'tailwind-merge';
import { trackEvent } from '@tapestry/shared/utils';

export const MeasuresSliderMenuMeasureTile: React.FC<IMetricTile> = ({
  metric,
  metricTitle,
  metricSubtitle,
  isActiveCategory,
  shopIsActiveThread,
  isLoading = false,
  urlQuery,
}) => {
  /**
   * Hooks
   */
  const [{ threadTypeThemeColors }] = useUIContext();

  /**
   * Const
   */
  const { backgroundColor, iconColor, textColor, borderColor } =
    threadTypeThemeColors;
  const { tileIconColorStyle, tileColorStyle } = getStylesFromState(
    metric.disabled ?? false,
    isActiveCategory,
    shopIsActiveThread,
    textColor,
    iconColor
  );

  const handleTrackEvent = () => {
    trackEvent({
      event: 'MeasureSelection',
      category: 'measure_selection',
      action: 'measure_selected',
      label: metric.key,
      value: metricSubtitle ? 1 : 0, // if summary is displayed, report 1 else report 0
    });
  };

  return (
    <div className={twMerge('h-full', metric.readonly && 'cursor-not-allowed')}>
      <Link
        href={{
          query: {
            ...urlQuery,
            slug: metric.key,
            mVar: metric?.variants ? metric.key : undefined,
          },
        }}
        onClick={handleTrackEvent}
        onKeyUp={({ key }) => {
          if (key === 'Enter') {
            handleTrackEvent();
          }
        }}
        className={twMerge(
          'relative flex h-full flex-col items-center justify-between rounded-lg px-6 py-2 font-bold md:min-w-32 md:px-1',
          tileColorStyle,
          borderColor,
          isActiveCategory && 'box-arrow-bottom-center after:border-t-current',
          (metric.disabled || metric.readonly) && 'pointer-events-none'
        )}
        aria-current={isActiveCategory}
      >
        <div className="flex flex-1 flex-row items-center justify-center text-center md:flex-col">
          {metric.icon && (
            <div
              className={`mr-2 h-4 md:mb-2 md:mr-0 md:h-6 ${
                metric.disabled ? 'opacity-50' : ''
              }`}
            >
              {React.cloneElement(metric?.icon as ReactElement, {
                fillColor: tileIconColorStyle,
              })}
            </div>
          )}

          <div className="text-sm font-bold sm:text-base">
            <h2
              className={`whitespace-nowrap capitalize leading-5 tracking-wide md:whitespace-normal`}
            >
              {metricTitle}
            </h2>
          </div>
        </div>
        <div className="h-6 w-full flex-shrink-0 overflow-hidden p-1 text-center leading-4 md:mb-1">
          {isLoading ? (
            <Skeleton height={16} width={80} containerClassName=" rounded-lg" />
          ) : (
            <span
              title={metricSubtitle}
              className={`block truncate text-xs ${
                isActiveCategory ? 'font-bold' : 'font-normal'
              }`}
            >
              {metric?.disabled ? 'coming soon' : metricSubtitle || 'N/A'}
            </span>
          )}
        </div>

        {/* This maybe would cause problem for SSR, unsure */}
        <Transition
          as="div"
          show={isActiveCategory || false}
          enter="transition-opacity duration-300 ease-out"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-150"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          className="absolute bottom-0 left-0 w-full"
        >
          <div className={`h-1 rounded-b-lg md:h-2 ${backgroundColor}`} />
        </Transition>
      </Link>
    </div>
  );
};

export default MeasuresSliderMenuMeasureTile;
