import { IsoString } from '@tapestry/types';
import dayjs from 'dayjs';
import { IDataValue } from '@tapestry/shared/graphql';
import { createDateArrayForPeriodStarting } from './createDateArrayForPeriodStarting';
import { fillDatePlaceholderArray } from './fillDatePlaceholderArray';
import { HeartbeatTickerInterval } from '../get-ticker-interval';

export const getDiffDays = (startDate: string, endDate: string) => {
  return dayjs(endDate).diff(dayjs(startDate), 'day');
};

export const getDiffHours = (startDate: string, endDate: string) => {
  return dayjs(endDate).diff(dayjs(startDate), 'hour');
};

/**
 * normaliseChartData
 *
 * Enables the UI to display 'empty days' even if the api returns nothing
 */

export const normaliseChartData = (
  data: IDataValue[],
  compareData: IDataValue[] | undefined,
  startDate: string,
  endDate: string,
  currentShopTimezone: string,
  zeroDataBeforeDate: string | IsoString | null,
  tickerInterval: HeartbeatTickerInterval
) => {
  const isHourly = getDiffHours(startDate, endDate) === 0;
  const diffDay = getDiffDays(startDate, endDate);
  const isIntraDay = diffDay === 0;

  const periodPlaceholderArray = createDateArrayForPeriodStarting(
    startDate,
    endDate,
    tickerInterval,
    currentShopTimezone
  );

  const mergedtimeData = fillDatePlaceholderArray(
    periodPlaceholderArray,
    data,
    compareData ?? [],
    zeroDataBeforeDate,
    isIntraDay,
    isHourly
  );

  return mergedtimeData;
};
