import { FormPhoneNumberInput, FormTextInput } from '@tapestry/weave';
import * as React from 'react';
import { Control, FieldErrors, UseFormRegister } from 'react-hook-form';
import { SupplierInsightInvitationFormNewUserSection } from '../permission-wizard-types';

const FORM_FIELDS: {
  name: keyof SupplierInsightInvitationFormNewUserSection;
  label: string;
  type: string;
  placeholder: string;
}[] = [
  {
    name: 'firstName',
    label: 'First Name',
    type: 'text',
    placeholder: 'e.g. John',
  },
  {
    name: 'lastName',
    label: 'Last Name',
    type: 'text',
    placeholder: 'e.g. Smith',
  },
  {
    name: 'email',
    label: 'Email',
    type: 'email',
    placeholder: 'e.g. jonh.smith@email.com',
  },
  {
    name: 'mobile',
    label: 'Mobile',
    type: 'mobile',
    placeholder: 'e.g. 0123456789',
  },
];

interface IAddPeopleScreenProps {
  register: UseFormRegister<SupplierInsightInvitationFormNewUserSection>;
  errors: FieldErrors;
  control: Control<SupplierInsightInvitationFormNewUserSection>;
}

const AddPeopleScreen: React.FC<IAddPeopleScreenProps> = ({
  register,
  control,
}) => {
  return (
    <div className="grid grid-cols-2 gap-6 pb-6">
      {FORM_FIELDS.map((field) => (
        <div key={field.name} className="text-left">
          {field.type === 'mobile' ? (
            <FormPhoneNumberInput
              label={field.label}
              name={field.name}
              control={control as any}
            />
          ) : (
            <FormTextInput {...field} {...register(field.name)} />
          )}
        </div>
      ))}
    </div>
  );
};

export { AddPeopleScreen };
