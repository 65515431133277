import Skeleton from 'react-loading-skeleton';

export const HorizontalBarLoadingState = () => {
  return (
    <div className="ml-16 w-8/12">
      <Skeleton height="2rem" className="mb-2" />
      <Skeleton height="2rem" width="90%" className="mb-2" />
      <Skeleton height="2rem" width="78%" className="mb-2" />
      <Skeleton height="2rem" width="75%" className="mb-2" />
      <Skeleton height="2rem" width="50%" className="mb-2" />
    </div>
  );
};

export const calculatePercentage = (a: number, b: number): number => {
  return a / b;
};
