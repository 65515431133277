import { IInviteSupplierAccessVariables } from '@tapestry/shared/graphql';
import {
  SupplierInsightInvitationForm,
  AccessType,
} from './permission-wizard-types';

export const mapAccessIds = (
  accessType: AccessType,
  formValues: SupplierInsightInvitationForm
) => {
  let accessIds;

  if (accessType === 'shop') {
    accessIds = formValues.shops.map((shop) => shop.id);
  } else if (accessType === 'shop_group') {
    accessIds = formValues.groups.map((shop) => shop.id);
  }

  return accessIds;
};

export const parseFormDataToVariables = (
  formValues: SupplierInsightInvitationForm,
  linkedUserId: string,
  accessType: AccessType
): IInviteSupplierAccessVariables => {
  const accessId = mapAccessIds(accessType, formValues);

  return {
    userId: linkedUserId,
    accessType,
    accessId,
    availableMeasures: formValues.permissions,
    products: formValues.products.map((product) => product.id),
    message: formValues.message || '',
  };
};
