import {
  ISortByEnum,
  IThreadV4Type,
  useGetPaginatedThreadsLazyQuery,
} from '@tapestry/shared/graphql';
import { useActiveShopScopeParams } from '@tapestry/shared/hooks';
import {
  ProductIconWithBubble,
  SearchIcon,
  TickIcon,
} from '@tapestry/shared/icons';
import { Button, ListItem } from '@tapestry/weave';
import { safeJSONStringify } from '@tapestry/shared/utils';
import * as React from 'react';
import { useDebounce } from 'use-debounce';
import { getTwistByKey } from '@tapestry/shared/utils';
import { Twist } from '@tapestry/types';
import { twMerge } from 'tailwind-merge';
import {
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form';
import { SupplierInsightInvitationForm } from '../permission-wizard-types';

interface ISelectProductsScreenProps {
  getValues: UseFormGetValues<SupplierInsightInvitationForm>;
  register: UseFormRegister<SupplierInsightInvitationForm>;
  setValue: UseFormSetValue<SupplierInsightInvitationForm>;
}

const SelectProductsScreen: React.FC<ISelectProductsScreenProps> = ({
  getValues,
  register,
  setValue,
}) => {
  const [query, setQuery] = React.useState('');
  const [debounceQuery] = useDebounce(query, 250);
  const [selectedProducts, setSelectedProducts] = React.useState<
    IThreadV4Type[]
  >(getValues?.().products || []);

  const activeScope = useActiveShopScopeParams({
    shopId: 'shop_id',
    groupId: 'group_id',
  });

  const defaultQueryVariables = React.useMemo(
    () => ({
      page: 1,
      extraData: true,
      limit: 100,
      search: '',
      filters: safeJSONStringify({
        thread_type: 'product',
        ...activeScope,
      }),
      sortBy: ISortByEnum.AZ,
    }),
    [activeScope]
  );

  const [getThreads, { data, loading: isLoading }] =
    useGetPaginatedThreadsLazyQuery({
      variables: defaultQueryVariables,
    });

  const threads = React.useMemo(
    () => data?.threadPaginationV6?.data || [],
    [data?.threadPaginationV6?.data]
  );

  const hasAllSelected = threads.length === setSelectedProducts.length;

  const handleSearchInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;

    setQuery(value);
  };

  const handleToggleButtonClick = (product: IThreadV4Type) => {
    setSelectedProducts((prev) => {
      const isSelected = !!prev.find((thread) => thread.id === product.id);
      const newValue = isSelected
        ? prev.filter((thread) => thread.id !== product.id)
        : [...prev, product];

      setValue('products', newValue);

      return newValue;
    });
  };

  const handleSelectAll = () => {
    setSelectedProducts(threads);
    setValue('products', threads);
  };

  const handleUnselectAll = () => {
    setSelectedProducts([]);
    setValue('products', []);
  };

  React.useEffect(() => {
    getThreads({
      variables: { ...defaultQueryVariables, search: debounceQuery },
    });
  }, [debounceQuery, defaultQueryVariables, getThreads]);

  React.useEffect(() => {
    register('products');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div className="flex space-x-4">
        <div className="border-gray-lightest my-4 flex flex-1 items-center rounded border p-4">
          <SearchIcon
            className="text-gray-text mr-2 h-6 w-6 flex-shrink-0"
            fillColor="currentColor"
          />
          <input
            className="text-blue placeholder:text-gray-text flex-grow text-base tracking-wider outline-none"
            placeholder="Search for Products"
            onChange={handleSearchInputChange}
          />
        </div>

        <div className="flex space-x-2 flex-shrink-0">
          <Button
            status="text"
            spacing="none"
            bold
            className={hasAllSelected ? '' : 'text-blue'}
            onClick={handleSelectAll}
          >
            Select All
          </Button>
          <Button
            status="text"
            spacing="none"
            bold
            className={hasAllSelected ? 'text-blue' : ''}
            onClick={handleUnselectAll}
          >
            Unselect All
          </Button>
        </div>
      </div>

      {isLoading && <p className="py-4">Loading...</p>}

      <div className="no-scrollbar flex max-h-[500px] flex-col gap-2 overflow-hidden overflow-y-auto">
        {threads.map((thread) => {
          const SKU = getTwistByKey(Twist.SKU, thread)?.value;
          const isSelected = !!selectedProducts.find(
            (product) => thread.id === product.id
          );

          return (
            <ListItem key={thread.id} tag="div" justify="between">
              <div
                className="flex w-full cursor-pointer items-center justify-between"
                onClick={() => handleToggleButtonClick(thread)}
                onKeyDown={({ key }) => {
                  if (key === 'Enter') {
                    handleToggleButtonClick(thread);
                  }
                }}
              >
                <div className="flex items-center overflow-x-hidden">
                  <span className="mr-4 h-12 w-12 rounded-full">
                    <ProductIconWithBubble />
                  </span>
                  <div className="flex flex-col items-start">
                    <p className=" truncate font-semibold text-black">
                      {thread?.title || ''}
                    </p>
                    {SKU ? (
                      <p className=" text-gray-text truncate">{SKU}</p>
                    ) : null}
                  </div>
                </div>

                <div
                  className={twMerge(
                    'border-gray-broder  inline-flex h-7 w-7 items-center justify-center rounded-full border text-white sm:h-10 sm:w-10 sm:p-2',
                    isSelected ? 'bg-green' : ''
                  )}
                >
                  <TickIcon light fillColor="currentColor" />
                </div>
              </div>
            </ListItem>
          );
        })}
      </div>
    </div>
  );
};

export { SelectProductsScreen };
