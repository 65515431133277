import { ROUTE_PATHS } from '@tapestry/shared/constants';
import { useLongLoading, useSortMenu } from '@tapestry/shared/hooks';
import { SortDownIcon, UsersIcon } from '@tapestry/shared/icons';
import { IMetric, THREAD_TYPE } from '@tapestry/types';
import {
  Card,
  Circle,
  MainHeading,
  Panel,
  Pill,
  ResponsiveDropdownMenu,
  SliderTray,
  Stack,
} from '@tapestry/weave';
import { Maybe } from 'graphql/jsutils/Maybe';
import sortBy from 'lodash/sortBy';
import Link from 'next/link';
import React, { ReactElement, useEffect, useState } from 'react';

type Persona = { id: string; name: string; value: number };

const MOCK_PERSONAS: Persona[] = [
  { id: 'millennialsFemale', name: 'Female 32 - 45 years old', value: 1235.54 },
  { id: 'femaleGenZ', name: 'Female 16 - 22 years old', value: 5000.195 },
  { id: 'genXMale', name: 'Male 57 - 67 years old', value: 5748.09 },
  { id: 'maleGenZ', name: 'Male 16 - 22 years old', value: 9923.12 },
  { id: 'genXFemale', name: 'Female 57 - 67 years old', value: 2053.94 },
  { id: 'tradMale', name: 'Male 75+ years old', value: 91223.52 },
  { id: 'millennialsMale', name: 'Male 32 - 45 years old', value: 8532.95 },
  { id: 'babyBoomMale', name: 'Male 65 - 72 years old', value: 3329.77 },
  { id: 'tradFemale', name: 'Female 75+ years old', value: 4821.12 },
  { id: 'babyBoomFemale', name: 'Female 65 - 72 years old', value: 1042.42 },
];

const useGetPersonas = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, 3000);

    return () => clearTimeout(timeout);
  });

  return { loading: isLoading, data: { getPersonas: { data: MOCK_PERSONAS } } };
};

// *******************************************
// Local Interface
// -------------------------------------------
export interface IPersonaVisualProps {
  stringifyParams: (param: Record<'dem', string>) => string;
  activeMeasureIcon: Maybe<IMetric['icon']>;
  activeMetricTitle: string | undefined;
}

/**
 * A visualization displaying personas
 */
export const PersonaVisual = ({
  stringifyParams,
  activeMeasureIcon,
  activeMetricTitle,
}: IPersonaVisualProps) => {
  /**
   * Hooks
   */
  const {
    items,
    state: { sortBy },
  } = useSortMenu();
  const { data, loading } = useGetPersonas();
  const { loadingMessage } = useLongLoading({
    mode: 'interval',
    isLoading: loading,
  });
  const personas = data?.getPersonas?.data || [];

  const handleMakeUrlForPersonaCard = (cardId: string) => {
    return ROUTE_PATHS.build.heartbeat({
      threadType: THREAD_TYPE.DEMOGRAPHIC,
      qp: stringifyParams({ dem: cardId }),
    });
  };

  return (
    <Panel>
      <header className="mb-5 flex items-center justify-between">
        <div className="flex items-center">
          <MainHeading>Demographics {activeMetricTitle}</MainHeading>
          {/* <HankGuideInfo
            title="Demographics"
            link=""
            className="ml-2 inline-block"
          /> */}
        </div>

        <div className="flex items-center justify-end space-x-3">
          <ResponsiveDropdownMenu>
            <ResponsiveDropdownMenu.Button
              title="Sort Demographics"
              className="h-8 rounded-full bg-gray-200 p-2 hover:bg-gray-300 focus:bg-gray-300 focus:outline-none sm:h-6 sm:p-1"
            >
              <span className="sr-only">Sort Demographics</span>
              <SortDownIcon />
            </ResponsiveDropdownMenu.Button>

            <ResponsiveDropdownMenu.Menu>
              {items.map((item) => (
                <ResponsiveDropdownMenu.StyledMenuItem
                  key={item.label}
                  label={item.label}
                  onClick={item.onclick}
                  isActive={item.id === sortBy}
                />
              ))}
            </ResponsiveDropdownMenu.Menu>
          </ResponsiveDropdownMenu>
        </div>
      </header>

      <PersonasList
        personas={personas}
        handleMakeUrlForPersonaCard={handleMakeUrlForPersonaCard}
        activeMeasureIcon={activeMeasureIcon}
        loadingMessage={loadingMessage}
      />
    </Panel>
  );
};

const PersonasList = ({
  personas,
  handleMakeUrlForPersonaCard,
  activeMeasureIcon,
  loadingMessage,
}: {
  personas: Persona[];
  handleMakeUrlForPersonaCard: (id: string) => string;
  activeMeasureIcon: Maybe<IMetric['icon']>;
  loadingMessage: string | null;
}) => {
  const Icon = React.cloneElement(activeMeasureIcon as ReactElement, {
    fillColor: 'currentColor',
  });

  return (
    <SliderTray>
      {loadingMessage ? (
        <p className="absolute top-1/2 left-1/2 z-10 -translate-x-1/2 -translate-y-1/2 transform text-center font-medium tracking-wide text-gray-700">
          {loadingMessage}
        </p>
      ) : null}

      {sortBy(personas, ['value'])
        .reverse()
        ?.map((persona) => (
          <Link
            href={handleMakeUrlForPersonaCard(persona?.id)}
            className="block"
            key={persona?.id}
          >
            <Card
              justify="center"
              bgColor="bg-cerulean"
              backgroundGradient="linear-gradient(rgba(35, 176, 231,1), rgba(0, 0, 0, .85))"
              className="text-white"
            >
              <Stack spacing="small">
                <Stack spacing="xxsmall" align="center">
                  <Circle size="h-18 w-18 p-4" className="bg-cerulean">
                    <UsersIcon fillColor="#fff" />
                  </Circle>
                  <h3 className="max-w-32 font-bold tracking-wide">
                    {persona?.name}
                  </h3>
                </Stack>

                <Pill
                  label={persona?.value}
                  iconLeft={Icon}
                  bgColor="#fff"
                  textColor="text-black"
                  fontWeight="font-bold"
                />
              </Stack>
            </Card>
          </Link>
        ))}
    </SliderTray>
  );
};

export default PersonaVisual;
