import {
  AGGREGATION_TYPE,
  IParsedHeartbeatMainChartDataValueList,
} from '@tapestry/types';
import isEmpty from 'lodash/isEmpty';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
dayjs.extend(isSameOrBefore);

export const getTabMetricTotalForPeriod = (
  periodData: IParsedHeartbeatMainChartDataValueList,
  aggregationType: AGGREGATION_TYPE | undefined,
  isComparedData = false
): number => {
  if (isEmpty(periodData)) return 0;

  const periodDataValueOnly = periodData.map((dataPoint) => {
    const data = isComparedData ? dataPoint.comparison : dataPoint.value;

    return data ?? 0;
  });
  const periodTotal = periodDataValueOnly.reduce(
    (total, nextValue) => total + nextValue,
    0
  );

  if (aggregationType === AGGREGATION_TYPE.SUM) {
    return periodTotal;
  } else if (aggregationType === AGGREGATION_TYPE.AVERAGE) {
    return periodTotal / (periodData?.length || 1);
  }

  return 0;
};

export const getTotals = (
  parsedChartData: IParsedHeartbeatMainChartDataValueList,
  aggregationType: AGGREGATION_TYPE | undefined
) => {
  const dataUpToToday = parsedChartData.filter((data) =>
    dayjs(data.name).isSameOrBefore(dayjs(), 'day')
  );

  const totalUpToToday = getTabMetricTotalForPeriod(
    dataUpToToday,
    aggregationType
  );

  const comparedDataUpToToday = getTabMetricTotalForPeriod(
    dataUpToToday,
    aggregationType,
    true
  );

  const fullPeriodTotal = getTabMetricTotalForPeriod(
    parsedChartData,
    aggregationType,
    true
  );

  return { comparedDataUpToToday, totalUpToToday, fullPeriodTotal };
};
