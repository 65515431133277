import * as React from 'react';
import { Modal, ModalState } from '@tapestry/weave';
import { AlertListItem } from './AlertListItem';
import { THREAD_TYPE } from '@tapestry/types';

interface IAlertListModalProps {
  modalState: ModalState;
}

const MOCK_ALERTS = [
  {
    threadType: THREAD_TYPE.DEPARTMENT,
    title: 'Dairy Transactions',
    boundaries: {
      min: 33,
      max: 39,
    },
    frequency: '2 hrs',
  },
  {
    threadType: THREAD_TYPE.SHOP,
    title: 'All Sales',
    boundaries: {
      min: 29,
      max: 55,
    },
    frequency: '1 hr',
  },
  {
    threadType: THREAD_TYPE.PRODUCT,
    title: 'Joe’s Pinot Noir Units Solds',
    boundaries: {
      min: 29,
      max: 55,
    },
    frequency: '1 hr',
  },
  {
    threadType: THREAD_TYPE.CATEGORY,
    title: 'Vegetables Gross Profit',
    boundaries: {
      min: 29,
      max: 55,
    },
    frequency: '2 hr',
  },
];

const AlertListModal: React.FC<IAlertListModalProps> = ({ modalState }) => {
  return (
    <Modal state={modalState}>
      <Modal.Dialog>
        <Modal.Content>
          <Modal.Title>Saved alerts</Modal.Title>
          <Modal.Description>
            View all saved alerts. Removed any unwanted alerts.
          </Modal.Description>

          <div className="my-6 flex w-full flex-col items-start gap-2">
            {MOCK_ALERTS.map(({ title, frequency, threadType, boundaries }) => (
              <AlertListItem
                title={title}
                frequency={`After ${frequency}`}
                threadType={threadType}
                boundaries={boundaries}
              />
            ))}
          </div>
        </Modal.Content>
      </Modal.Dialog>
    </Modal>
  );
};

export { AlertListModal };
