import {
  IShopSelectorGroupType,
  IThreadV4Type,
} from '@tapestry/shared/graphql';

export function checkIfSelected(
  key: string,
  thread: IThreadV4Type,
  selectedShops: IThreadV4Type[],
  selectedGroups: IShopSelectorGroupType[]
) {
  return key === 'groups'
    ? selectedGroups.some((product) => thread.id === product.id)
    : selectedShops.some((product) => thread.id === product.id);
}

export const getNewValue = (prev, selectedThread) => {
  const isSelected = prev.some((thread) => thread.id === selectedThread.id);

  const newValue = isSelected
    ? prev.filter((thread) => thread.id !== selectedThread.id)
    : [...prev, selectedThread];

  return newValue;
};

export const sortGroupFromBiggestToSmallest = (
  groups: IShopSelectorGroupType[] | undefined
) => {
  if (!groups) return [];

  return [...groups].sort((groupA, groupB) => {
    const groupALength = groupA.shops?.length || 0;
    const groupBLength = groupB.shops?.length || 0;

    if (groupALength > groupBLength) {
      return -1;
    }

    if (groupALength < groupBLength) {
      return 1;
    }

    return 0;
  });
};
