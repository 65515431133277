import { sortGroupFromBiggestToSmallest } from './SelectShopsScreenUtils';
import {
  IShopSelectorGroupType,
  IThreadV4Type,
  useShopSelector,
} from '@tapestry/shared/graphql';
import { ShopIcon, TickIcon } from '@tapestry/shared/icons';
import { Avatar, ListItem, TabItem, Tabs } from '@tapestry/weave';
import * as React from 'react';
import { getTwistByKey } from '@tapestry/shared/utils';
import { Twist } from '@tapestry/types';
import { twMerge } from 'tailwind-merge';
import {
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form';
import { SupplierInsightInvitationForm } from '../permission-wizard-types';
import { checkIfSelected, getNewValue } from './SelectShopsScreenUtils';

interface ISelectShopsScreenProps {
  getValues: UseFormGetValues<SupplierInsightInvitationForm>;
  register: UseFormRegister<SupplierInsightInvitationForm>;
  setValue: UseFormSetValue<SupplierInsightInvitationForm>;
}

const SelectShopsScreen: React.FC<ISelectShopsScreenProps> = ({
  getValues,
  register,
  setValue,
}) => {
  const { data: stores, loading: isLoading } = useShopSelector();
  const allShops = stores?.shopSelector?.allShops;
  const sortedGroups = sortGroupFromBiggestToSmallest(
    stores?.shopSelector?.groups
  );

  const [selectedGroups, setSelectedGroups] = React.useState<
    IShopSelectorGroupType[]
  >(getValues?.().groups || [sortedGroups[0]]);
  const [selectedShops, setSelectedShops] = React.useState<IThreadV4Type[]>(
    getValues?.().shops || []
  );

  const comparisonOptions = React.useMemo(
    () => [
      {
        key: 'groups',
        title: 'Groups',
        options: sortedGroups,
      },
      {
        key: 'shops',
        title: 'Shops',
        options: allShops || [],
      },
    ],
    [allShops, sortedGroups]
  );

  const handleToggleButtonClick = (
    key: 'shops' | 'groups',
    selectedThread: IThreadV4Type
  ) => {
    if (key === 'groups') {
      setSelectedGroups((prev) => {
        const newValue = getNewValue(prev, selectedThread);
        setValue('groups', newValue);
        setValue('shops', []);

        setSelectedShops([]);

        return newValue;
      });
    } else {
      setSelectedShops((prev) => {
        const newValue = getNewValue(prev, selectedThread);
        setValue('shops', newValue);
        setValue('groups', []);

        setSelectedGroups([]);

        return newValue;
      });
    }
  };

  React.useEffect(() => {
    register('shops', { value: selectedShops });
    register('groups', { value: selectedGroups });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {isLoading && <p className="py-4">Loading...</p>}

      <div className="no-scrollbar flex max-h-[500px] flex-col gap-2 overflow-hidden overflow-y-auto">
        <Tabs
          selectedTab="groups"
          classes={{
            root: 'mt-6',
            tabList: 'flex-wrap  gap-2',
            tabItem: 'rounded-md px-3 py-2 text-sm font-bold',
          }}
        >
          {comparisonOptions
            .filter(({ options }) => options.length > 0)
            .map(({ key, title, options }) => (
              <TabItem key={key} title={title}>
                <div className="my-4 flex flex-col gap-2">
                  {options?.map((thread) => {
                    const shopLogo = getTwistByKey(Twist.Logo, thread)?.value;

                    const isSelected = checkIfSelected(
                      key,
                      thread,
                      selectedShops,
                      selectedGroups
                    );

                    return (
                      <ListItem key={thread.id} tag="div" justify="between">
                        <button
                          className="flex w-full cursor-pointer items-center justify-between"
                          type="button"
                          onClick={() => {
                            handleToggleButtonClick(
                              key as 'shops' | 'groups',
                              thread
                            );
                          }}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              handleToggleButtonClick(
                                key as 'shops' | 'groups',
                                thread
                              );
                            }
                          }}
                        >
                          <div className="flex items-center overflow-x-hidden">
                            <span className="mr-4 flex h-12 w-12 items-center justify-center overflow-hidden rounded-full">
                              {shopLogo ? (
                                <Avatar src={shopLogo} />
                              ) : (
                                <span className="bg-pear h-full w-full text-white">
                                  <ShopIcon
                                    className="h-full w-full p-2"
                                    fillColor="currentColor"
                                  />
                                </span>
                              )}
                            </span>
                            <div className="flex flex-col items-start">
                              <p className=" truncate font-semibold text-black">
                                {thread?.title || ''}
                              </p>
                            </div>
                          </div>

                          <div
                            className={twMerge(
                              'border-gray-broder inline-flex h-7 w-7 items-center justify-center rounded-full border text-white sm:h-10 sm:w-10 sm:p-2',
                              isSelected && 'bg-green'
                            )}
                          >
                            <TickIcon light fillColor="currentColor" />
                          </div>
                        </button>
                      </ListItem>
                    );
                  })}
                </div>
              </TabItem>
            ))}
        </Tabs>
      </div>
    </div>
  );
};

export { SelectShopsScreen };
