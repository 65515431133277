import React, { FC, useEffect, useMemo, useState } from 'react';
import { InfoCircleIcon } from '@tapestry/shared/icons';
import { HeartbeatAreaChart } from '../HeartbeatAreaChart';
import { Spring } from 'react-spring';
import { Stack } from '@tapestry/weave';
import {
  HeartbeatQueryParams,
  HeartbeatComparisonQueriesVariable,
  HeartbeatVisualizationQueriesBaseVariables,
  IComparisonQueryVariable,
  IMetric,
} from '@tapestry/types';
import {
  getDiffDays,
  getDiffHours,
  getTotals,
  IComparisonLabel,
  normaliseChartData,
} from '../../utils';
import { parseToCurrencyString, SetQuery } from '@tapestry/shared/utils';
import { ErrorBoundary } from '@tapestry/shared/client';
import { ChartErrorView } from '../ChartErrorView/ChartErrorView';
import { Transition } from '@headlessui/react';
import { getTickerIntervalVariable } from '../../utils';
import { APP_DEFAULT_TIMEZONE } from '@tapestry/shared/constants';
import {
  getChartCaption,
  getComparisonMetricUnit,
} from './heartbeat-primary-chart-utils';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Maybe } from 'graphql/jsutils/Maybe';
import Skeleton from 'react-loading-skeleton';
import { usePrimaryChartData } from '../../hooks';

export interface IHeartbeatPrimaryChartProps {
  currentMeasure: IMetric;
  queryVariables: HeartbeatVisualizationQueriesBaseVariables;
  comparisonQueryVariable: IComparisonQueryVariable;
  comparisonVariable: HeartbeatComparisonQueriesVariable;
  setUrlQueryState: SetQuery<HeartbeatQueryParams>;
  currentShopTimezone: string | undefined;
  comparisonLabels: IComparisonLabel;
  activeScopeTitle: Maybe<string>;
}

export const HeartbeatPrimaryChart: FC<
  React.PropsWithChildren<IHeartbeatPrimaryChartProps>
> = ({
  currentMeasure,
  queryVariables,
  comparisonQueryVariable,
  comparisonVariable,
  children,
  setUrlQueryState,
  currentShopTimezone = APP_DEFAULT_TIMEZONE,
  comparisonLabels,
  activeScopeTitle,
}) => {
  const {
    title,
    hankUniversityLink,
    mainLabel,
    unit,
    helperText,
    aggregationType,
    zeroDataBeforeDate,
  } = currentMeasure;
  const { startDate, endDate } = queryVariables;
  const { mainChartBackEndSummaries: hasBackEndtotalsPermission } = useFlags();

  const isIntraDay = getDiffDays(startDate, endDate) === 0;
  const isHourly = getDiffHours(startDate, endDate) === 0;
  const tickerInterval = getTickerIntervalVariable(isIntraDay, isHourly);
  const [shouldShowComparisonNotes, setShouldShowComparisonNotes] =
    useState(false);

  const {
    data: { mainChart, comparedChart },
    loading: {
      mainChart: isLoadingChartData,
      comparedChart: isLoadingComparisionData,
    },
    error,
    refetch,
  } = usePrimaryChartData({
    variables: {
      ...queryVariables,
      ...comparisonQueryVariable,
      tickerInterval,
    },
  });

  const isLoading = React.useMemo(() => {
    if (isLoadingComparisionData) {
      return false;
    }

    return isLoadingChartData;
  }, [isLoadingChartData, isLoadingComparisionData]);

  const parsedChartData = useMemo(
    () =>
      normaliseChartData(
        mainChart.values,
        comparedChart.values,
        startDate,
        endDate,
        currentShopTimezone,
        zeroDataBeforeDate || null,
        tickerInterval
      ),
    [
      mainChart.values,
      comparedChart.values,
      startDate,
      endDate,
      currentShopTimezone,
      zeroDataBeforeDate,
      tickerInterval,
    ]
  );

  /**
   * Const
   */
  const chartCaption = getChartCaption(
    activeScopeTitle,
    mainLabel,
    startDate,
    endDate,
    currentShopTimezone
  );

  const { comparedDataUpToToday, fullPeriodTotal, totalUpToToday } = getTotals(
    parsedChartData,
    aggregationType
  );

  const dataSummary = hasBackEndtotalsPermission
    ? mainChart.summary?.value || 0
    : totalUpToToday;
  const comparisonSummary = hasBackEndtotalsPermission
    ? comparedChart.summary?.value
    : fullPeriodTotal;

  const diffToDateWithComparaison = dataSummary - comparedDataUpToToday;
  const diffColor = diffToDateWithComparaison < 0 ? 'text-red' : 'text-black';

  const shouldShowAdditionnalYAxis =
    getComparisonMetricUnit(comparisonVariable);

  const handleRefetchChart = () => {
    refetch({
      ...queryVariables,
      ...comparisonQueryVariable,
      tickerInterval,
    });
  };

  useEffect(() => {
    setShouldShowComparisonNotes(false);
  }, [currentMeasure]);

  return (
    <ErrorBoundary errorView={<ChartErrorView />}>
      <Stack>
        <div className="mx-auto px-4">
          <div className="mx-auto mb-10 max-w-screen-xl sm:mb-7">
            <div className="sm:flex sm:flex-row sm:items-center sm:justify-between md:mb-2">
              <h2
                data-testid="realtime-ticker"
                className="text-3xl font-bold capitalize"
              >
                <span className="text-2xl md:text-3xl">
                  <Spring
                    from={{ number: 0 }}
                    to={{ number: dataSummary }}
                    config={{ duration: 2100 }}
                    delay={1000}
                    onRest={() => setShouldShowComparisonNotes(true)}
                  >
                    {({ number }) => (
                      <span>
                        {parseToCurrencyString(
                          number,
                          unit?.symbol,
                          unit?.position
                        )}
                      </span>
                    )}
                  </Spring>
                  {unit?.symbol === '$' && (
                    <span className="ml-2 text-3xl">AUD</span>
                  )}
                </span>

                {hankUniversityLink && (
                  <span className="ml-2 hidden md:inline-block">
                    <a
                      href={hankUniversityLink as string}
                      target="_blank"
                      rel="noopener noreferrer"
                      title={`Learn more about ${title}`}
                    >
                      <span className="sr-only">Learn more about {title}</span>
                      <InfoCircleIcon
                        fillColor="#666666"
                        className="h-4 w-auto"
                      />
                    </a>
                  </span>
                )}
              </h2>

              <div className="sm:flex sm:items-center sm:justify-end sm:gap-2">
                {children}
              </div>
            </div>

            {!isLoading && dataSummary === 0 && (
              <p className="max-w-screen-xs text-gray-text mt-2 md:mt-0">
                No data available for the current period
              </p>
            )}

            {!isLoading && (
              <Transition
                as="div"
                appear
                show={shouldShowComparisonNotes}
                enter="transition-opacity duration-1000"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity duration-500"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                {diffToDateWithComparaison && (
                  <span className={`text-lg font-medium ${diffColor}`}>
                    {isLoadingComparisionData ? (
                      <Skeleton width={120} inline />
                    ) : (
                      <span>
                        {diffToDateWithComparaison < 0 ? '-' : '+'}&nbsp;
                        {parseToCurrencyString(
                          Math.abs(diffToDateWithComparaison),
                          unit?.symbol,
                          unit?.position
                        )}
                        {unit?.symbol === '$' && (
                          <span className="">&nbsp;AUD</span>
                        )}
                      </span>
                    )}
                    &nbsp;
                    {comparisonLabels?.headerString}
                  </span>
                )}

                <p>
                  Total comparison across the period:{' '}
                  {isLoadingComparisionData ? (
                    <Skeleton width={120} inline />
                  ) : (
                    <span>
                      {parseToCurrencyString(
                        comparisonSummary || 0,
                        unit?.symbol,
                        unit?.position
                      )}
                      {unit?.symbol === '$' && (
                        <span className="">&nbsp;AUD</span>
                      )}
                    </span>
                  )}
                </p>
              </Transition>
            )}

            {helperText && (
              <p className="max-w-screen-xs text-gray-text mt-2 md:mt-0">
                {helperText}
              </p>
            )}
          </div>

          <HeartbeatAreaChart
            chartData={parsedChartData}
            isLoadingChartData={isLoading}
            chartDataError={error}
            label={mainLabel || 'N/A'}
            unit={unit}
            comparisonUnit={shouldShowAdditionnalYAxis}
            isIntraDay={isIntraDay}
            isHourly={isHourly}
            setUrlQueryState={setUrlQueryState}
            comparisonTooltipLabel={comparisonLabels?.tooltipLabel}
            currentShopTimezone={currentShopTimezone}
            refetchChart={handleRefetchChart}
            chartCaption={chartCaption}
          />
        </div>
      </Stack>
    </ErrorBoundary>
  );
};

export default HeartbeatPrimaryChart;
