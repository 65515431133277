import { Maybe } from '@tapestry/shared/graphql';
import { IMetric } from '@tapestry/types';
import { Overlay, Pill } from '@tapestry/weave';
import { parseToCurrencyString } from '@tapestry/shared/utils';
import Link from 'next/link';
import * as React from 'react';

interface IStaffCardProps {
  id: string;
  firstName: string;
  lastName: string;
  role: string;
  value: string;
  background?: string;
  activeMeasureIcon: Maybe<IMetric['icon']>;
  handleMakeUrl: (id: string) => string;
}

const StaffCard: React.VFC<IStaffCardProps> = ({
  id,
  firstName,
  lastName,
  role,
  value,
  background,
  activeMeasureIcon,
  handleMakeUrl,
}) => {
  const Icon = React.cloneElement(activeMeasureIcon as React.ReactElement, {
    fillColor: '#ED7725',
  });

  return (
    <li
      className={`min-w-56 max-w-56 bg-cerulean-dark relative block h-72 w-56 cursor-pointer rounded-lg bg-cover bg-center bg-no-repeat focus:outline-none focus:ring-2`}
      style={{
        backgroundImage: background ? `url("${background}")` : '',
      }}
    >
      {background ? (
        <Overlay rounded="rounded-lg" />
      ) : (
        <div
          className="absolute inset-0 rounded-lg"
          style={{
            backgroundImage: `linear-gradient(rgba(14, 115, 187), rgba(0, 0, 0, .85))`,
          }}
        />
      )}

      <Link href={handleMakeUrl(id || '')} className="relative h-full w-full">
        <div className="flex h-full flex-col justify-end p-4 text-left">
          <div className="mb-10 text-white">
            <h3
              className={`truncate-2-lines text-base font-bold leading-6 sm:text-lg`}
            >
              {firstName} {lastName}
            </h3>
            <h4 className={`truncate-2-lines text-base capitalize leading-6`}>
              {role}
            </h4>
          </div>

          <div className="flex flex-row items-end justify-between">
            <span className="min-w-10 bg-blue flex h-10 w-10 items-center justify-center rounded-full text-center text-base font-bold uppercase text-white shadow-md">
              {firstName?.[0]}
              {lastName?.[0]}
            </span>
            <Pill
              size="small"
              label={value ? parseToCurrencyString(Number(value)) : ''}
              bgColor={'#fff'}
              textColor="text-black"
              iconLeft={Icon}
            />
          </div>
        </div>
      </Link>
    </li>
  );
};

export { StaffCard };
