import {
  HeartbeatComparisonQueriesVariable,
  UserSetting,
} from '@tapestry/types';
import { DEFAULT_COMPARISON_VARIABLE } from '../../../../constants';
import { IUserProfile } from '@tapestry/shared/graphql';
import { Maybe } from 'graphql/jsutils/Maybe';

export const getDefaultComparison = (
  profile: Maybe<IUserProfile>
): HeartbeatComparisonQueriesVariable => {
  return (
    profile?.user_settings?.[
      UserSetting.applet_analytic_chart_default_comparison
    ] || DEFAULT_COMPARISON_VARIABLE
  );
};
