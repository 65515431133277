import { IGetHeartbeatHeatmapChartData } from '@tapestry/shared/graphql';
import { Maybe } from 'graphql/jsutils/Maybe';
import isEmpty from 'lodash/isEmpty';

export const calculatePercentage = (a: number, b: number): number => {
  return a / b;
};

export const caculateOpacity = (percentage: number) => {
  const defaultOpacity = 0.5;
  const minOpacity = 0.08;

  if (!percentage) return defaultOpacity;

  return percentage < minOpacity ? minOpacity : percentage;
};

/**
 * finds highest value in the data to measure all other values against to get a percentage
 */
export const getMaxValueOfData = (
  data: Maybe<
    IGetHeartbeatHeatmapChartData['heartbeatChartHeatmapGridV2']['data']
  >
): number => {
  if (!data || isEmpty(data)) return 0;

  // * could measure if map + .apply is faster or if reduce is faster
  const maxValue = data.reduce((highestValue, current) => {
    if (!current?.value) return highestValue;

    return current.value > highestValue ? current.value : highestValue;
  }, 0);

  return maxValue || 0;
};

/**
 * Recreates the `name` property from the hour and day
 */
export const createLookupName = (day: number, hour: number): string => {
  const stringified = String(hour);
  const formattedHour = stringified.length === 1 ? `0${hour}` : stringified;

  return `${day}_${formattedHour}`;
};

/**
 * Finds the value using the lookup name
 */
export const findValueInList = (
  lookupName: string,
  data: IGetHeartbeatHeatmapChartData['heartbeatChartHeatmapGridV2']['data']
): number => {
  return data.find((entry) => entry.name === lookupName)?.value || 0;
};

/**
 * Teases out the opening hours of the shop from the data.
 *
 * As we dont currently have a shop opening hours stored in the database, this is one of the ways to do it
 */
export const extractOpeningHours = (
  data: IGetHeartbeatHeatmapChartData['heartbeatChartHeatmapGridV2']['data']
) => {
  if (isEmpty(data)) return { openingHour: 0, closingHour: 23 };

  const listofHours = data.map((entry) => Number(entry?.name?.split('_')?.[1]));
  const openingHour = Math.min.apply(null, listofHours);
  const closingHour = Math.max.apply(null, listofHours);

  return { openingHour, closingHour };
};
