import { RotateIcon } from '@tapestry/shared/icons';
import { Button, Stack } from '@tapestry/weave';
import React, { FC } from 'react';
import { IHeartbeatAreaChartProps } from './HeartbeatAreaChartUtils';

interface IHeartbeatAreaChartErrorPromptProps {
  refetchChart: IHeartbeatAreaChartProps['refetchChart'];
}

export const HeartbeatAreaChartErrorPrompt: FC<
  IHeartbeatAreaChartErrorPromptProps
> = ({ refetchChart }) => {
  return (
    <>
      <div className="absolute inset-0 opacity-75 bg-gray-100 z-10" />
      <div className="absolute inset-0 flex items-center justify-center z-10">
        <div className="max-w-xl">
          <Stack spacing="xsmall">
            <h5 className="text-lg">Unable to get your chart data</h5>
            <p>
              There has been a conflicting query and your request has been timed
              out.
              <br />
              Please try again by using the refresh button below.
              <br />
              If the error persist, contact support
            </p>

            <Button
              iconLeft={
                <RotateIcon
                  fillColor="currentColor"
                  className="h-full w-auto text-blue group-hover:text-white"
                />
              }
              status="secondary"
              spacing="small"
              onClick={refetchChart}
            >
              Refresh Chart
            </Button>
          </Stack>
        </div>
      </div>
    </>
  );
};
