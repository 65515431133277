import { APP_DEFAULT_TIMEZONE } from '@tapestry/shared/constants';
import { dateTime } from '@tapestry/shared/utils';

const PARSING_FORMAT = 'YYYY-MM-DD';

export const getDateLabel = (
  startDate: string,
  endDate: string,
  currentShopTimezone: string
) => {
  const start = dateTime.parseInTimezone(
    startDate,
    currentShopTimezone || APP_DEFAULT_TIMEZONE,
    PARSING_FORMAT
  );
  const end = dateTime.parseInTimezone(
    endDate,
    currentShopTimezone || APP_DEFAULT_TIMEZONE,
    PARSING_FORMAT
  );
  const startMonth = start.format('MMM');
  const finishMonth = end.format('MMM');

  const startYear = start.format('YY');
  const finishYear = end.format('YY');

  const startDay = start.date();
  const lastDay = end.date();

  const startWeekDay = start.format('ddd');
  const currentHour = start.format('h:mm a');
  const ishourly =
    dateTime.diff(startDate, endDate, 'hour', `${PARSING_FORMAT}THH:mm:ssZ`) ===
    0;

  const isSameMonth = startMonth === finishMonth;
  const isSameYear = startYear === finishYear;

  if (dateTime.isSameDate(start, end, currentShopTimezone, PARSING_FORMAT)) {
    return `${startWeekDay} ${startDay} ${startMonth} ${startYear}${
      ishourly ? ` - ${currentHour}` : ''
    }`;
  }

  if (isSameMonth && isSameYear) {
    return `${startDay} - ${lastDay} ${startMonth} ${startYear}`;
  }

  if (!isSameMonth && isSameYear) {
    return `${startDay} ${startMonth} - ${lastDay} ${finishMonth} ${startYear}`;
  }

  return `${startDay} ${startMonth} ${startYear}  - ${lastDay} ${finishMonth} ${finishYear}`;
};
