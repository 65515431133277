import { useUIContext } from '@tapestry/shared/client';
import {
  HeartbeatQueryParams,
  HeartbeatVisualizationQueriesBaseVariables,
  IComparisonQueryVariable,
  MeasureSlug,
  THREAD_TYPE,
} from '@tapestry/types';
import { SliderTray } from '@tapestry/weave';
import {
  parseToCurrencyString,
  safeJSONParse,
  SetQuery,
} from '@tapestry/shared/utils';
import { FC, useEffect, useState } from 'react';
import { MeasuresSliderMenuMeasureTile as Tiles } from './MeasuresSliderMenuMeasureTile';
import { getMetricTitle } from './MeasuresMenuUtils';
import { useGetHeartbeatSummaries } from '@tapestry/shared/graphql';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useRouter } from 'next/router';
import {
  getDiffDays,
  getDiffHours,
  getThreadEnabledMetricList,
  getTickerIntervalVariable,
} from '../../utils';
import omit from 'lodash/omit';
import { usePrimaryChartData } from '../../hooks';

interface IMeasuresSliderMenuProps {
  activeMeasureSlug: MeasureSlug;
  setUrlQueryParams: SetQuery<HeartbeatQueryParams>;
  queryVariables: HeartbeatVisualizationQueriesBaseVariables;
  comparisonQueryVariable: IComparisonQueryVariable;
  activeThreadKind: THREAD_TYPE;
}

function calculateTickerInterval(startDate: string, endDate: string) {
  const isIntraDay = getDiffDays(startDate, endDate) === 0;
  const isHourly = getDiffHours(startDate, endDate) === 0;
  const tickerInterval = getTickerIntervalVariable(isIntraDay, isHourly);

  return tickerInterval;
}

export const MeasuresSliderMenu: FC<IMeasuresSliderMenuProps> = ({
  activeMeasureSlug,
  queryVariables,
  comparisonQueryVariable,
  activeThreadKind,
}) => {
  const { query } = useRouter();
  const [{ threadTypeThemeColors }] = useUIContext();
  const { backgroundColor } = threadTypeThemeColors;
  const flags = useFlags();
  const { startDate, endDate } = queryVariables;
  const [hasNeverHadMainChartData, setHasNeverHadMainChartData] =
    useState(true);

  // TODO[high]: the api now supports un-stingify filter object , hence we can remove these calls
  const filters = safeJSONParse(queryVariables?.filters);
  const isGroupView = !!filters?.groupId;

  const threadEnabledMetricList = getThreadEnabledMetricList(
    flags,
    activeThreadKind,
    isGroupView
  );
  const activeMetricKeys = threadEnabledMetricList
    .filter(({ disabled }) => !disabled)
    .map(({ key }) => key);

  // * 25/05/2023 - Because:
  // - useGetHeartbeatSummaries is a big query
  // - and the database can't handle multiple query when heartbeat/rta loads
  // we are defering the fetching of summaries until the main chart is loaded / has data
  const mainChartQueryVariables = {
    ...queryVariables,
    ...comparisonQueryVariable,
    filters,
    tickerInterval: calculateTickerInterval(startDate, endDate),
  };
  const {
    data: {
      mainChart: { values: mainChartData },
    },
  } = usePrimaryChartData({
    variables: mainChartQueryVariables,
    fetchPolicy: 'cache-only',
  });

  const summariesVariables = omit(queryVariables, ['measure']);
  const { data: heartbeatSummariesData, loading: isLoadingSummary } =
    useGetHeartbeatSummaries({
      variables: {
        ...summariesVariables,
        measures: activeMetricKeys,
      },
      skip: hasNeverHadMainChartData,
    });

  // * on initial load, because there's no chart data and we do wait for chart data before fetching summaries, it display a `N/A` non-loading state
  // * This bothered management
  // * Hence the below prevent the `N/A` state in initial load.
  // * this won't be needed the day we don't need to `skip` the useGetHeartbeatSummaries anymore
  const isLoading = hasNeverHadMainChartData || isLoadingSummary;

  const getSummyData = (metricKey: MeasureSlug) =>
    heartbeatSummariesData?.heartbeatSummaries?.metrics.find(
      ({ name }) => name === metricKey
    )?.value || undefined;

  useEffect(
    function setHasDataIfData() {
      if (hasNeverHadMainChartData && mainChartData) {
        setHasNeverHadMainChartData(false);
      }
    },
    [mainChartData, hasNeverHadMainChartData]
  );

  return (
    <div className="relative w-full pt-4 md:pt-6">
      {/* colored banner behind the slider menu */}
      {activeThreadKind !== THREAD_TYPE.SHOP && (
        <div
          className={`absolute inset-x-0 bottom-1/2 top-0 ${backgroundColor}`}
        />
      )}

      <nav className="px-2" data-testid="metrics-list">
        <SliderTray
          containerClassName="max-w-screen-xl xl:mx-auto"
          listClassName="pb-6"
          gradientsColor="#f6f6f6"
          noEdgeGradients={activeThreadKind !== THREAD_TYPE.SHOP}
          hideArrowsBelowTablet
          snapAlign="snap-start"
        >
          {threadEnabledMetricList.map((metric) => {
            const summaryValue = getSummyData(metric.key);
            const subTitle = summaryValue
              ? parseToCurrencyString(
                  summaryValue,
                  metric.unit?.symbol,
                  metric.unit?.position
                )
              : undefined;

            return (
              <Tiles
                metric={metric}
                metricTitle={getMetricTitle(
                  metric,
                  activeThreadKind,
                  activeMeasureSlug
                )}
                metricSubtitle={subTitle}
                key={metric.key}
                isActiveCategory={activeMeasureSlug === metric.key}
                shopIsActiveThread={activeThreadKind === THREAD_TYPE.SHOP}
                urlQuery={query}
                isLoading={isLoading}
              />
            );
          })}
        </SliderTray>
      </nav>
    </div>
  );
};

export default MeasuresSliderMenu;
