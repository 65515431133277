import { OnboardingSlide } from '@tapestry/types';
import retailOnboardingscreen5 from './retail-onboarding-5v3.jpg';

export const TOP_SUPPLIERS_SLIDES: OnboardingSlide[] = [
  {
    image: retailOnboardingscreen5 as unknown as string,
    header: 'Insights are valuable',
    text: 'Suppliers require real-time data on the performance of their products, brands, and categories with customers. As a retailer, you have valuable insight into the performance of your suppliers and can provide this information to them.',
  },
  {
    image: retailOnboardingscreen5 as unknown as string,
    header: 'Drive Additional Rebates',
    text: "Your insights into the performance of your suppliers' products, brands, and categories are extremely valuable, as they can help suppliers to make informed decisions about pricing, promotional activity, production quantities, and in-store placement. As part of your supplier trading terms, you may be able to request additional rebates in exchange for providing real-time insights.",
  },
  {
    image: retailOnboardingscreen5 as unknown as string,
    header: 'You’re in control of your data',
    text: 'When sharing insights, you have the ability to choose which insights to share and with whom. You have control over the data you own, and it is important to protect the privacy of customers by not sharing personal information such as phone numbers and email addresses with third parties. As data privacy is a concern for everyone, we will not allow the sharing of this type of information.',
  },

  {
    image: retailOnboardingscreen5 as unknown as string,
    header: 'Sharing insights with suppliers is easy',
    text: 'To share insights with your suppliers, simply search for the supplier, press the share button, and enter the email address and the insights you want to share. You have control over what you share, as it is your data. You can configure the insights that you want to share with your suppliers.',
  },
];
