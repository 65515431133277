import { HeartbeatAppletFilters } from '@tapestry/types';

/**
 * isVoid filter
 *
 * has 3 options
 * true is "only the voided"
 * false is "dont include voided" - DEFAULT
 * undefined is "only the voided"
 *
 * We map `null` in our system to `undefined` for the api
 */
const mapIsVoidValue = (isVoid: HeartbeatAppletFilters['isVoid']) => {
  const mappedValue = JSON.parse(isVoid) ?? undefined; // Overrides `null` with `undefined`

  return mappedValue;
};

export const mapActiveFiltersToQueryFilters = (
  activeFilters: HeartbeatAppletFilters | undefined
) => {
  if (!activeFilters) return {};

  const isVoid = mapIsVoidValue(activeFilters.isVoid);

  return { ...activeFilters, isVoid };
};
