import { USER_ERROR_MESSAGES } from '@tapestry/shared/constants';

export const handleNotifyUserIfFetchError = (
  addToast,
  departmentsError,
  topDepartmentsError,
  topProductsError,
  topCategoriesError,
  topSpacesError,
  bestTimesError,
  tabCategoriesError,
  tabProductsError
) => {
  const addErrorToastWith = (content) => {
    addToast({ type: 'error', content });
  };

  // if (shopsError) {
  //   addErrorToastWith(USER_ERROR_MESSAGES.heartbeat.fetchShop);
  // }
  if (departmentsError) {
    addErrorToastWith(USER_ERROR_MESSAGES.heartbeat.fetchDepartments);
  }

  if (topDepartmentsError) {
    addErrorToastWith(
      USER_ERROR_MESSAGES.heartbeat.metrics.fetchTopDepartments
    );
  }

  if (topProductsError) {
    addErrorToastWith(USER_ERROR_MESSAGES.heartbeat.metrics.fetchTopProducts);
  }

  if (topCategoriesError) {
    addErrorToastWith(USER_ERROR_MESSAGES.heartbeat.metrics.fetchTopCategories);
  }

  if (topSpacesError) {
    addErrorToastWith(USER_ERROR_MESSAGES.heartbeat.metrics.fetchTopSpaces);
  }

  if (bestTimesError) {
    addErrorToastWith(USER_ERROR_MESSAGES.heartbeat.metrics.fetchBestTimes);
  }

  if (tabCategoriesError) {
    addErrorToastWith(USER_ERROR_MESSAGES.heartbeat.metrics.fetchAllCategories);
  }

  if (tabProductsError) {
    addErrorToastWith(USER_ERROR_MESSAGES.heartbeat.metrics.fetchAllProducts);
  }
};
