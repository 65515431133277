import { loadable } from '@tapestry/shared/lazy-load';
import { LoadingIndicator } from '@tapestry/weave';

const HorizontalChartLazy = loadable(() => import('./HeartbeatHorizontalBar'), {
  chunkName: 'LazyHorizontalBarChart',
  fallbackComponent: <LoadingIndicator />,
});

export { HorizontalChartLazy };
export { HeartbeatHorizontalBar } from './HeartbeatHorizontalBar';
