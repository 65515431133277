import { HeartbeatLoadingGrayIcon } from '@tapestry/shared/icons';
import Skeleton from 'react-loading-skeleton';

export const HeartbeatAreaChartLoadingState = ({
  loadingMessage,
}: {
  loadingMessage: string | null;
}) => {
  return (
    <div className="relative h-full overflow-hidden overflow-x-hidden py-10">
      <HeartbeatLoadingGrayIcon />

      {loadingMessage ? (
        <p className="absolute top-1/2 left-1/2 -translate-x-1/2 transform font-medium tracking-wide text-gray-700">
          {loadingMessage}
        </p>
      ) : null}

      <ul className="mt-1 flex items-center space-x-6 overflow-x-hidden px-4">
        {[...Array(40).keys()].map((each) => (
          <Skeleton key={each} height={30} width={30} circle />
        ))}
      </ul>
    </div>
  );
};
