import { BANNER_HEIGHT, NAVBAR_HEIGHT } from '@tapestry/shared/constants';
import { FrameContent } from '@tapestry/weave';
import {
  HeartbeatDateRangeSelector,
  IHeartbeatDateRangeSelectorProps,
} from '../../HeartbeatDateRangeSelector';
import { FC } from 'react';
import {
  ComparisonButton,
  IComparisonButtonProps,
} from '../../comparison/ComparisonButton';
import {
  AppletFilterButton,
  MoreButtonEllipsis,
} from '@tapestry/shared/components';

import { IDropDownActionItem } from '@tapestry/types';
import { ExportButton } from '../../exports/ExportButton';
import { useUIContext } from '@tapestry/shared/client';

interface IShopThreadHeaderProps {
  dateRangeSelectorProps: IHeartbeatDateRangeSelectorProps;
  filterButtonProps: {
    onClick: VoidFunction;
    onReset: VoidFunction;
    activeFiltersCount: number;
  };
  onExportButtonClick: VoidFunction;
  comparisonButtonProps: IComparisonButtonProps;
  ellipsisItems: IDropDownActionItem[];
}

const ShopThreadHeader: FC<IShopThreadHeaderProps> = ({
  dateRangeSelectorProps,
  filterButtonProps,
  onExportButtonClick,
  comparisonButtonProps,
  ellipsisItems,
}) => {
  const [{ hasTopBannersShowing }] = useUIContext();
  const top = hasTopBannersShowing
    ? NAVBAR_HEIGHT + BANNER_HEIGHT
    : NAVBAR_HEIGHT;

  return (
    <div className="sticky z-20 bg-white shadow-md" style={{ top }}>
      <FrameContent maxWidth="xl">
        <div className="flex items-center justify-between space-x-3">
          <div className="flex flex-1 items-center justify-between">
            <h1
              data-testid="analytic-title"
              className="hidden text-left text-xl font-bold tracking-wide text-black md:text-2xl xl:block"
            >
              Real-time Analytics
            </h1>
            <HeartbeatDateRangeSelector {...dateRangeSelectorProps} />
          </div>
          <div className="flex items-center justify-end space-x-3">
            <ComparisonButton {...comparisonButtonProps} />
            <div className="hidden items-center justify-end space-x-3 sm:flex">
              <AppletFilterButton
                activeFiltersCount={filterButtonProps.activeFiltersCount}
                handleOpenModal={filterButtonProps.onClick}
                handleFiltersReset={filterButtonProps.onReset}
              />
              <ExportButton onClick={onExportButtonClick} />
            </div>

            <MoreButtonEllipsis items={ellipsisItems} />
          </div>
        </div>
      </FrameContent>
    </div>
  );
};

export { ShopThreadHeader };
export type { IShopThreadHeaderProps };
