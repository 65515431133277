import { string, object } from 'yup';

export const schema = object({
  email: string()
    .email('You have not entered a valid email address.')
    .required(),
  mobile: string()
    .min(7, 'This phone number is too short')
    .matches(
      /^\+/i,
      'Invalid format: Start with the "+", then country code, then phone number'
    )
    .required(),
  firstName: string()
    .min(2, 'Your first name is too short')
    .matches(
      /^[a-z ,.'-]+$/i,
      'This is not a valid character. Valid characters include A-Z a-z ,.- and spaces.'
    )
    .required(),
  lastName: string()
    .min(2, 'Your last name is too short')
    .matches(
      /^[a-z ,.'-]+$/i,
      'This is not a valid character. Valid characters include A-Z a-z ,.- and spaces.'
    )
    .required(),
});
