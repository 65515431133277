import { IDataValue } from '@tapestry/shared/graphql';
import isEmpty from 'lodash/isEmpty';

/**
 * Teases out the opening hours of the shop from the data.
 *
 * As we dont currently have a shop opening hours stored in the database, this is one of the ways to do it
 */
export const extractOpeningHours = (data: IDataValue[] | undefined) => {
  if (isEmpty(data) || !data) return { openingHour: 0, closingHour: 24 };

  const listofHours = data
    .filter((entry) => entry.value)
    .map((entry) => Number(entry?.name?.split(' ')?.[1]?.slice(0, 2)));
  const openingHour = Math.min.apply(null, listofHours);
  const closingHour = Math.max.apply(null, listofHours);

  return { openingHour, closingHour };
};
