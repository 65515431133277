import {
  useInviteSupplierAccess,
  useLinkSupplierUser,
} from '@tapestry/shared/graphql';
import { ArrowRightIcon } from '@tapestry/shared/icons';
import { Modal } from '@tapestry/weave';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { UserInfo } from '../../components/UserInfo';
import { useToast } from '@tapestry/shared/client';
import pick from 'lodash/pick';
import { Maybe } from 'graphql/jsutils/Maybe';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from '../../utils/new-user-schema';
import {
  SupplierInsightInvitationForm,
  SupplierInsightInvitationFormNewUserSection,
} from './permission-wizard-types';
import { WIZARD_SCREENS } from './permission-wizard-screens';
import { parseFormDataToVariables } from './permission-wizard-utils';

export interface IPermissionWizardScreenProps {
  onCloseWizard: VoidFunction;
}

const PermissionWizardScreen: React.FC<IPermissionWizardScreenProps> = ({
  onCloseWizard,
}) => {
  const [currentScreenIndex, setCurrentScreenIndex] = React.useState(0);
  const currentProgress = `${
    ((currentScreenIndex + 1) / (WIZARD_SCREENS.length - 1)) * 100
  }%`;
  const currentScreen = WIZARD_SCREENS[currentScreenIndex];
  const ScreenComponent = currentScreen.component;

  const { addToast } = useToast();
  const [
    linkSupplierUser,
    { loading: isLinkingSupplier, data: linkedSupplierUserData },
  ] = useLinkSupplierUser({
    onCompleted: ({ linkSupplierUser }) => {
      if (linkSupplierUser) {
        setCurrentScreenIndex((currentScreen) => currentScreen + 1);
      }
    },
    onError: () => {
      addToast({
        type: 'error',
        content:
          'A problem occurs trying to add this user. Try to submit again and if the error persits, please contact support',
      });
    },
  });

  const [inviteSupplierAccess, { loading: isSendingInvitation }] =
    useInviteSupplierAccess({
      onError: () => {
        addToast({
          type: 'error',
          content:
            'A problem occurs trying to invite this user. Try to submit again and if the error persits, please contact support',
        });
      },
      onCompleted: ({ inviteSupplierAccess }) => {
        if (inviteSupplierAccess) {
          setCurrentScreenIndex((currentIndex) => {
            const isAtLastSlide = currentIndex === WIZARD_SCREENS.length - 1;

            if (isAtLastSlide) {
              return currentIndex;
            }

            return currentIndex + 1;
          });
        }
      },
    });

  const {
    watch,
    register,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
    getValues,
    control,
  } = useForm<SupplierInsightInvitationForm>({
    mode: 'onSubmit',
    resolver: yupResolver(schema),
  });

  const [firstName, lastName, email] = watch([
    'firstName',
    'lastName',
    'email',
  ]);

  const handleLinkSupplierUser = (formData: SupplierInsightInvitationForm) => {
    const NEW_USER_FIELDS = ['email', 'mobile', 'firstName', 'lastName'];
    const variables = pick(
      formData,
      NEW_USER_FIELDS
    ) as SupplierInsightInvitationFormNewUserSection;

    linkSupplierUser({ variables: { ...variables, role: 'supplier' } });
  };

  const handleSubmitInvitation = (
    formValues: SupplierInsightInvitationForm,
    linkedUserId: Maybe<string>
  ) => {
    if (!linkedUserId) {
      addToast({
        type: 'error',
        content: 'Could not link user with invitation. Please contact support',
      });

      return;
    }

    const hasSomeShopsSelected = formValues.shops?.length;
    const hasSomeGroupsSelected = formValues.groups?.length;

    // The current (10th Feb 2023) api doesnt accept a list of shop ids and group ids, it can only do one or the other.
    // hence sending an invite for each if they are present in the form
    // ! be mindful we are sending two invitation and you need to wait for both to finish
    if (hasSomeShopsSelected) {
      const variables = parseFormDataToVariables(
        formValues,
        linkedUserId,
        'shop'
      );

      inviteSupplierAccess({
        variables,
      });
    }

    if (hasSomeGroupsSelected) {
      const variables = parseFormDataToVariables(
        formValues,
        linkedUserId,
        'shop_group'
      );

      inviteSupplierAccess({
        variables,
      });
    }
  };

  const handleFormSubmit = (formValues: SupplierInsightInvitationForm) => {
    if (currentScreen.key === 'message') {
      handleSubmitInvitation(
        formValues,
        linkedSupplierUserData?.linkSupplierUser
      );
    } else if (currentScreen.key === 'add-people') {
      handleLinkSupplierUser(formValues);
    } else {
      setCurrentScreenIndex((currentScreen) => currentScreen + 1);
    }
  };

  const handleBackButton = () => {
    if (currentScreenIndex === 0) {
      return onCloseWizard();
    }

    setCurrentScreenIndex((currentScreen) => currentScreen - 1);
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <Modal.Content>
        {currentScreen.title ? (
          <Modal.Title>{currentScreen.title}</Modal.Title>
        ) : null}

        {currentScreen.description ? (
          <Modal.Description>
            {typeof currentScreen.description === 'string'
              ? currentScreen.description
              : currentScreen.description(email)}
          </Modal.Description>
        ) : null}

        {currentScreen.key !== 'confirm' ? (
          <div className="just bg-gray-lightest my-8 grid grid-cols-2 rounded-lg p-4 ">
            <UserInfo
              user={{
                first_name: firstName || 'Firstname',
                last_name: lastName || 'Lastname',
                email: email || 'Email',
              }}
            />
            <div className="flex items-center justify-center">
              <span className="text-blue mr-2 flex-shrink-0 font-semibold">
                {currentScreen.shortTitle}
              </span>
              <div className="flex-1 overflow-hidden rounded-full bg-gray-200">
                <div
                  className="bg-blue h-4 rounded-full"
                  style={{ width: currentProgress }}
                />
              </div>
            </div>
          </div>
        ) : null}

        <ScreenComponent
          getValues={getValues}
          register={register}
          setValue={setValue}
          errors={errors}
          control={control}
          onDone={onCloseWizard}
        />
      </Modal.Content>

      {currentScreen.key !== 'confirm' ? (
        <Modal.Footer justify="sm:justify-between">
          <Modal.Button
            status="primary"
            type="submit"
            iconRight={
              currentScreen.key !== 'message' ? (
                <ArrowRightIcon light fillColor="currentColor" />
              ) : undefined
            }
            disabled={!isValid || isLinkingSupplier || isSendingInvitation}
            loading={isLinkingSupplier || isSendingInvitation}
          >
            {currentScreen.key === 'message' ? 'Confirm Access' : 'Next'}
          </Modal.Button>

          <Modal.Button
            status="text"
            onClick={handleBackButton}
            disabled={isLinkingSupplier || isSendingInvitation}
          >
            {currentScreenIndex === 0 ? 'Cancel' : 'Back'}
          </Modal.Button>
        </Modal.Footer>
      ) : null}
    </form>
  );
};

export { PermissionWizardScreen };
