import { Modal, ModalState } from '@tapestry/weave';
import * as React from 'react';
import { PermissionWizardScreen, UsersListScreen } from './screens';
import { ErrorBoundary, useToast } from '@tapestry/shared/client';

interface ISupplierInsightsModalProps {
  supplierId: string | null | undefined;
  modalState: ModalState;
}

const SupplierInsightsModal: React.FC<ISupplierInsightsModalProps> = ({
  supplierId,
  modalState,
}) => {
  const [shouldShowWizard, setShouldShowWizard] = React.useState(false);
  const { addToast } = useToast();

  const handleOpenWizardScreen = () => {
    setShouldShowWizard(true);
  };

  const handleCloseWizardScreen = () => {
    setShouldShowWizard(false);
  };

  const handleOnError = () => {
    addToast({
      type: 'error',
      content: 'A error has occured in the insight modal. Contact support',
    });
    modalState.close();
  };

  return (
    <ErrorBoundary onError={handleOnError}>
      <Modal state={modalState}>
        <Modal.Dialog>
          {shouldShowWizard ? (
            <PermissionWizardScreen onCloseWizard={handleCloseWizardScreen} />
          ) : (
            <UsersListScreen
              openWizardScreen={handleOpenWizardScreen}
              supplierId={supplierId}
            />
          )}
        </Modal.Dialog>
      </Modal>
    </ErrorBoundary>
  );
};

export { SupplierInsightsModal };
export default SupplierInsightsModal;
