import { loadable } from '@tapestry/shared/lazy-load';
import { LoadingIndicator } from '@tapestry/weave';

const HeatmapChartLazy = loadable(() => import('./HeartbeatHeatmapChart'), {
  chunkName: 'heatmap-chart',
  fallbackComponent: <LoadingIndicator />,
});

export { HeatmapChartLazy };
export { HeartbeatHeatmapChart } from './HeartbeatHeatmapChart';
