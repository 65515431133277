import { IDataValue } from '@tapestry/shared/graphql';
import {
  IDataValuePlaceholder,
  IsoString,
  IParsedHeartbeatMainChartDataValueList,
} from '@tapestry/types';
import dayjs from 'dayjs';
import { extractOpeningHours } from '../extract-opening-hours';
import { getComparisonData, getDayData } from './dataGetter';

const _should = (
  zeroDataBeforeDate: string | IsoString | null,
  day: IDataValuePlaceholder
) => {
  if (!zeroDataBeforeDate) return false;

  const isEarlierThanZeroDate = dayjs(day.name).isBefore(
    zeroDataBeforeDate,
    'day'
  );

  return zeroDataBeforeDate && isEarlierThanZeroDate ? true : false;
};

const zeroOut = (day: IDataValuePlaceholder) => ({
  ...day,
  value: 0,
  comparison: 0,
});

const sliceToOpeningHours = (
  placeholderArray: IDataValuePlaceholder[],
  {
    openingHour,
    closingHour,
  }: {
    openingHour: number;
    closingHour: number;
  }
) => {
  const startIndex = placeholderArray.findIndex(
    (entry) => Number(entry.name.split(' ')[1].slice(0, 2)) === openingHour
  );
  const endIndex = placeholderArray.findIndex(
    (entry) => Number(entry.name.split(' ')[1].slice(0, 2)) === closingHour
  );

  return placeholderArray.slice(startIndex, endIndex + 1);
};

const getArrayToBeMapped = (
  data: IDataValue[],
  isIntraDay: boolean,
  isHourly: boolean,
  placeholderDateArray: IDataValuePlaceholder[]
) => {
  const openingHours = extractOpeningHours(data);

  return isIntraDay && !isHourly
    ? sliceToOpeningHours(placeholderDateArray, openingHours)
    : placeholderDateArray;
};

/**
 * Responsible for manipuliating to data we want to display
 */
export const fillDatePlaceholderArray = (
  placeholderDateArray: IDataValuePlaceholder[],
  data: IDataValue[],
  compareData: IDataValue[],
  zeroDataBeforeDate: string | IsoString | null = null,
  isIntraDay: boolean,
  isHourly: boolean
): IParsedHeartbeatMainChartDataValueList => {
  const arrayToBeMapped = getArrayToBeMapped(
    data,
    isIntraDay,
    isHourly,
    placeholderDateArray
  );

  const filledArray = arrayToBeMapped.map((placeholderDay) => {
    if (_should(zeroDataBeforeDate, placeholderDay))
      return zeroOut(placeholderDay);

    // get the day data and some "metadata" from the datalist
    const { resultOfDay, matchingDayIndex } = getDayData(data, placeholderDay);
    const comparison = getComparisonData(
      compareData,
      matchingDayIndex,
      placeholderDay,
      arrayToBeMapped
    );

    return { ...resultOfDay, comparison };
  });

  return filledArray;
};
