import {
  ComparisonVariablesParam,
  MeasureParam,
  ReturnToParam,
  StringParam,
  withDefault,
} from '@tapestry/shared/utils';
import {
  HeartbeatComparisonQueriesVariable,
  MeasureSlug,
} from '@tapestry/types';
import { DEFAULT_COMPARISON_VARIABLE } from '../../../constants';

type DefaultQueryParams = {
  defaultStart: string;
  defaultEnd: string;
  defaultComparison: HeartbeatComparisonQueriesVariable;
};

export const getDefaultQueryParams = ({
  defaultStart,
  defaultEnd,
  defaultComparison,
}: DefaultQueryParams) => {
  return {
    groupId: StringParam,
    mVar: StringParam,
    departmentId: StringParam,
    categoryId: StringParam,
    spaceId: StringParam,
    productId: StringParam,
    supplierId: StringParam,
    staffId: StringParam,
    dem: StringParam,
    postcode: StringParam,
    tagId: StringParam,
    listId: StringParam,
    listName: StringParam,
    shop_id: StringParam, // * this is use as a patch for mobile
    returnTo: ReturnToParam,
    slug: withDefault(MeasureParam, MeasureSlug.TotalSales),
    shopId: withDefault(StringParam, null),
    startDate: withDefault(StringParam, defaultStart),
    endDate: withDefault(StringParam, defaultEnd),
    comparison: withDefault(
      ComparisonVariablesParam,
      defaultComparison || DEFAULT_COMPARISON_VARIABLE
    ),
  };
};
