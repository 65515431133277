import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { PrimaryChartDataDate } from '@tapestry/types';
import { DEVICE_WIDTH } from '@tapestry/shared/constants';
import {
  API_DATETIME_DAY_FORMAT,
  API_DATETIME_INTRADAY_FORMAT,
} from '../../../utils';
dayjs.extend(advancedFormat);
dayjs.extend(customParseFormat);

export const MONTH_LENGTH = 31;
export const THREE_MONTHS = 90;

/**
 * handles responsive tick label formatting
 */
export const handleTickFormatting = (
  tick: PrimaryChartDataDate | undefined,
  isIntraDay: boolean
) => {
  const isTabletOrSmaller = window.innerWidth < DEVICE_WIDTH.tablet.maxWidth;
  const responsiveDayFormat = isTabletOrSmaller ? 'D' : 'Do';
  const responsiveWeekdayFormat = isTabletOrSmaller ? 'dd' : 'ddd';
  const dateTimeFormat = isIntraDay
    ? API_DATETIME_INTRADAY_FORMAT
    : API_DATETIME_DAY_FORMAT;
  const hourFormat = isTabletOrSmaller ? 'hh' : 'hh:mm';

  const parsedDate = dayjs(tick, dateTimeFormat);

  const isToday = dayjs().isSame(parsedDate, 'day');
  const isCurrentHour = dayjs().isSame(parsedDate, 'hour');

  const monthName = parsedDate.format('MMM');
  const weekdayName = parsedDate.format(responsiveWeekdayFormat);
  const dayOfMonth = parsedDate.isValid()
    ? parsedDate.format(responsiveDayFormat)
    : tick;
  const hour = isIntraDay ? parsedDate.format(hourFormat) : null;
  const minutes = isIntraDay ? parsedDate.format('mm') : null;
  const meridiem = isIntraDay ? parsedDate.format('A') : null;

  return {
    dayOfMonth,
    monthName,
    weekdayName,
    hour,
    minutes,
    meridiem,
    isCurrentHour,
    isToday,
  };
};

/**
 * handles conditionnal rendering of the month label
 */
export const getMonthLabelShowCondition = (
  dataLength: number,
  isFirstmonthlyTick: boolean
) => {
  const isLongerThanAMonth = dataLength > MONTH_LENGTH; // depends wich month but using 31 days for now
  const isLongerThanAQuarter = dataLength > THREE_MONTHS;
  const between31to90Days = isLongerThanAMonth && !isLongerThanAQuarter;

  const showCondition =
    (isLongerThanAQuarter && isFirstmonthlyTick) || between31to90Days;

  return showCondition;
};
