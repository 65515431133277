import { ROUTE_PATHS } from '@tapestry/shared/constants';
import { twMerge } from 'tailwind-merge';
import {
  ThreadTypeToThemeColorMapper,
  useToast,
  useUIContext,
} from '@tapestry/shared/client';
import { MeasureSlug, THREAD_TYPE, Twist } from '@tapestry/types';
import {
  ReturnToParam,
  getTwistByKey,
  mapThreadTypeToThreadIcon,
  useQueryParam,
} from '@tapestry/shared/utils';
import { FrameContent } from '@tapestry/weave';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FC } from 'react';
import Skeleton from 'react-loading-skeleton';
import { NextRouter, useRouter } from 'next/router';
import { stringify } from 'querystring';
import { CloseIcon, ExportIcon } from '@tapestry/shared/icons';
import {
  IShopSelectorGroupType,
  IThreadV4Type,
  Maybe,
} from '@tapestry/shared/graphql';
import { getThreadEnabledMetricList } from '../../../utils';

interface IThreadHeader {
  threadType: THREAD_TYPE;
  threadTitle: Maybe<string> | undefined;
  onShareInsight: VoidFunction;
  isGroupView: boolean;
  activeMeasureSlug: MeasureSlug;
}

type LDFlag = { [key: string]: any };

export const ALLOWED_SWITCHABLE_SCOPE = [
  THREAD_TYPE.COLLECTION,
  THREAD_TYPE.PRODUCT,
  THREAD_TYPE.CATEGORY,
  THREAD_TYPE.SPACE,
  THREAD_TYPE.DEPARTMENT,
  THREAD_TYPE.SUPPLIER,
];

export const getLogo = (
  activeShopOrGroup: IShopSelectorGroupType | IThreadV4Type | null
) => {
  if (!activeShopOrGroup) return null;

  return 'logo' in activeShopOrGroup
    ? activeShopOrGroup.logo
    : getTwistByKey(Twist.Logo, activeShopOrGroup as IThreadV4Type)?.value;
};

const CARRIED_OVER_KEYS = ['startDate', 'endDate', 'slug', 'comparison'];

function extractCarriedOverParams(query: NextRouter['query'], noSlug: boolean) {
  const filteredKeys = noSlug
    ? CARRIED_OVER_KEYS.filter((key) => key !== 'slug')
    : CARRIED_OVER_KEYS;

  const carriedOverParams = Object.entries(query).reduce(
    (acc, [key, value]) => {
      if (filteredKeys.includes(key)) {
        acc[key] = value;
      }

      return acc;
    },
    {}
  );

  return carriedOverParams;
}

function checkMeasureIsAvailableAtDestination(
  flags: LDFlag,
  isGroupView: boolean,
  activeMeasureSlug: MeasureSlug
) {
  const threadEnabledMetricList = getThreadEnabledMetricList(
    flags,
    THREAD_TYPE.SHOP,
    isGroupView
  );

  const isMeasureAvailable = threadEnabledMetricList.some(
    (metric) => metric.key === activeMeasureSlug
  );

  return isMeasureAvailable;
}

const ThreadHeader: FC<IThreadHeader> = ({
  threadType,
  threadTitle,
  onShareInsight,
  isGroupView,
  activeMeasureSlug,
}) => {
  const router = useRouter();
  const [returnTo] = useQueryParam('returnTo', ReturnToParam);
  const [{ threadTypeThemeColors }] = useUIContext();
  const {
    tempRetailHeartbeatSupplierInsights: hasTempRetailHeartbeatSupplierInsights,
    ...flags
  } = useFlags();
  const { addToast } = useToast();

  const { altBackgroundColor, iconColor } = threadTypeThemeColors;
  const IconComponent = mapThreadTypeToThreadIcon(threadType);
  const iconBackgroundColor =
    ThreadTypeToThemeColorMapper[threadType]?.iconBackgroundColor || 'bg-white';
  const textColor =
    ThreadTypeToThemeColorMapper[threadType]?.textColor || 'text-white';

  function redirectToHeartbeatOverview() {
    const isMeasureAvailable = checkMeasureIsAvailableAtDestination(
      flags,
      isGroupView,
      activeMeasureSlug
    );

    const carriedOverQueryParam = extractCarriedOverParams(
      router.query,
      !isMeasureAvailable
    );

    if (!isMeasureAvailable) {
      addToast({
        type: 'info',
        content:
          'Measure unavailable at this destination. Redirecting to Sales.',
      });
    }

    router.push(
      ROUTE_PATHS.build.heartbeat({ qp: stringify(carriedOverQueryParam) })
    );
  }

  const handleGoBack = () => {
    if (returnTo) {
      router.push(returnTo);
    } else {
      // * Always brings them back to the overview instead of the actual last view
      redirectToHeartbeatOverview();
    }
  };

  return (
    <div
      data-testid="active-thread-header"
      className={twMerge(
        'sticky z-20 top-0',
        altBackgroundColor ?? 'bg-primary'
      )}
    >
      <FrameContent maxWidth="xl">
        <div className="flex items-center justify-between text-white">
          <div className="flex items-center">
            <div
              className={twMerge(
                'flex h-10 w-10 items-center justify-center rounded-full',
                'p-3 sm:h-12 sm:w-12',
                iconBackgroundColor
              )}
            >
              {threadType === THREAD_TYPE.STAFF ? (
                <span className={twMerge(textColor, 'font-bold')}>
                  {/* TODO: once confirm a logic for initials, make this a component */}
                  {threadTitle
                    ?.split(' ')
                    .map((token) => token[0])
                    .join('')}
                </span>
              ) : (
                <IconComponent
                  fillColor={iconColor ?? '#000'}
                  className="h-full w-auto"
                />
              )}
            </div>

            <div className="ml-2 text-left sm:ml-4">
              <h1 className="text-base font-bold sm:text-2xl">
                {threadTitle || <Skeleton width={200} />}
              </h1>
              {/* TODO @optimistic-updt Please review this */}
              <h2 className="-mt-1 text-xs capitalize">
                {threadType === THREAD_TYPE.COLLECTION
                  ? 'Manufacturers'
                  : threadType}
              </h2>
            </div>
          </div>

          <div className="flex items-center justify-end">
            {threadType === THREAD_TYPE.SUPPLIER &&
            hasTempRetailHeartbeatSupplierInsights ? (
              <button
                onClick={onShareInsight}
                className={twMerge(
                  'flex flex-shrink-0 items-center justify-center rounded-full border-2',
                  'hover:border-primary focus:border-primary mr-2 py-2 px-4 font-bold text-white shadow-sm focus:outline-none',
                  'active:border-primary-dark'
                )}
              >
                <ExportIcon className="mr-2 h-4 w-4" fillColor="currentColor" />
                <span>Share Insights</span>
              </button>
            ) : null}

            <button
              type="button"
              title={`Go back to ${returnTo ? 'search' : 'Heartbeat overview'}`}
              className="flex h-6 items-center justify-center p-1 sm:h-6"
              onClick={handleGoBack}
              onKeyDown={({ key }) => {
                if (key === 'Enter') {
                  handleGoBack();
                }
              }}
            >
              <p className="sr-only">Go back</p>
              <CloseIcon light fillColor="#fff" />
            </button>
          </div>
        </div>
      </FrameContent>

      <div className="w-full border-b border-black" style={{ opacity: 0.1 }} />
    </div>
  );
};

export { ThreadHeader };
