import { useToast } from '@tapestry/shared/client';
import {
  IUser,
  useSupplierLinkedUsersLazyQuery,
} from '@tapestry/shared/graphql';
import { PlusIcon, SearchIcon } from '@tapestry/shared/icons';
import { Button, ListItem, Modal } from '@tapestry/weave';
import { Maybe } from 'graphql/jsutils/Maybe';
import * as React from 'react';
import { UserInfo } from '../../components/UserInfo';

const bySearchValue = (user: IUser | null, query: string) => {
  if (!query) {
    return true;
  }

  return `${user?.first_name || ''}${user?.last_name || ''}${user?.email || ''}`
    .toLowerCase()
    .includes(query.toLowerCase());
};

interface IUserListScreenProps {
  supplierId: Maybe<string>;
  openWizardScreen: (id?: string) => void;
}

const UsersListScreen: React.FC<IUserListScreenProps> = ({
  supplierId,
  openWizardScreen,
}) => {
  const [query, setQuery] = React.useState('');
  const { addToast } = useToast();
  const [getSupplierLinkedUsers, { data: supplierLinkedUsersData }] =
    useSupplierLinkedUsersLazyQuery({
      onError: () => {
        addToast({
          type: 'error',
          content:
            'A problem occurs getting your shared insights user. Support has been informed',
        });
      },
    });
  const userList = supplierLinkedUsersData?.supplierLinkedUsers || [];

  const filteredAccesses = userList.filter((user) =>
    bySearchValue(user, query)
  );

  React.useEffect(() => {
    getSupplierLinkedUsers({ variables: { supplierId } });
  }, [supplierId, getSupplierLinkedUsers]);

  return (
    <Modal.Content>
      <Modal.Title>Share supplier insights</Modal.Title>
      <Modal.Description>
        Suppliers want data and insights into how their products and categories
        are performing. You can now share this with your suppliers in real-time.
      </Modal.Description>

      <div className="py-8 text-center">
        <Button
          status="primary"
          rounded="rounded-full"
          className="text-white"
          iconLeft={<PlusIcon light fillColor="currentColor" />}
          onClick={() => openWizardScreen()}
        >
          Add User
        </Button>

        {filteredAccesses.length > 0 ? (
          <div className="my-4 flex flex-col items-start">
            <label htmlFor="search" className="block pb-1 font-bold">
              People with access
            </label>
            <div className="border-gray-lightest flex w-full rounded border p-4">
              <SearchIcon
                className="text-gray-text mr-2 h-6 w-6 flex-shrink-0"
                fillColor="currentColor"
              />
              <input
                name="search"
                className="text-blue placeholder:text-gray-text flex-grow text-base tracking-wider outline-none"
                placeholder="Search people with access"
                onChange={(e) => setQuery(e.target.value)}
              />
            </div>
          </div>
        ) : null}

        <ul className="flex max-h-80 flex-col gap-2 overflow-y-auto">
          {filteredAccesses.map((user) => (
            <ListItem key={user?.id} tag="li" justify="between">
              <UserInfo user={user} />
            </ListItem>
          ))}
        </ul>
      </div>
    </Modal.Content>
  );
};

export { UsersListScreen };
