import { IGetSimpleThread } from '@tapestry/shared/graphql';
import { HeartbeatQueryParamsState, THREAD_TYPE } from '@tapestry/types';

export const getTitle = (
  activeThreadKind: THREAD_TYPE,
  urlQueryState: HeartbeatQueryParamsState,
  data?: IGetSimpleThread
) => {
  if (activeThreadKind === THREAD_TYPE.COLLECTION) {
    return urlQueryState.tagId;
  }

  if (activeThreadKind === THREAD_TYPE.LIST) {
    return urlQueryState.listName || 'N/A';
  }

  return data?.threadV4?.title ?? '';
};
