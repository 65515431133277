import { dateTime } from '@tapestry/shared/utils';

export type NormaliseInterval =
  | 'hour'
  | 'day'
  | 'isoWeek'
  | 'week'
  | 'month'
  | 'year';

/**
 * Strandardise datetimes to generally the beginning or end of a define period (@default 'day')
 *
 * Pass in the date(s) you need normalised, signaling which one is the start and end
 *
 * Possible periods: 'hour'
  | 'day'
  | 'isoWeek'
  | 'week'
  | 'month'
  | 'year';
 *
 * @example
 * const normalisedDates = normaliseDatetime({start: '2022-09-27T12:44:09Z', end: '2022-09-27T12:44:09Z'})
 *
 * @example
 * const normalisedDates = normaliseDatetime({start: '2022-09-27T12:44:09Z'}, 'isoWeek')
 */
export const normaliseDatetime = (
  datetimes: { start?: string; end?: string },
  normalisePeriod: NormaliseInterval = 'day',
  timezone?: string
  // format or iso option ?
) => {
  const normalisedDatetimes: { start?: string; end?: string } = {};

  if (datetimes.start) {
    normalisedDatetimes.start = dateTime
      .convertToTimezone(datetimes.start, timezone)
      .startOf(normalisePeriod)
      .format();
  }

  if (datetimes.end) {
    normalisedDatetimes.end = dateTime
      .convertToTimezone(datetimes.end, timezone)
      .endOf(normalisePeriod)
      .format();
  }

  return normalisedDatetimes;
};
