import { loadable } from '@tapestry/shared/lazy-load';
import { LoadingIndicator } from '@tapestry/weave';

const CollectionsVisualizationLazy = loadable(
  () => import('./CollectionsVisualization'),
  {
    chunkName: 'collections-visualizationLazy',
    fallbackComponent: <LoadingIndicator />,
  }
);

export { CollectionsVisualizationLazy };
export { CollectionsVisualization } from './CollectionsVisualization';
