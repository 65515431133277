import React, { useEffect, useState, FC } from 'react';
import {
  ExclamationCircleIcon,
  SearchIcon,
  SortDownIcon,
} from '@tapestry/shared/icons';
import {
  Card,
  MainHeading,
  Panel,
  ResponsiveDropdownMenu,
  SliderTray,
} from '@tapestry/weave';
import Skeleton from 'react-loading-skeleton';
import {
  HANK_UNI_URLS,
  ROUTE_PATHS,
  SUPPORT_EMAIL,
} from '@tapestry/shared/constants';
import {
  capitaliseString,
  parseToCurrencyString,
  safeJSONParse,
} from '@tapestry/shared/utils';
import { HeartbeatVisualizationQueriesBaseVariables } from '../../constants';
import {
  IDataValueId,
  useGetHeartbeatBarChartData,
  useGetHeartbeatBarChartPaginatedData,
} from '@tapestry/shared/graphql';
import { ErrorBoundary, useToast, useUIContext } from '@tapestry/shared/client';
import { IMetric, Visualization } from '@tapestry/types';
import { VisualizationSearchModal } from '@tapestry/shared/visualisation';
import { caculateOpacity, calculatePercentage } from './HeartbeatTabChartUtils';
import { HankGuideInfo } from '@tapestry/shared/components';
import { ChartErrorView } from '../ChartErrorView/ChartErrorView';
import isEmpty from 'lodash/isEmpty';
import { useLongLoading, useModal, useSortMenu } from '@tapestry/shared/hooks';
import Link from 'next/link';
import { useDebounce } from 'use-debounce';
import { captureException } from '@sentry/nextjs';

const NoResultsWarning: FC = () => (
  <Card bgColor="bg-gray-100">
    <div className="flex">
      <ExclamationCircleIcon className="h-6 w-auto flex-shrink-0" />
      <p className="ml-4">
        We're sorry, but we couldn't retrieve your data at this time. <br />
        This could be because there's no data available, or there might be an
        issue with our servers. <br />
        If you believe this to be an error, contact{' '}
        <a href={`mailto:${SUPPORT_EMAIL}`} className="text-primary underline">
          support
        </a>
        .
      </p>
    </div>
  </Card>
);

const getMaxValue = (data: IDataValueId[] | undefined) => {
  if (!data) return 0;
  const maxValue = Math.max(...data.map((item) => item.value || 0));

  return maxValue;
};

interface ITabChartQueryVariables
  extends HeartbeatVisualizationQueriesBaseVariables {
  scopeType: string;
  threadType: string;
}

export interface IHeartbeatTabChartProps {
  title: string;
  queryVariables: ITabChartQueryVariables;
  unit: IMetric['unit'];
  stringifyQueryParams: (id: string) => string;
  readOnly?: boolean;
  visualisation: Visualization;
}

const SEARCH_QUERY_DEBOUNCE = 250;
const SEARCH_RESULT_LIMIT = 20;
const INITIAL_PAGE_NO = 1;

export const HeartbeatTabChart: FC<IHeartbeatTabChartProps> = ({
  title,
  queryVariables,
  unit,
  stringifyQueryParams,
  readOnly,
  visualisation,
}) => {
  /**
   * Hooks
   */
  const { addToast } = useToast();
  const {
    items,
    state: { sortBy },
  } = useSortMenu();

  const searchModalState = useModal();
  const [
    {
      threadTypeThemeColors: { backgroundColor, textColor, ringColor },
    },
  ] = useUIContext();

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [debouncedSearch] = useDebounce(search, SEARCH_QUERY_DEBOUNCE);

  const filters = safeJSONParse(queryVariables?.filters);
  const hasId = filters?.groupId || filters?.shopId;
  const baseQueryVariables = React.useMemo(
    () => ({
      ...queryVariables,
      search: '',
      limit: SEARCH_RESULT_LIMIT,
      page: INITIAL_PAGE_NO,
      sortBy,
    }),
    [queryVariables, sortBy]
  );

  const { data: chartData, loading: isChartDataLoading } =
    useGetHeartbeatBarChartData({
      skip: !hasId,
      variables: baseQueryVariables,
      onError: () => {
        addToast({
          type: 'error',
          content: `An error has occured whilst retrieving the data for ${queryVariables.scopeType}. `,
        });
      },
      onCompleted: (data) => {
        const chartData = data?.heartbeatChartBarV3?.data || [];

        if (isEmpty(chartData)) {
          captureException('No data found for this query', {
            level: 'info',
            tags: { visualisation },
          });
        }
      },
    });

  const {
    data: modalData,
    loading: isModalDataLoading,
    refetch: refetchModalData,
  } = useGetHeartbeatBarChartPaginatedData({
    skip: !hasId,
    variables: {
      ...baseQueryVariables,
      sortBy,
    },
    onError: () => {
      addToast({
        type: 'error',
        content: `An error has occured whilst retrieving the data for ${queryVariables.scopeType}. `,
      });
    },
  });

  const { loadingMessage } = useLongLoading({
    mode: 'interval',
    isLoading: isChartDataLoading,
  });

  const initialChartDataResults = chartData?.heartbeatChartBarV3?.data;
  const dataList = modalData?.heartbeatChartBarV3?.data || [];
  const maxValue = getMaxValue(initialChartDataResults);
  const pagination = modalData?.heartbeatChartBarV3?.pagination;

  /**
   * Const
   */
  const handleStringifyParamsWithNewId = (id: string) => {
    if (stringifyQueryParams) {
      return stringifyQueryParams(id);
    }

    return '';
  };

  const handleSortbyChange = (sortBy: string) => {
    refetchModalData({ sortBy });
  };

  const notifyOfReadOnlyState = () => {
    alert('Coming Soon...');
  };

  const handlePreviousePage = (page?: number) => {
    setPage((currentPage) => {
      const newPage = page || currentPage - 1;
      const _page = newPage > INITIAL_PAGE_NO ? newPage : INITIAL_PAGE_NO;

      refetchModalData({ page: _page });

      return _page;
    });
  };

  const handleNextPage = (page?: number) => {
    setPage((currentPage) => {
      const newPage = page || currentPage + 1;

      refetchModalData({ page: newPage });

      return newPage;
    });
  };

  useEffect(() => {
    if (!searchModalState.isOpen) {
      return;
    }

    refetchModalData({
      ...baseQueryVariables,
      search: debouncedSearch,
      limit: SEARCH_RESULT_LIMIT,
    });
  }, [
    baseQueryVariables,
    debouncedSearch,
    refetchModalData,
    searchModalState.isOpen,
  ]);

  return (
    <ErrorBoundary errorView={<ChartErrorView />}>
      <Panel>
        <header className="mb-4 flex flex-row items-center justify-between">
          <div className="flex flex-1 items-center">
            <MainHeading>
              {title || <Skeleton />}

              <HankGuideInfo
                title={title}
                link={HANK_UNI_URLS.heartbeat[queryVariables.threadType]}
                className="ml-2 hidden sm:inline-block"
              />
            </MainHeading>
          </div>

          <div className="flex items-center justify-end space-x-3">
            <button
              title={`Search ${title}`}
              className="h-8 rounded-full bg-gray-200 p-2 hover:bg-gray-300 focus:bg-gray-300 focus:outline-none sm:h-6 sm:p-1"
              onClick={searchModalState.open}
              onKeyDown={({ key }) => {
                if (key === 'Enter') {
                  searchModalState.open();
                }
              }}
            >
              <SearchIcon />
            </button>

            <ResponsiveDropdownMenu>
              <ResponsiveDropdownMenu.Button
                title="Sort"
                className="h-8 rounded-full bg-gray-200 p-2 hover:bg-gray-300 focus:bg-gray-300 focus:outline-none sm:h-6 sm:p-1"
              >
                <span className="sr-only">Sort</span>
                <SortDownIcon />
              </ResponsiveDropdownMenu.Button>

              <ResponsiveDropdownMenu.Menu>
                {items.map((item) => (
                  <ResponsiveDropdownMenu.StyledMenuItem
                    key={item.label}
                    label={item.label}
                    onClick={item.onclick}
                    isActive={item.id === sortBy}
                  />
                ))}
              </ResponsiveDropdownMenu.Menu>
            </ResponsiveDropdownMenu>
          </div>
        </header>

        {isEmpty(initialChartDataResults) && !isChartDataLoading ? (
          <NoResultsWarning />
        ) : null}

        {isEmpty(initialChartDataResults) && isChartDataLoading ? (
          <div className="relative">
            <Skeleton height="2rem" />
            {loadingMessage ? (
              <p className="absolute left-1/2 top-1/2 z-10 -translate-x-1/2 -translate-y-1/2 transform text-center font-medium tracking-wide text-gray-700">
                {loadingMessage}
              </p>
            ) : null}
          </div>
        ) : null}

        {!isEmpty(initialChartDataResults) ? (
          <SliderTray spacing="none" noEdgeGradients>
            {initialChartDataResults?.map(({ id, name, value }) => (
              <div
                key={`${id}${name}`}
                className={`flex-1 text-center text-sm`}
                style={{ minWidth: '126px' }}
              >
                <Link
                  href={ROUTE_PATHS.build.heartbeat({
                    threadType: queryVariables.threadType,
                    qp: handleStringifyParamsWithNewId(id || ''),
                  })}
                  className={`group focus:outline-none ${
                    readOnly ? 'cursor-auto' : ''
                  }`}
                  onClick={(e) => {
                    if (readOnly) {
                      e.preventDefault();
                      notifyOfReadOnlyState();
                    }
                  }}
                  onKeyUp={({ key }) => {
                    if (key === 'Enter' && readOnly) {
                      notifyOfReadOnlyState();
                    }
                  }}
                >
                  <div className="w-full truncate text-base font-bold">
                    {parseToCurrencyString(
                      value || 0,
                      unit?.symbol,
                      unit?.position
                    )}
                  </div>
                  <div
                    className={`mb-3 mt-2 h-7 w-full group-focus:ring-2 ${
                      ringColor || 'ring-orange-light'
                    } ${backgroundColor || 'bg-orange'}`}
                    style={{
                      opacity: `${caculateOpacity(
                        calculatePercentage(value || 0, maxValue)
                      )}`,
                    }}
                  />
                  <h3
                    className={`w-full truncate text-xs capitalize ${textColor}`}
                  >
                    {capitaliseString(name)}
                  </h3>
                </Link>
              </div>
            ))}
          </SliderTray>
        ) : null}

        {searchModalState.isOpen && pagination && (
          <VisualizationSearchModal
            title={title}
            defaultSortBy={sortBy}
            data={dataList}
            isLoading={isModalDataLoading}
            unit={unit}
            onInputChange={(value) => {
              setPage(1);
              setSearch(value);
            }}
            onSortbyChange={handleSortbyChange}
            readOnly={readOnly}
            modalState={searchModalState}
            onLinkClicked={(item) =>
              ROUTE_PATHS.build.heartbeat({
                threadType: queryVariables.threadType,
                qp: handleStringifyParamsWithNewId(item?.id || ''),
              })
            }
            withPagination={{
              showBoundaryPages: true,
              boundaryPagesAmount: 2,
              showFirstAndLastPage: true,
              lastPage: pagination.totalPages,
              currentPage: page,
              isLastPage: page === pagination.totalPages,
              onPrev: handlePreviousePage,
              onPrevWithPageNumber: handlePreviousePage,
              onNext: handleNextPage,
              onNextWithPageNumber: handleNextPage,
            }}
          />
        )}
      </Panel>
    </ErrorBoundary>
  );
};

export default HeartbeatTabChart;
