import { IDataValueId } from '@tapestry/shared/graphql';
import { IMetric } from '@tapestry/types';
import { Overlay, Pill } from '@tapestry/weave';
import { parseToCurrencyString } from '@tapestry/shared/utils';
import { Maybe } from 'graphql/jsutils/Maybe';
import React, { ReactElement, FC } from 'react';

import { default as almBackgroundImage } from './assets/background-images/alm-background-image.png';
import { default as begaBackgroundImage } from './assets/background-images/bega-background-image.png';
import { default as lactailsBackgroundImage } from './assets/background-images/lactalis-background-image.png';
import { default as metcashV2BackgroundImage } from './assets/background-images/metcash-background-image-v2.png';
import { default as metcashBackgroundImage } from './assets/background-images/metcash-background-image.png';
import { default as mondeBackgroundImage } from './assets/background-images/monde-nissin-background-image.png';
import { default as vennBackgroundImage } from './assets/background-images/venn-s-wholesale-background-image.png';
import { default as royalfoodsBackgroundImage } from './assets/background-images/royal-foods-background-image.png';
import { default as meatBackgroundImage } from './assets/background-images/meat-background-image.png';

import { default as almIgaLogo } from './assets/logos/alm-iga-logo.png';
import { default as almLogo } from './assets/logos/alm-logo.png';
import { default as begaLogo } from './assets/logos/bega-logo.png';
import { default as begaNswLogo } from './assets/logos/bega-nsw-logo.png';
import { default as metcashIgaLogo } from './assets/logos/metcash-iga-logo.png';
import { default as metcashLogo } from './assets/logos/metcash-logo.png';
import { default as royalfoodsLogo } from './assets/logos/royal-foods-logo.png';
import { default as lactalisLogo } from './assets/logos/lactalis-logo.png';
import { default as mondeNissinLogo } from './assets/logos/monde-nissin-logo.png';
import Link from 'next/link';
import Image from 'next/image';

const mapSupplierTitleToAssets = (name?: Maybe<string>) => {
  if (!name) {
    return {};
  }

  const assets = {
    davd: {
      background: metcashBackgroundImage,
      logo: metcashLogo,
    },
    metnsw: {
      background: metcashV2BackgroundImage,
      logo: metcashLogo,
    },
    metiga: {
      background: metcashV2BackgroundImage,
      logo: metcashIgaLogo,
    },
    almiga: {
      background: almBackgroundImage,
      logo: almIgaLogo,
    },
    alm: {
      background: almBackgroundImage,
      logo: almLogo,
    },
    almnsw: {
      background: almBackgroundImage,
      logo: almLogo,
    },
    venns_wholesale: {
      background: vennBackgroundImage,
    },
    'bega_bdd_aust_(was_lion_dairy)': {
      background: begaBackgroundImage,
      logo: begaLogo,
    },
    bega_bdd_aust_nsw: {
      background: begaBackgroundImage,
      logo: begaNswLogo,
    },
    monde_nissin_aust_aka_menora_foods: {
      background: mondeBackgroundImage,
      logo: mondeNissinLogo,
    },
    'lactalis_(was_parmalat)': {
      background: lactailsBackgroundImage,
      logo: lactalisLogo,
    },
    'lactalis_(nsw)': {
      background: lactailsBackgroundImage,
      logo: lactalisLogo,
    },
    royal_foods: {
      background: royalfoodsBackgroundImage,
      logo: royalfoodsLogo,
    },
    meat: {
      background: meatBackgroundImage,
    },
  };

  return assets[name.toLocaleLowerCase().replace(/ /g, '_')] || {};
};

const SupplierCard: FC<{
  supplier: IDataValueId;
  handleMakeURL: (id: string) => string;
  activeMeasureIcon: Maybe<IMetric['icon']>;
}> = ({ supplier, handleMakeURL, activeMeasureIcon }) => {
  const { id, name, value } = supplier;
  const Icon = React.cloneElement(activeMeasureIcon as ReactElement, {
    fillColor: '#ED7725',
  });
  const { background, logo } = mapSupplierTitleToAssets(name);

  return (
    <div
      className={`min-w-56 max-w-56 bg-blue relative block h-72 w-56 cursor-pointer rounded-lg bg-cover bg-center bg-no-repeat focus:outline-none focus:ring-2`}
    >
      {background ? (
        <>
          <Image
            src={background}
            alt={`${name} supplier`}
            quality={100}
            fill
            className="rounded-lg object-cover"
          />
          <Overlay rounded="rounded-lg" />
        </>
      ) : (
        <div
          className="absolute inset-0 rounded-lg"
          style={{
            backgroundImage: `linear-gradient(rgba(14, 115, 187), rgba(0, 0, 0, .85))`,
          }}
        />
      )}

      <Link href={handleMakeURL(id || '')} className="relative h-full w-full">
        <div className="flex h-full flex-col justify-end p-4 text-left">
          <div className="mb-10 text-white">
            <h3
              className={`truncate-2-lines text-base font-bold leading-6 sm:text-lg`}
            >
              {name}
            </h3>
          </div>

          <div className="flex flex-row items-end justify-between">
            {logo ? (
              <Image
                src={logo}
                alt="shop's logo"
                className="min-w-10 h-10 w-10 rounded-full bg-white object-contain object-center shadow-md"
                width={40}
                height={40}
              />
            ) : (
              <span className="min-w-10 bg-blue flex h-10 w-10 items-center justify-center rounded-full text-center text-base font-bold uppercase text-white shadow-md">
                <abbr>{name ? name[0] : null}</abbr>
              </span>
            )}
            <Pill
              size="small"
              label={value ? parseToCurrencyString(value) : ''}
              bgColor={'#fff'}
              textColor="text-black"
              iconLeft={Icon}
            />
          </div>
        </div>
      </Link>
    </div>
  );
};

export { SupplierCard };
