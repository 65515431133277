import { ThreadTypeToThemeColorMapper } from '@tapestry/shared/client';
import { TrashIcon } from '@tapestry/shared/icons';
import { THREAD_TYPE } from '@tapestry/types';
import { Pill } from '@tapestry/weave';
import { mapThreadTypeToThreadIcon } from '@tapestry/shared/utils';
import * as React from 'react';
import { twMerge } from 'tailwind-merge';

interface IAlertListItemProps {
  threadType: THREAD_TYPE;
  title: string;
  frequency: string;
  boundaries: {
    min: number;
    max: number;
  };
}

const AlertListItem: React.VFC<IAlertListItemProps> = ({
  threadType,
  title,
  frequency,
  boundaries: { min, max },
}) => {
  const IconComponent = mapThreadTypeToThreadIcon(threadType);
  const { backgroundColor } = ThreadTypeToThemeColorMapper[threadType] || {};

  return (
    <div className="border-gray-border flex w-full flex-col gap-2 rounded-md border px-4 py-2 md:flex-row md:items-center md:justify-between md:gap-4">
      <div
        className={twMerge(
          backgroundColor,
          'hidden h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-white p-3 sm:hidden md:flex'
        )}
      >
        <IconComponent fillColor="#FFFFFF" className="h-full w-auto" />
      </div>

      <div className="flex flex-1 items-center justify-between">
        <div className="flex flex-col items-start">
          <span className="font-bold capitalize">{title}</span>
          <span className="text-gray-text text-sm capitalize">
            {threadType}
          </span>
        </div>

        <button type="button" className="h-4 w-4 flex-shrink-0 md:hidden">
          <TrashIcon fillColor="red" />
        </button>
      </div>

      <span className="flex-shrink-0 text-left text-sm">
        Alert outside of {min}% - {max}%
      </span>

      <div className="flex items-center justify-between">
        <Pill
          label={frequency}
          size="small"
          className="flex-shrink-0 md:block"
        />

        <div
          className={twMerge(
            backgroundColor,
            'flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-white p-3 md:hidden'
          )}
        >
          <IconComponent fillColor="#FFFFFF" className="h-full w-auto" />
        </div>
      </div>

      <button type="button" className="hidden h-4 w-4 flex-shrink-0 md:block">
        <TrashIcon fillColor="red" />
      </button>
    </div>
  );
};

export { AlertListItem };
