import { ManufacturerIcon } from '@tapestry/shared/icons';
import { parseToCurrencyString } from '@tapestry/shared/utils';
import { IMetric } from '@tapestry/types';
import { Pill, Card } from '@tapestry/weave';
import { FC, ReactElement } from 'react';
import Skeleton from 'react-loading-skeleton';

type CollectionCardProps =
  | {
      isLoading: true;
      name?: never;
      count?: never;
      value?: never;
      unit?: never;
      icon?: never;
    }
  | {
      isLoading?: never;
      name: string;
      count: number;
      value: number;
      unit: IMetric['unit'];
      icon?: ReactElement;
    };

const CollectionCard: FC<CollectionCardProps> = ({
  isLoading,
  name,
  count,
  value = 0,
  unit,
  icon: Icon,
}) => {
  const pillLabel = parseToCurrencyString(value, unit?.symbol, unit?.position);

  return (
    <Card
      justify="center"
      bgColor="bg-purple"
      backgroundGradient="linear-gradient(rgba(90, 59, 143,1), rgba(0, 0, 0, .85))"
      className="h-full text-white min-w-56 max-w-56 "
    >
      <div className="w-full h-full flex flex-col items-between justify-between">
        <div className="w-full text-left mb-10 text-white">
          <h3 className="truncate text-base font-bold leading-6 sm:text-lg">
            {isLoading ? <Skeleton width={120} /> : name}
          </h3>

          <p className="text-base">
            {isLoading ? <Skeleton width={20} /> : `${count} products`}
          </p>
        </div>

        <div className="flex flex-row items-end justify-between">
          <div className="min-w-10 bg-purple flex h-10 w-10 items-center justify-center rounded-full p-2 shadow-md">
            <ManufacturerIcon fillColor="#fff" />
          </div>

          {isLoading ? (
            <Skeleton width={80} className="rounded-full" />
          ) : (
            <Pill
              size="small"
              label={pillLabel}
              bgColor="bg-white"
              textColor="text-black"
              iconLeft={Icon}
            />
          )}
        </div>
      </div>
      {/* </div> */}
    </Card>
  );
};

export { CollectionCard };
