import * as React from 'react';
import { UseFormRegister, FieldErrors } from 'react-hook-form';
import { SupplierInsightInvitationForm } from '../permission-wizard-types';

interface IMessageScreenProps {
  register: UseFormRegister<SupplierInsightInvitationForm>;
  errors: FieldErrors;
}

const MessageScreen: React.FC<IMessageScreenProps> = ({ register }) => (
  <div className="pb-6 text-left">
    <label htmlFor="message" className="block pb-1 font-bold">
      Add a message
    </label>
    <textarea
      {...register('message')}
      rows={4}
      className="border-gray-lightest placeholder-gray-normal w-full rounded-md border-2 py-3 px-3 text-left text-base transition-colors
      duration-150"
    />
  </div>
);

export { MessageScreen };
