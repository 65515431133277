import React from 'react';
import { FrameContent, Stack, useAppMediaQuery } from '@tapestry/weave';
import Skeleton from 'react-loading-skeleton';
import { HeartbeatAreaChartLoadingState } from '../HeartbeatAreaChart/HeartbeatAreaChartLoadingState';

/**
 * A splash screen used for lazy loading heartbeat
 */
export const HeartbeatLoadingSplashScreen = () => {
  const { isPhone } = useAppMediaQuery();

  return (
    <div>
      <header className="border-b bg-white py-4">
        <FrameContent
          maxWidth="xl"
          noVerticalSpacing
          className="relative flex items-center justify-between"
        >
          <Skeleton circle height={'2rem'} width={'2rem'} />

          <Skeleton
            className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-full"
            height="2rem"
            width={250}
          />

          <Skeleton circle height={'2rem'} width={'2rem'} />
        </FrameContent>
      </header>

      <main className="bg-gray-pageBackground">
        <FrameContent maxWidth="xl">
          <Stack>
            <header className="flex items-center">
              <Skeleton circle height={60} width={60} />

              <div className="ml-4 flex flex-col items-start">
                <Skeleton className="block" height="1.25rem" width={50} />
                <Skeleton className="block" height="1.25rem" width={200} />
              </div>
            </header>

            <nav className="flex items-center space-x-2 overflow-x-hidden">
              {[1, 2, 3, 4, 5, 6, 7, 8].map((each) => (
                <Skeleton
                  key={each}
                  height={150}
                  width={150}
                  className="rounded-lg"
                />
              ))}
            </nav>

            <div className="sm:flex sm:items-center sm:justify-between">
              <Skeleton
                height="2rem"
                width={isPhone ? '100%' : 340}
                className="rounded-full"
              />
              <Skeleton height="2.25rem" width={isPhone ? '100%' : 150} />
            </div>

            <HeartbeatAreaChartLoadingState loadingMessage={null} />

            <Skeleton height="300px" className="rounded-lg" />
          </Stack>
        </FrameContent>
      </main>
    </div>
  );
};

export default HeartbeatLoadingSplashScreen;
