import React, { FC } from 'react';
import { parseToCurrencyString } from '@tapestry/shared/utils';
import { API_DATETIME_DAY_FORMAT } from '../../utils';
import {
  IMetric,
  IParsedDataValue,
  IParsedHeartbeatMainChartDataValueList,
  Nullable,
} from '@tapestry/types';
import isEmpty from 'lodash/isEmpty';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { Maybe } from 'graphql/jsutils/Maybe';
import { useFlags } from 'launchdarkly-react-client-sdk';
dayjs.extend(customParseFormat);

const getDiffAmount = (data: IParsedDataValue) => {
  if (!data || !data.value || !data.comparison) return null;

  return data.value - data.comparison;
};

interface ICustomTooltipProps {
  active?: boolean;
  payload?: { payload: IParsedHeartbeatMainChartDataValueList };
  todaysPayload?: Nullable<IParsedHeartbeatMainChartDataValueList>;
  label?: string;
  title?: string;
  unit: IMetric['unit'];
  comparisonUnit: IMetric['unit'] | null;
  comparisonTooltipLabel: Maybe<string>;
  viewScope: {
    isHourly: boolean;
    isIntraDay: boolean;
  };
}

export const CustomTooltip: FC<ICustomTooltipProps> = ({
  title = '',
  payload = [],
  label = 'N/A',
  unit,
  comparisonUnit,
  todaysPayload,
  comparisonTooltipLabel,
  viewScope,
}) => {
  const payloadDate = todaysPayload?.[0]?.name || label;
  const dataDateTime = dayjs(payloadDate, API_DATETIME_DAY_FORMAT);

  const data = isEmpty(payload) ? todaysPayload?.[0] : payload?.[0]?.payload;

  if (!data) return null;

  return (
    <div className="flex flex-col gap-2 rounded-lg bg-black pb-4 pt-3 px-4 text-base leading-5 text-white">
      <MainDateInformation label={label} dataDateTime={dataDateTime} />

      <MainDataPoint value={data.value} title={title} unit={unit} />

      <ComparisonData
        data={data}
        comparisonTooltipLabel={comparisonTooltipLabel}
        unit={comparisonUnit || unit}
      />

      <DrilldownNote viewScope={viewScope} />
    </div>
  );
};

const MainDateInformation: FC<{
  label: ICustomTooltipProps['label'];
  dataDateTime: dayjs.Dayjs;
}> = ({ label, dataDateTime }) => {
  const weekdayName = dataDateTime.format('dddd');
  const dateTimeDisplay = dataDateTime.format('DD-MM-YYYY');
  const isToday = dayjs(dataDateTime).isSame(dayjs(), 'day');

  return (
    <header className="text-sm leading-4">
      <h4>{weekdayName}</h4>
      <h5>
        <time dateTime={label}>{isToday ? 'Today' : dateTimeDisplay}</time>
      </h5>
    </header>
  );
};

const MainDataPoint: FC<{
  value: IParsedDataValue['value'];
  unit: ICustomTooltipProps['unit'];
  title: ICustomTooltipProps['title'];
}> = ({ value, unit, title }) => {
  if (!value) return null;

  return (
    <div className="font-bold  text-orange">
      <data value={String(value)}>
        {parseToCurrencyString(
          value || 0,
          unit?.symbol || '',
          unit?.position || 'left'
        )}
      </data>

      <p>{title !== null ? title : null}</p>
    </div>
  );
};

const ComparisonData: FC<{
  data: IParsedDataValue;
  comparisonTooltipLabel: ICustomTooltipProps['comparisonTooltipLabel'];
  unit: ICustomTooltipProps['unit'];
}> = ({ data, comparisonTooltipLabel, unit }) => {
  const diffAmount = getDiffAmount(data);
  const diffColor = diffAmount && diffAmount < 0 ? 'text-red' : 'text-white';

  const comparisonNumber = parseToCurrencyString(
    data.comparison || 0,
    unit?.symbol || '',
    unit?.position || 'left'
  );

  if (!data.comparison) return null;

  return (
    <div>
      <p className="lowercase">{comparisonTooltipLabel}</p>

      <data className="block" value={String(data.comparison)}>
        {comparisonNumber}
      </data>

      {diffAmount ? (
        <p className={`text-details ${diffColor}`}>
          {diffAmount < 0 ? '-' : '+'}
          <data>
            {parseToCurrencyString(
              Math.abs(diffAmount) || 0,
              unit?.symbol || '',
              unit?.position || 'left'
            )}
          </data>
        </p>
      ) : null}
    </div>
  );
};

const DrilldownNote: FC<{ viewScope: ICustomTooltipProps['viewScope'] }> = ({
  viewScope,
}) => {
  const { isHourly, isIntraDay } = viewScope;
  const { mainChartFiveMinutesDrilldown: hasFiveMinsDrillDownPermission } =
    useFlags();

  if (isHourly || (isIntraDay && !hasFiveMinsDrillDownPermission)) return null;

  return (
    <footer className="text-blue text-details leading-4">
      Double-click to see
      <br />
      {isIntraDay ? 'hour' : 'day'} breakdown
    </footer>
  );
};
