import {
  IDataValuePlaceholder,
  IMetric,
  IParsedHeartbeatMainChartDataValueList,
  HeartbeatQueryParams,
  IParsedDataValue,
} from '@tapestry/types';
import { dateTime, SetQuery } from '@tapestry/shared/utils';
import isEmpty from 'lodash/isEmpty';
import { IDataValue } from '@tapestry/shared/graphql';
import { Maybe } from 'graphql/jsutils/Maybe';
import { ApolloError } from '@apollo/client';
import { IAppMediaQueries } from '@tapestry/weave';

export interface IHeartbeatAreaChartProps {
  isLoadingChartData: boolean;
  chartData: IParsedHeartbeatMainChartDataValueList;
  chartDataError: ApolloError | undefined;
  label?: string;
  unit: IMetric['unit'];
  comparisonUnit: IMetric['unit'] | null;
  isIntraDay: boolean;
  isHourly: boolean;
  setUrlQueryState: SetQuery<HeartbeatQueryParams>;
  comparisonTooltipLabel: Maybe<string>;
  currentShopTimezone: string;
  refetchChart: () => void;
  chartCaption: string;
}

export const getWidthByMaxNumber = (entries: number[]) => {
  const biggestNum = entries.reduce((total, number) => {
    return number > total ? number : total;
  }, 0);

  const numLength = biggestNum.toFixed().length;
  const width = numLength < 3 ? 26 : 30;

  return width;
};

export const MONTH_LENGTH = 30; // even number makes further calculation easier
const MAX_TICKS_ON_PHONE = 15;
const MAX_TICKS_ON_TABLET = 30;

export const calculateInterval = (
  deviceSizes: IAppMediaQueries,
  dataLength: number
): number => {
  const { isPhone } = deviceSizes;

  let interval = 0;

  if (isPhone) {
    interval = Math.round(dataLength / MAX_TICKS_ON_PHONE);
  } else if (dataLength > MONTH_LENGTH + 1) {
    interval = Math.round(dataLength / MAX_TICKS_ON_TABLET);
  }

  return interval;
};

/**
 * get the ticks that are actually showned on the map depending on the interval
 *
 */
export const getActualRepresentedDataTicks = (
  data: (IDataValue | IDataValuePlaceholder)[],
  interval: number
) => {
  return data.filter((_, idx) => {
    const divider = interval + 1;
    const remainder = idx % divider;

    return remainder === 0;
  });
};

export const checkifItIsFirstMonthlyTick = (
  chartedData: (IDataValue | IDataValuePlaceholder)[],
  tick
) => {
  if (isEmpty(chartedData)) return false;

  const tickIndex = chartedData.findIndex(
    (datapoint) => datapoint.name === tick.value
  );
  const previousDatapoint = chartedData[tickIndex - 1];

  if (previousDatapoint && previousDatapoint?.name) {
    const previousDatapointMonth = dateTime.getCurrentMonth(
      previousDatapoint?.name,
      'MM'
    );
    const tickMonth = dateTime.getCurrentMonth(tick.name, 'MM');

    return previousDatapointMonth !== tickMonth;
  }

  return false;
};

export const getTodayPayload = (
  today: string,
  data: IHeartbeatAreaChartProps['chartData']
) => {
  return data.filter((day) => day.name === today);
};

type accObj = {
  value: number;
  comparison: number;
};

const findHighestNumPerDataKey = (
  accumObj: accObj,
  currentPoint: IParsedDataValue
) => {
  accumObj.value = Math.max(accumObj.value, currentPoint?.value || 0);
  accumObj.comparison = Math.max(
    accumObj.comparison,
    currentPoint?.comparison || 0
  );

  return accumObj;
};

/**
 * Finds the highest value between the two datakey and return the key of the highest.
 */
export const pickHighestDataKey = (
  chartData: IHeartbeatAreaChartProps['chartData']
): 'value' | 'comparison' => {
  const dataKeyAccumObject: accObj = {
    value: 0,
    comparison: 0,
  };

  chartData.reduce(findHighestNumPerDataKey, dataKeyAccumObject);

  const { comparison, value } = dataKeyAccumObject;

  return comparison > value ? 'comparison' : 'value';
};
