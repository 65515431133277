import { IMetric, MeasureSlug, THREAD_TYPE } from '@tapestry/types';
import { ParsedUrlQuery } from 'querystring';

export interface IMetricTile {
  metric: IMetric;
  metricTitle: string;
  metricSubtitle?: string;
  isActiveCategory: boolean;
  shopIsActiveThread?: boolean;
  isLoading?: boolean;
  urlQuery: ParsedUrlQuery;
}

/**
 * Utils
 */
export const getMetricTitle = (
  metric: IMetric,
  activeThread: THREAD_TYPE,
  activeMeasure: MeasureSlug
): string => {
  if (
    metric.altTitleCondition &&
    metric.altTitleCondition(activeThread, activeMeasure)
  ) {
    return metric.altTitle || metric.title;
  }

  return metric.title;
};

export const getTileStyle = (
  isDisable: boolean,
  isActiveCategory: IMetricTile['isActiveCategory'],
  shopIsActiveThread: IMetricTile['shopIsActiveThread'],
  themeTextColor?: string
) => {
  let tileColorStyle = '';

  if (isDisable) {
    tileColorStyle = `${
      shopIsActiveThread ? 'opacity-50' : ''
    } cursor-not-allowed text-gray-text bg-gray-200`;
  } else {
    tileColorStyle = isActiveCategory
      ? `cursor-pointer bg-white ${themeTextColor || 'text-black'}`
      : `cursor-pointer bg-white text-gray-text`;
  }

  return tileColorStyle;
};

export const getTileIconColorStyle = (
  isDisable: boolean,
  isActiveCategory: IMetricTile['isActiveCategory'],
  themeIconColor?: string
) => {
  if (isDisable) return '#3c3c3c';

  const themeColor = themeIconColor ?? '#000';

  return isActiveCategory ? themeColor : '#666';
};

export const getStylesFromState = (
  isDisable: boolean,
  isActiveCategory: IMetricTile['isActiveCategory'],
  shopIsActiveThread: IMetricTile['shopIsActiveThread'],
  themeTextColor?: string,
  themeIconColor?: string
) => {
  const tileColorStyle = getTileStyle(
    isDisable,
    isActiveCategory,
    shopIsActiveThread,
    themeTextColor
  );

  const tileIconColorStyle = getTileIconColorStyle(
    isDisable,
    isActiveCategory,
    themeIconColor
  );

  return { tileIconColorStyle, tileColorStyle };
};
