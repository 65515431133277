import React, { ReactElement, ReactNode, FC } from 'react';
import Link from 'next/link';
import {
  ChartLineIcon,
  InfoCircleIcon,
  SearchIcon,
  SortDownIcon,
} from '@tapestry/shared/icons';
import {
  MainHeading,
  Panel,
  ResponsiveDropdownMenu,
  SliderTray,
} from '@tapestry/weave';
import { VisualizationSearchModal } from '@tapestry/shared/visualisation';
import { HANK_UNI_URLS, ROUTE_PATHS } from '@tapestry/shared/constants';
import { safeJSONParse } from '@tapestry/shared/utils';
import { IMetric, THREAD_TYPE, Visualization } from '@tapestry/types';
import {
  IDataValueTagCollection,
  useGetHeartbeatTagCollection,
} from '@tapestry/shared/graphql';
import { HeartbeatVisualizationQueriesBaseVariables } from '@tapestry/types';
import { Maybe } from 'graphql/jsutils/Maybe';
import { useLongLoading, useModal, useSortMenu } from '@tapestry/shared/hooks';
import { captureException } from '@sentry/nextjs';
import { CollectionsVisualizationLoading } from './CollectionsVisualizationLoading';
import { CollectionsVisualizationNoResult } from './CollectionsVisualizationNoResult';
import { CollectionCard } from './CollectionCard';
import { twMerge } from 'tailwind-merge';

export interface IHeartbeatFeaturesCollectionsProps {
  queryVariables: HeartbeatVisualizationQueriesBaseVariables;
  stringifyQueryParams: (collectionId: string) => string;
  unit: IMetric['unit'];
  activeMetricIcon: ReactNode | null;
  activeMetricTitle: string | undefined;
  readOnly?: boolean;
}

const CollectionsVisualization: FC<IHeartbeatFeaturesCollectionsProps> = ({
  stringifyQueryParams,
  queryVariables,
  unit,
  activeMetricIcon = <ChartLineIcon />,
  activeMetricTitle,
  readOnly,
}) => {
  const visualisationTitle = `Top Manufacturers ${activeMetricTitle}`;
  const {
    items,
    state: { sortBy },
  } = useSortMenu();
  const queryFilters = safeJSONParse(queryVariables.filters);

  const isNotActiveCollection = (collection: IDataValueTagCollection) => {
    if (queryVariables.scopeType !== THREAD_TYPE.COLLECTION) return true;

    return collection.name !== queryVariables.scopeId;
  };

  const { data, loading, refetch } = useGetHeartbeatTagCollection({
    notifyOnNetworkStatusChange: true,
    variables: {
      ...queryVariables,
      search: '',
      sortBy,
      limit: 20,
    },
    skip: !(queryFilters.shopId || queryFilters.groupId),
    onCompleted: (data) => {
      const collections = data?.heartbeatChartTagCollectionV2?.data || [];
      const activeCollections = collections.filter(isNotActiveCollection);

      if (!activeCollections.length) {
        captureException('No data found for this query', {
          level: 'info',
          tags: { visualisation: Visualization.TopCollections },
        });
      }
    },
  });

  const searchModalState = useModal({
    onOpenChange: (isOpen) => {
      if (isOpen) return;
      refetch({ search: '' });
    },
  });

  const { loadingMessage } = useLongLoading({
    mode: 'interval',
    isLoading: loading,
  });
  const collections = data?.heartbeatChartTagCollectionV2?.data || [];
  const activeCollections = collections.filter(isNotActiveCollection);
  const noResults = !loading && activeCollections.length === 0;

  const Icon = React.cloneElement(activeMetricIcon as ReactElement, {
    className: 'h-4 w-auto text-primary',
    fillColor: 'currentColor',
  });

  const handleStringifyParamsWithNewId = (id: Maybe<string>) => {
    if (!id) return;

    return stringifyQueryParams(id);
  };

  const handleSortbyChange = (sortBy: string) => {
    refetch({ sortBy });
  };

  const notifyOfReadOnlyState = () => {
    alert('Coming Soon...');
  };

  return (
    <Panel>
      <header className="mb-6 flex items-center justify-between">
        <div className="flex items-center">
          <MainHeading>{visualisationTitle}</MainHeading>
          <a
            href={HANK_UNI_URLS.heartbeat.collections}
            target="_blank"
            rel="noopener noreferrer"
            title="Learn more about Lists"
            className="ml-2 hidden sm:inline-block"
          >
            <span className="sr-only">Learn more about lists</span>
            <InfoCircleIcon fillColor="#666666" className="h-4 w-auto" />
          </a>
        </div>
        <div className="flex items-center justify-end space-x-3 ml-4">
          <button
            title="Search lists"
            className="h-8 rounded-full bg-gray-200 p-2 hover:bg-gray-300 focus:bg-gray-300 focus:outline-none sm:h-6 sm:p-1"
            onClick={searchModalState.open}
            onKeyDown={({ key }) => {
              if (key === 'Enter') {
                searchModalState.open();
              }
            }}
          >
            <span className="sr-only">Search collection</span>
            <SearchIcon />
          </button>

          <ResponsiveDropdownMenu>
            <ResponsiveDropdownMenu.Button
              title="Sort Manufacturers"
              className="h-8 rounded-full bg-gray-200 p-2 hover:bg-gray-300 focus:bg-gray-300 focus:outline-none sm:h-6 sm:p-1"
            >
              <span className="sr-only">Sort Manufacturers</span>
              <SortDownIcon />
            </ResponsiveDropdownMenu.Button>

            <ResponsiveDropdownMenu.Menu>
              {items.map((item) => (
                <ResponsiveDropdownMenu.StyledMenuItem
                  key={item.label}
                  label={item.label}
                  onClick={item.onclick}
                  isActive={item.id === sortBy}
                />
              ))}
            </ResponsiveDropdownMenu.Menu>
          </ResponsiveDropdownMenu>
        </div>
      </header>

      {noResults && <CollectionsVisualizationNoResult />}

      <div className="relative">
        {loadingMessage ? (
          <p className="absolute top-1/2 left-1/2 z-10 -translate-x-1/2 -translate-y-1/2 transform text-center font-medium tracking-wide text-gray-700">
            {loadingMessage}
          </p>
        ) : null}

        {!noResults && loading && <CollectionsVisualizationLoading />}

        {!noResults && !loading && (
          <SliderTray spacing="large" noEdgeGradients>
            {collections.map(({ name, value, count }) => {
              if (!name) return null;

              return (
                <Link
                  href={ROUTE_PATHS.build.heartbeat({
                    threadType: 'collection',
                    qp: handleStringifyParamsWithNewId(name),
                  })}
                  className={twMerge(
                    'relative h-full w-full',
                    readOnly && 'cursor-auto'
                  )}
                  onClick={(e) => {
                    if (readOnly) {
                      e.preventDefault();
                      notifyOfReadOnlyState();
                    }
                  }}
                  onKeyDown={({ key }) => {
                    if (key === 'Enter' && readOnly) {
                      notifyOfReadOnlyState();
                    }
                  }}
                >
                  <CollectionCard
                    key={name}
                    name={name}
                    value={value ?? 0}
                    count={count ?? 0}
                    unit={unit}
                    icon={Icon}
                  />
                </Link>
              );
            })}
          </SliderTray>
        )}
      </div>

      {searchModalState.isOpen && (
        <VisualizationSearchModal
          title={visualisationTitle}
          defaultSortBy={sortBy}
          data={collections}
          isLoading={loading}
          unit={unit}
          onInputChange={(value) => refetch({ search: value })}
          onSortbyChange={handleSortbyChange}
          modalState={searchModalState}
          readOnly={readOnly}
          onLinkClicked={(item) =>
            ROUTE_PATHS.build.heartbeat({
              threadType: 'collection',
              qp: handleStringifyParamsWithNewId(item?.name || ''),
            })
          }
        />
      )}
    </Panel>
  );
};

export { CollectionsVisualization };
export default CollectionsVisualization;
