import { SliderTray } from '@tapestry/weave';
import { CollectionCard } from './CollectionCard';

const CollectionsVisualizationLoading = () => {
  return (
    <SliderTray spacing="large" noEdgeGradients>
      {Array(4)
        .fill(null)
        .map((_, index) => (
          <CollectionCard key={index} isLoading />
        ))}
    </SliderTray>
  );
};

export { CollectionsVisualizationLoading };
