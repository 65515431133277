import React, { useEffect, useState, FC } from 'react';
import Skeleton from 'react-loading-skeleton';
import {
  InfoCircleIcon,
  SearchIcon,
  SortDownIcon,
} from '@tapestry/shared/icons';
import {
  Button,
  Card,
  MainHeading,
  Panel,
  ResponsiveDropdownMenu,
  SliderTray,
} from '@tapestry/weave';
import { ROUTE_PATHS } from '@tapestry/shared/constants';
import { useLongLoading, useModal, useSortMenu } from '@tapestry/shared/hooks';
import { useToast, ErrorBoundary } from '@tapestry/shared/client';
import {
  GetHeartbeatTopSuppliersDocument,
  IGetHeartbeatTopSuppliers,
  ITopSupplierSortByEnum,
  useGetHeartbeatTopSuppliersLazyQuery,
} from '@tapestry/shared/graphql';
import { Maybe } from 'graphql/jsutils/Maybe';
import { VisualizationSearchModal } from '../VisualizationSearchModal';
import {
  HeartbeatVisualizationQueriesBaseVariables,
  IMetric,
  THREAD_TYPE,
} from '@tapestry/types';
import {
  ChartErrorView,
  OnboardingCarousel,
} from '@tapestry/shared/components';
import { useApolloClient } from '@apollo/client';
import isEmpty from 'lodash/isEmpty';
import { SORT_ITEMS, sortReducer } from './supplier-viz-sort-utils';
import { SupplierCard } from './SupplierCard';
import { TOP_SUPPLIERS_SLIDES } from './top-supplier-slides';
import { safeJSONParse } from '@tapestry/shared/utils';

interface ISupplierWidgetProps {
  queryVariables: HeartbeatVisualizationQueriesBaseVariables;
  stringifyParams: (param: Record<string, string>) => string;
  activeMeasureIcon: Maybe<IMetric['icon']>;
  activeMetricTitle: string | undefined;
}

export const SupplierWidget: FC<ISupplierWidgetProps> = ({
  queryVariables,
  stringifyParams,
  activeMeasureIcon,
  activeMetricTitle,
}) => {
  const ENTRY_PER_PAGE = 15;
  const visualisationTitle = `Top Supplier ${activeMetricTitle}`;
  const { open, close, isOpen } = useModal();
  const [searchModalpage, setSearchModalPage] = useState(1);
  const searchModalState = useModal({
    onOpenChange: (isOpen) => {
      if (isOpen) return;
      setSearchModalPage(1);
    },
  });

  const {
    items,
    state: { sortBy },
  } = useSortMenu({
    items: SORT_ITEMS,
    sortReducer,
  });

  const { addToast } = useToast();

  const defaultQueryVariables = React.useMemo(
    () => ({
      ...queryVariables,
      sortBy: sortBy as ITopSupplierSortByEnum,
      limit: ENTRY_PER_PAGE,
    }),
    [queryVariables, sortBy]
  );

  // * reading initial query results straight from the cache so that when the modal is open and new queries are executed, the visualisation in the background does not update (looks bad)
  const client = useApolloClient();
  const query = client.readQuery<IGetHeartbeatTopSuppliers>({
    query: GetHeartbeatTopSuppliersDocument,
    variables: defaultQueryVariables,
  });

  const [
    getTopSuppliers,
    { data: suppliersData, loading: isLoadingSuppliers, refetch },
  ] = useGetHeartbeatTopSuppliersLazyQuery({
    variables: defaultQueryVariables,
    onError: () => {
      addToast({
        type: 'error',
        content: 'An error has occured whislt getting your top suppliers',
      });
    },
  });
  const { loadingMessage } = useLongLoading({
    mode: 'interval',
    isLoading: isLoadingSuppliers,
  });

  const initialQueryResults = query?.heartbeatChartTopSuppliersV2?.data;
  const suppliers = React.useMemo(
    () => suppliersData?.heartbeatChartTopSuppliersV2?.data || [],
    [suppliersData?.heartbeatChartTopSuppliersV2?.data]
  );

  const paginatedSuppliers = React.useMemo(
    () =>
      suppliers.reduce((paginatedArray, supplier, index) => {
        const page = Math.floor(index / ENTRY_PER_PAGE);

        // offsetting page by 1 to reflect the fact that the first page is page 1
        return {
          ...paginatedArray,
          [page + 1]: [...(paginatedArray[page + 1] || []), supplier],
        };
      }, {}),
    [suppliers]
  );

  const handleMakeURL = (supplierId: Maybe<string>) => {
    if (!supplierId) {
      return '';
    }

    return ROUTE_PATHS.build.heartbeat({
      threadType: THREAD_TYPE.SUPPLIER,
      qp: stringifyParams({
        supplierId,
      }),
    });
  };

  // Note: no debouncing is required here, already handled by the search modal.
  const handleOnInputChange = (value) => {
    /*
      TODO: currently backend is not supporting search without pagination to list all result.
      `limit` could potentially be remove once that happen.
    */
    getTopSuppliers({
      variables: {
        ...queryVariables,
        search: value,
        sortBy: sortBy as ITopSupplierSortByEnum,
        limit: 1000,
      },
    });

    // every time serach term chages, reset pagination
    setSearchModalPage(1);
  };

  const handleSortbyChange = (sortBy: string) => {
    refetch({ sortBy: sortBy as ITopSupplierSortByEnum });
  };

  useEffect(() => {
    const queryFilters = safeJSONParse(defaultQueryVariables.filters);
    if (!queryFilters.shopId && !queryFilters.groupId) return;

    getTopSuppliers({
      variables: {
        ...defaultQueryVariables,
        sortBy: sortBy as ITopSupplierSortByEnum,
      },
    });
  }, [defaultQueryVariables, getTopSuppliers, sortBy]);

  if (
    !isLoadingSuppliers &&
    initialQueryResults?.length === 0 &&
    suppliers.length === 0
  ) {
    return null;
  }

  return (
    <ErrorBoundary errorView={<ChartErrorView />}>
      <Panel>
        <header className="mb-6 flex items-center justify-between">
          <div className="flex items-center">
            <MainHeading>{visualisationTitle}</MainHeading>
          </div>
          <div className="flex items-center justify-end space-x-3">
            <button
              title="Search suppliers"
              type="button"
              className="h-8 rounded-full bg-gray-200 p-2 hover:bg-gray-300 focus:bg-gray-300 focus:outline-none sm:h-6 sm:p-1"
              onClick={searchModalState.open}
              onKeyDown={({ key }) => {
                if (key === 'Enter') {
                  searchModalState.open();
                }
              }}
            >
              <span className="sr-only">Search suppliers</span>
              <SearchIcon />
            </button>

            <ResponsiveDropdownMenu>
              <ResponsiveDropdownMenu.Button
                title="Sort Suppliers"
                className="h-8 rounded-full bg-gray-200 p-2 hover:bg-gray-300 focus:bg-gray-300 focus:outline-none sm:h-6 sm:p-1"
              >
                <span className="sr-only">Sort Suppliers</span>
                <SortDownIcon />
              </ResponsiveDropdownMenu.Button>

              <ResponsiveDropdownMenu.Menu>
                {items.map((item) => (
                  <ResponsiveDropdownMenu.StyledMenuItem
                    key={item.label}
                    label={item.label}
                    onClick={item.onclick}
                    isActive={item.id === sortBy}
                  />
                ))}
              </ResponsiveDropdownMenu.Menu>
            </ResponsiveDropdownMenu>
          </div>
        </header>

        <SliderTray spacing="large" noArrows={isEmpty(initialQueryResults)}>
          {isEmpty(initialQueryResults) ? (
            <>
              <Skeleton height={320} width={224} />
              <Skeleton height={320} width={224} />
              <Skeleton height={320} width={224} />
              <Skeleton height={320} width={224} />
              {loadingMessage ? (
                <p className="absolute top-1/2 left-1/2 z-10 -translate-x-1/2 -translate-y-1/2 transform text-center font-medium tracking-wide text-gray-700">
                  {loadingMessage}
                </p>
              ) : null}
            </>
          ) : (
            initialQueryResults?.map((supplier) => (
              <SupplierCard
                key={supplier.id}
                supplier={supplier}
                handleMakeURL={handleMakeURL}
                activeMeasureIcon={activeMeasureIcon}
              />
            ))
          )}
        </SliderTray>

        <div className="mt-6">
          <Card bgColor="bg-gray-100" spacing="small">
            <div className="md:flex w-full md:items-center md:justify-between">
              <div className="flex flex-row  items-start sm:items-center">
                <InfoCircleIcon className="h-6 sm:h-8 md:h-10 w-auto flex-shrink-0" />
                <p className="text-gray-text ml-4">
                  Did you know you can share real-time insights with your
                  suppliers?
                </p>
              </div>

              <div className="ml-8 mt-4 sm:ml-12 sm:mt-2 md:m-0">
                <Button
                  status="primary"
                  rounded="rounded-full"
                  spacing="small"
                  onClick={open}
                >
                  Learn more
                </Button>
              </div>
            </div>
          </Card>
        </div>

        {searchModalState.isOpen && (
          <VisualizationSearchModal
            title={visualisationTitle}
            data={paginatedSuppliers?.[searchModalpage] || []}
            defaultSortBy={sortBy}
            isLoading={isLoadingSuppliers}
            onInputChange={handleOnInputChange}
            modalState={searchModalState}
            onSortbyChange={handleSortbyChange}
            onLinkClicked={(supplier) => handleMakeURL(supplier?.id)}
            withPagination={{
              simple: true,
              currentPage: searchModalpage,
              isLastPage:
                searchModalpage === Object.values(paginatedSuppliers).length,
              onPrev: () => {
                setSearchModalPage((page) => (page > 1 ? page - 1 : 1));
              },
              onNext: () => {
                setSearchModalPage((page) => page + 1);
              },
            }}
          />
        )}
      </Panel>

      {isOpen && (
        <OnboardingCarousel isOpen hide={close} slides={TOP_SUPPLIERS_SLIDES} />
      )}
    </ErrorBoundary>
  );
};

export default SupplierWidget;
