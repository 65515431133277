import { IHeartbeatPrimaryChartProps } from './HeartbeatPrimaryChart';
import { METRICS } from '@tapestry/shared/constants';
import { Maybe } from 'graphql/jsutils/Maybe';
import { dateTime } from '@tapestry/shared/utils';
import { APP_DEFAULT_TIMEZONE } from '@tapestry/shared/constants';
import { HearbeatComparisonKey } from '@tapestry/types';

export const getComparisonMetricUnit = (
  comparisonVariable: IHeartbeatPrimaryChartProps['comparisonVariable']
) => {
  const isComparingMeasures =
    comparisonVariable.key === HearbeatComparisonKey.Measure;

  if (isComparingMeasures) {
    const comparedAgainstMeasure = METRICS.find(
      (metric) => metric.key === comparisonVariable.value
    );

    return comparedAgainstMeasure?.unit || null;
  }

  return null;
};

/**
 * Formats a text caption of the chart used by screen readers and tests
 */
export const getChartCaption = (
  shopName: Maybe<string>,
  measureLabel: string,
  startDate: string,
  endDate: string,
  currentShopTimezone: string
) => {
  const start = dateTime
    .convertToTimezone(startDate, currentShopTimezone || APP_DEFAULT_TIMEZONE)
    .format('dddd, D MMMM YYYY');

  const end = dateTime
    .convertToTimezone(endDate, currentShopTimezone || APP_DEFAULT_TIMEZONE)
    .format('dddd, D MMMM YYYY');

  return `${shopName} daily ${measureLabel.toLowerCase()} between ${start} and ${end}`;
};
