import { IUser } from '@tapestry/shared/graphql';
import { Avatar } from '@tapestry/weave';
import * as React from 'react';

interface IUserInfoProps {
  user: Pick<IUser, 'first_name' | 'last_name' | 'email' | 'photo_url'> | null;
}

const UserInfo: React.FC<IUserInfoProps> = ({ user }) => (
  <div className="flex items-center overflow-x-hidden">
    <Avatar src={user?.photo_url} />

    <div className="xs:ml-4 ml-2 truncate text-left">
      <p className="font-semibold text-black">
        {user?.first_name} {user?.last_name}
      </p>
      <span className="truncate" title={user?.email || ''}>
        {user?.email}
      </span>
    </div>
  </div>
);

export { UserInfo };
