import { IDataValuePlaceholder, PrimaryChartDataDate } from '@tapestry/types';
import { Dayjs } from 'dayjs';
import {
  API_DATETIME_DAY_FORMAT,
  API_DATETIME_INTRADAY_FORMAT,
} from '../api-date-time-format';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

dayjs.extend(isSameOrBefore);

const DAY_JS_MONDAY = 1;

export const makeHourPlaceholder = (startParsedHour: Dayjs) => {
  const hourPlaceholder: IDataValuePlaceholder[] = [];
  const sixtyMins = 60;
  const fiveMins = 5;

  for (let mins = 0; mins < sixtyMins; mins = mins + fiveMins) {
    const dayData: IDataValuePlaceholder = {
      name: startParsedHour
        .add(mins, 'minute')
        .format(API_DATETIME_INTRADAY_FORMAT) as PrimaryChartDataDate,
      value: null,
    };
    hourPlaceholder.push(dayData);
  }

  return hourPlaceholder;
};

export const makeDayPlaceholder = (start: Dayjs) => {
  const dayPlaceholder: IDataValuePlaceholder[] = [];
  const twentyThreeHours = 23;

  for (let hours = 0; hours <= twentyThreeHours; hours++) {
    const dayData: IDataValuePlaceholder = {
      name: start
        .add(hours, 'hour')
        .format(API_DATETIME_INTRADAY_FORMAT) as PrimaryChartDataDate,
      value: null,
    };

    dayPlaceholder.push(dayData);
  }

  return dayPlaceholder;
};

export const makeGeneralPlaceholder = (start: Dayjs, diffNumber: number) => {
  const placeholderPeriodArray: IDataValuePlaceholder[] = [];

  // * the api returns UTC offsets
  for (let idx = 0; idx <= diffNumber; idx++) {
    const dayData: IDataValuePlaceholder = {
      name: start
        .add(idx, 'day')
        .format(API_DATETIME_DAY_FORMAT) as PrimaryChartDataDate,
      value: null,
    };
    placeholderPeriodArray.push(dayData);
  }

  return placeholderPeriodArray;
};

export const makeWeeklyPlaceholder = (start: Dayjs, end: Dayjs) => {
  const placeholderPeriodArray: IDataValuePlaceholder[] = [];
  const isMonday = start.day() === DAY_JS_MONDAY; //as per DayJS docs;

  let startingMonday = isMonday
    ? start
    : start.add(1, 'week').startOf('isoWeek');

  for (
    ;
    startingMonday.isSameOrBefore(end);
    startingMonday = startingMonday.add(1, 'week')
  ) {
    const name = startingMonday.format(
      API_DATETIME_DAY_FORMAT
    ) as PrimaryChartDataDate;

    const dayData: IDataValuePlaceholder = {
      name,
      value: null,
    };

    placeholderPeriodArray.push(dayData);
  }

  return placeholderPeriodArray;
};
