import { CheckCircleIcon } from '@tapestry/shared/icons';
import { Button, Modal } from '@tapestry/weave';
import * as React from 'react';
import { UseFormGetValues } from 'react-hook-form';
import { SupplierInsightInvitationForm } from '../permission-wizard-types';

interface IConfirmationScreenProps {
  onDone: VoidFunction;
  getValues: UseFormGetValues<SupplierInsightInvitationForm>;
}

const ConfirmationScreen: React.FC<IConfirmationScreenProps> = ({
  onDone,
  getValues,
}) => (
  <div className="flex flex-col items-center justify-center pb-6">
    <div className="text-green h-16 w-16">
      <CheckCircleIcon fillColor="currentColor" />
    </div>
    <Modal.Title>
      <p className="mt-6">Access sent</p>
    </Modal.Title>
    <Modal.Description>
      <span className="block">
        An email has been sent to{getValues().email ? ':' : ' their email'}
      </span>

      {getValues().email ? (
        <span className="block text-black">{getValues().email}</span>
      ) : null}
      <span className="block pt-4">
        We will let you know when {getValues()?.firstName || 'they'} accept
        {getValues()?.firstName ? 's' : ''}&nbsp; this invitation.
        <br />
        You can change permissions or revoke access at any time.
      </span>
    </Modal.Description>

    <Button
      status="primary"
      rounded="rounded-full"
      className="mt-10 text-white"
      onClick={onDone}
    >
      Done
    </Button>
  </div>
);

export { ConfirmationScreen };
