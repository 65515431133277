import { DollarIcon, PercentIcon } from '@tapestry/shared/icons';
import { MetricUnit } from '@tapestry/types';

const mapUnitSymbolToIcon = (unit: MetricUnit | undefined) => {
  switch (unit) {
    case '$':
    case 'AUD$':
      return DollarIcon;

    case '%':
      return PercentIcon;

    default:
      return;
  }
};

export { mapUnitSymbolToIcon };
