import { FC } from 'react';
import { ExportIcon } from '@tapestry/shared/icons';

interface IExportButtonProps {
  onClick: () => void;
}

const ExportButton: FC<IExportButtonProps> = ({ onClick }) => (
  <button
    type="button"
    className="border-gray-border hover:border-primary focus:border-primary active:border-primary-dark flex flex-shrink-0 items-center gap-4 rounded-full border-2 bg-white p-4 font-bold text-black shadow-sm focus:outline-none lg:px-6 lg:py-4"
    onClick={onClick}
    onKeyDown={({ key }) => {
      if (key === 'Enter') {
        onClick();
      }
    }}
  >
    <span className="sr-only">Export report</span>
    <ExportIcon className="h-5 w-5 text-black" fillColor="currentColor" />
    <span className="hidden md:inline-block">Export</span>
  </button>
);

export { ExportButton };
export type { IExportButtonProps };
