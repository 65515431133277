import React from 'react';
import { useAppMediaQuery } from '@tapestry/weave';
import isEmpty from 'lodash/isEmpty';
import { IMetric, Nullable } from '@tapestry/types';

interface IMetricOptionsSelectorProps {
  metricVariants: IMetric['variants'] | null;
  onOptionClick: (slug: string) => void;
  activeVariantSlug: Nullable<string>;
}

export const MetricOptionsSelector = ({
  metricVariants,
  onOptionClick,
  activeVariantSlug,
}: IMetricOptionsSelectorProps) => {
  const { isPhone } = useAppMediaQuery();

  const handleVariantClick = (slug: string) => {
    onOptionClick(slug);
  };

  return !metricVariants || isEmpty(metricVariants) ? null : (
    <div>
      <div className="mx-auto flex items-center space-x-px px-4 md:w-max md:px-0">
        {metricVariants.map((option, idx) => {
          const lastItem = metricVariants.length - 1;

          return (
            <button
              key={option.slug}
              className={`${
                option.slug === activeVariantSlug
                  ? 'bg-blue text-white'
                  : 'text-gray-text bg-white'
              } ${idx === 0 ? 'rounded-l-md' : ''} ${
                idx === lastItem ? 'rounded-r-md' : ''
              } flex-grow py-2 px-4 font-bold capitalize tracking-wide`}
              onClick={() => {
                handleVariantClick(option.slug);
              }}
              onKeyPress={({ key }) => {
                if (key === 'Enter') {
                  handleVariantClick(option.slug);
                }
              }}
            >
              {isPhone && option.mobileLabel
                ? option.mobileLabel
                : option.label}
            </button>
          );
        })}
      </div>
    </div>
  );
};
