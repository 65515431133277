import { HeartbeatQueryParamsState } from '@tapestry/types';
import findKey from 'lodash/findKey';

export const removeFaultyParam = (
  currentQS: HeartbeatQueryParamsState,
  activeThreadId: string
) => {
  const notFoundProperty = findKey(currentQS, (qp) => qp === activeThreadId);

  return notFoundProperty
    ? { ...currentQS, [notFoundProperty]: undefined }
    : currentQS;
};
