import { QueryHookOptions } from '@apollo/client';
import {
  IGetHeartbeatChartMainData,
  IGetHeartbeatChartMainDataVariables,
  useGetHeartbeatChartMainData,
} from '@tapestry/shared/graphql';
import { safeJSONParse } from '@tapestry/shared/utils';
import { IComparisonQueryVariable } from '@tapestry/types';

type ExtendedIGetHeartbeatChartMainDataVariables =
  IGetHeartbeatChartMainDataVariables &
    Pick<IComparisonQueryVariable, 'compareFilter'>;

type UseChartOptions = QueryHookOptions<
  IGetHeartbeatChartMainData,
  ExtendedIGetHeartbeatChartMainDataVariables
> & { variables: ExtendedIGetHeartbeatChartMainDataVariables };

const usePrimaryChartData = ({
  variables: { compareFilter, measureCompare, ...variables },
  ...options
}: UseChartOptions) => {
  const filters =
    typeof variables?.filters === 'string'
      ? safeJSONParse(variables.filters)
      : variables.filters;
  const hasId = filters?.groupId || filters?.shopId;

  // TODO[medium]: remove this hacky logic once BE api remove all compare varibales
  const mainChartVariables: IGetHeartbeatChartMainDataVariables = {
    ...variables,
    filters,
    startDateCompare: variables.startDate,
    endDateCompare: variables.endDate,
  };

  const compareChartVariable: IGetHeartbeatChartMainDataVariables = {
    ...variables,
    filters: { ...filters, ...compareFilter },
    startDate: variables.startDateCompare,
    endDate: variables.endDateCompare,
    measure: measureCompare || variables.measure,
  };

  const {
    data: mainChartData,
    loading: isMainChartLoading,
    error: mainChartError,
    refetch,
  } = useGetHeartbeatChartMainData({
    skip: !hasId,
    ...options,
    variables: mainChartVariables,
  });

  const {
    data: comparedChartData,
    loading: isLoadingComparedChartData,
    error: comparedChartError,
  } = useGetHeartbeatChartMainData({
    skip: !hasId || isMainChartLoading || !!mainChartError,
    ...options,
    variables: compareChartVariable,
  });

  return {
    data: {
      mainChart: {
        values: mainChartData?.heartbeatChartMainCompareV2?.data ?? [],
        summary: mainChartData?.heartbeatChartMainCompareV2?.summary,
      },

      comparedChart: {
        values: comparedChartData?.heartbeatChartMainCompareV2?.data ?? [],
        summary: comparedChartData?.heartbeatChartMainCompareV2?.summary,
      },
    },
    loading: {
      mainChart: isMainChartLoading,
      comparedChart: isLoadingComparedChartData,
    },
    error: mainChartError || comparedChartError,
    refetch,
  };
};

export { usePrimaryChartData };
