import { FC, useState } from 'react';
import { LoadingSpinnerIcon, SortDownIcon } from '@tapestry/shared/icons';
import { Modal, ModalState, ResponsiveDropdownMenu } from '@tapestry/weave';
import {
  IPaginationFooterProps,
  PaginationFooter,
  SearchInput,
} from '@tapestry/shared/components';
import isEmpty from 'lodash/isEmpty';
import { parseToCurrencyString } from '@tapestry/shared/utils';
import { useDebouncedCallback } from 'use-debounce';
import { IMetric } from '@tapestry/types';
import { useEffectAfterMount, useSortMenu } from '@tapestry/shared/hooks';
import {
  IDataValueId,
  IGetHeartbeatBarChartData,
  IGetHeartbeatTagCollection,
  IGetHeartbeatTopShops,
} from '@tapestry/shared/graphql';
import { twMerge } from 'tailwind-merge';
import { useRouter } from 'next/router';
import Link from 'next/link';

type VisualiSearchModalAcceptedData =
  | IGetHeartbeatBarChartData['heartbeatChartBarV3']['data']
  | IGetHeartbeatTagCollection['heartbeatChartTagCollectionV2']['data']
  | IGetHeartbeatTopShops['heartbeatChartTopShopsV2']['data'];

export interface IVisualizationSearchModalProps {
  data: VisualiSearchModalAcceptedData;
  defaultSortBy: string;
  isLoading: boolean;
  modalState: ModalState;
  onInputChange: (inputValue: string) => void;
  onSortbyChange: (sortBy: string) => void;
  title: string;
  onItemClick?: (item: IDataValueId | undefined) => void;
  onLinkClicked?: (item: IDataValueId | undefined) => string;
  unit?: IMetric['unit'];
  readOnly?: boolean;
  withPagination?: IPaginationFooterProps;
}

export const VisualizationSearchModal: FC<IVisualizationSearchModalProps> = ({
  title,
  data,
  defaultSortBy,
  isLoading = false,
  onInputChange,
  onSortbyChange,
  modalState,
  onItemClick,
  onLinkClicked,
  unit,
  readOnly,
  withPagination,
}) => {
  const router = useRouter();
  const [inputValue, setInputValue] = useState('');
  const debouncedHandleOnInputChange = useDebouncedCallback((value) => {
    onInputChange(value);
  }, 250);

  const {
    items,
    state: { sortBy },
  } = useSortMenu({
    defaultItem: defaultSortBy,
    onChange: onSortbyChange,
  });

  const shouldShowPagination =
    !isLoading &&
    withPagination &&
    withPagination.lastPage &&
    withPagination.lastPage > 1;

  const handleMakeLinkOnRender = (item: IDataValueId | undefined) => {
    if (onLinkClicked) {
      return onLinkClicked(item);
    }

    return '';
  };

  const handleOnItemClick = (item: IDataValueId | undefined) => {
    if (onItemClick) {
      onItemClick(item);
    }

    const url = handleMakeLinkOnRender(item);
    router.push(url);

    modalState.close();
  };

  useEffectAfterMount(() => {
    debouncedHandleOnInputChange.callback(inputValue);
  }, [inputValue, debouncedHandleOnInputChange]);

  return (
    <Modal state={modalState}>
      <Modal.Dialog>
        <Modal.Content>
          <Modal.Title>{title}</Modal.Title>

          <div className="flex justify-between items-center gap-4">
            <SearchInput
              placeholder="Search by keyword"
              fullWidth
              searchTerm={inputValue}
              onChange={(val) => setInputValue(val)}
            />

            <ResponsiveDropdownMenu>
              <ResponsiveDropdownMenu.Button
                title="Sort"
                className="h-8 rounded-full p-2 hover:bg-gray-300 focus:bg-gray-300 focus:outline-none sm:h-6 sm:p-1"
              >
                <span className="sr-only">Sort</span>
                <SortDownIcon />
              </ResponsiveDropdownMenu.Button>

              <ResponsiveDropdownMenu.Menu>
                {items.map((item) => (
                  <ResponsiveDropdownMenu.StyledMenuItem
                    key={item.label}
                    label={item.label}
                    onClick={item.onclick}
                    isActive={item.id === sortBy}
                  />
                ))}
              </ResponsiveDropdownMenu.Menu>
            </ResponsiveDropdownMenu>
          </div>

          {isLoading ? (
            <div className="mt-8 flex justify-center">
              <div className="h-12 w-12 text-primary">
                <LoadingSpinnerIcon fillColor="currentColor" />
              </div>
            </div>
          ) : (
            <ul className="mt-8">
              {isEmpty(data) ? (
                <li>No data for the selected time</li>
              ) : (
                data?.map((item) => (
                  <li key={`${item.id}${item?.name || item?.title}`}>
                    <Link
                      href={handleMakeLinkOnRender(item)}
                      className={twMerge(
                        'group flex w-full cursor-pointer flex-row items-center justify-between',
                        'border-b p-2 text-left text-sm focus:outline-none md:text-base',
                        readOnly && 'pointer-events-none'
                      )}
                      onClick={() => handleOnItemClick(item)}
                    >
                      <span className="group-hover:text-primary group-focus:text-primary mr-2 font-medium">
                        {item?.name || 'N/A'}
                      </span>
                      <span className="flex items-center">
                        <span className="mr-4 text-primary">
                          {parseToCurrencyString(
                            item?.value || 0,
                            unit?.symbol,
                            unit?.position
                          )}
                        </span>
                      </span>
                    </Link>
                  </li>
                ))
              )}
            </ul>
          )}
        </Modal.Content>

        {shouldShowPagination && (
          <Modal.Footer justify="justify-center">
            <PaginationFooter {...withPagination} />
          </Modal.Footer>
        )}
      </Modal.Dialog>
    </Modal>
  );
};

export default VisualizationSearchModal;
