import { ObjectValues } from '@tapestry/types';

export const HEARTBEAT_TICKER_INTERVAL = {
  FiveMins: 'five_minutes',
  Hourly: 'hourly',
  Daily: 'daily',
  Weekly: 'weekly', // currently only used by Avg weekly sales measure
} as const;
export type HeartbeatTickerInterval = ObjectValues<
  typeof HEARTBEAT_TICKER_INTERVAL
>;

export const getTickerIntervalVariable = (
  isIntraDay: boolean,
  isHourly: boolean
) => {
  if (isHourly) {
    return HEARTBEAT_TICKER_INTERVAL.FiveMins;
  }

  if (isIntraDay) {
    return HEARTBEAT_TICKER_INTERVAL.Hourly;
  }

  return HEARTBEAT_TICKER_INTERVAL.Daily;
};
