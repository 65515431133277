import { FC, useEffect } from 'react';
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import Skeleton from 'react-loading-skeleton';
import { MainHeading, useAppMediaQuery, Panel, Card } from '@tapestry/weave';
import {
  WEEK_DAYS,
  HOURS_IN_DAYS,
  HANK_UNI_URLS,
  SUPPORT_EMAIL,
} from '@tapestry/shared/constants';
import { HeartbeatVisualizationQueriesBaseVariables } from '../../constants';
import { useMediaQuery } from 'react-responsive';
import { ErrorBoundary, useToast, useUIContext } from '@tapestry/shared/client';
import { useGetHeartbeatHeatmapChartDataLazyQuery } from '@tapestry/shared/graphql';
import { HeartbeatQueryParams, IMetric, Visualization } from '@tapestry/types';
import {
  parseToCurrencyString,
  safeJSONParse,
  SetQuery,
} from '@tapestry/shared/utils';
import {
  caculateOpacity,
  calculatePercentage,
  createLookupName,
  extractOpeningHours,
  findValueInList,
  getMaxValueOfData,
} from './HeartbeatHeatmapChartUtils';
import { useLongLoading } from '@tapestry/shared/hooks';
import { ChartErrorView } from '../ChartErrorView/ChartErrorView';
import { HankGuideInfo } from '@tapestry/shared/components';
import { loadable } from '@tapestry/shared/lazy-load';
import isEmpty from 'lodash/isEmpty';
import { captureException } from '@sentry/nextjs';
import { ExclamationCircleIcon } from '@tapestry/shared/icons';
const ReactTooltip = loadable(() => import('react-tooltip'), {
  chunkName: 'tooltip',
});

dayjs.extend(isoWeek);

const NoResultsWarning: FC = () => (
  <Card bgColor="bg-gray-100">
    <div className="flex">
      <ExclamationCircleIcon className="h-6 w-auto flex-shrink-0" />
      <p className="ml-4">
        We're sorry, but we couldn't retrieve your data at this time. <br />
        This could be because there's no data available, or there might be an
        issue with our servers. <br />
        If you believe this to be an error, contact{' '}
        <a href={`mailto:${SUPPORT_EMAIL}`} className="text-primary underline">
          support
        </a>
        .
      </p>
    </div>
  </Card>
);

export interface IHeartbeatHeatmapChartProps {
  title: string | null;
  unit: IMetric['unit'];
  queryVariables: HeartbeatVisualizationQueriesBaseVariables;
  setURLQuery: SetQuery<HeartbeatQueryParams>;
}

/**
 * A heatmap chart
 */
const HeartbeatHeatmapChart = ({
  title,
  unit,
  queryVariables,
}: IHeartbeatHeatmapChartProps) => {
  /**
   * Hooks
   */
  const { addToast } = useToast();
  const [getChartData, { data, loading }] =
    useGetHeartbeatHeatmapChartDataLazyQuery({
      onError: () => {
        addToast({
          type: 'error',
          content: `An error has occured whilst retrieving the data for best times. `,
        });
      },
      onCompleted: (data) => {
        const chartData = data?.heartbeatChartHeatmapGridV2?.data || [];

        if (isEmpty(chartData)) {
          captureException('No data found for this query', {
            level: 'info',
            tags: { visualisation: Visualization.BestTimes },
          });
        }
      },
    });
  const { loadingMessage } = useLongLoading({
    mode: 'interval',
    isLoading: loading,
  });
  const chartData = data?.heartbeatChartHeatmapGridV2?.data || [];
  const { isPhone } = useAppMediaQuery();
  const isTablet: boolean = useMediaQuery({ minWidth: 640 });
  const isComputer: boolean = useMediaQuery({ minWidth: 992 });
  const [
    {
      threadTypeThemeColors: { backgroundColor },
    },
  ] = useUIContext();

  /**
   * Const
   */
  const maxValue = getMaxValueOfData(chartData);
  const { openingHour, closingHour } = extractOpeningHours(chartData);
  const workingDay = HOURS_IN_DAYS.slice(
    HOURS_IN_DAYS.indexOf(openingHour),
    HOURS_IN_DAYS.indexOf(closingHour) + 1
  );

  const makeDayResponsive = (day: string): string => {
    if (isComputer) {
      return day;
    } else if (isTablet) {
      return day.slice(0, 3);
    }

    return day[0];
  };

  useEffect(() => {
    const filters = safeJSONParse(queryVariables?.filters);
    const hasId = filters?.groupId || filters?.shopId;

    if (hasId) {
      getChartData({ variables: queryVariables });
    }
  }, [getChartData, queryVariables]);

  return (
    <ErrorBoundary errorView={<ChartErrorView />}>
      <Panel>
        <header className="mb-5 flex items-center justify-between">
          <div className="flex items-center">
            <MainHeading>
              <span>{title ?? 'Best times of day'}</span>
              <HankGuideInfo
                title="Best times of day"
                link={HANK_UNI_URLS.heartbeat.bestTimes}
                className="ml-2 inline-block"
              />
            </MainHeading>
          </div>
        </header>

        {isEmpty(chartData) && !loading ? <NoResultsWarning /> : null}

        {loading || !isEmpty(chartData) ? (
          <>
            <div className="-mx-2 flex flex-row flex-nowrap">
              {/* Days */}
              <div className="w-1/8 px-2 text-center uppercase">&nbsp;</div>
              {WEEK_DAYS.map((day) => (
                <div
                  key={day}
                  className="w-1/8 px-2 text-center text-sm uppercase"
                >
                  {makeDayResponsive(day)}
                </div>
              ))}
            </div>

            {/* Grids */}
            <div className="relative">
              {loadingMessage ? (
                <p className="absolute top-1/2 left-1/2 z-10 -translate-x-1/2 -translate-y-1/2 transform text-center font-medium tracking-wide text-gray-700">
                  {loadingMessage}
                </p>
              ) : null}

              {workingDay.map((h, idx) => (
                // Vertical
                <div key={idx} className="-mx-2 mt-2 flex flex-row flex-nowrap">
                  <div className="w-1/8 px-2 text-left">
                    {dayjs().hour(h).format('h')}
                    <span className="text-xs">
                      {dayjs().hour(h).format('A')}
                    </span>
                  </div>
                  {/* WEEK_DAYS - horizontal */}
                  {[1, 2, 3, 4, 5, 6, 7].map((d, idx) => {
                    const lookupName = createLookupName(d, h);
                    const value = findValueInList(lookupName, chartData);
                    const percentOfTotal = calculatePercentage(value, maxValue);
                    const opacity = caculateOpacity(percentOfTotal);

                    return (
                      <div
                        key={idx}
                        className={`w-1/8 group ${isPhone ? 'px-1' : 'px-2'}`}
                      >
                        {loading ? (
                          <Skeleton height="2rem" />
                        ) : (
                          <>
                            <div
                              data-tip
                              data-for={`infoTip${d}-${h}`}
                              className={`h-8 w-full ${
                                value > 0 ? backgroundColor : 'bg-gray-light'
                              }
                        `}
                              style={{
                                opacity,
                              }}
                            />
                            <ReactTooltip
                              id={`infoTip${d}-${h}`}
                              place="top"
                              effect="solid"
                            >
                              <ul>
                                <li>
                                  {dayjs()
                                    .isoWeekday(d)
                                    .hour(h)
                                    .format('ddd h A')}
                                </li>
                                <li>
                                  {parseToCurrencyString(
                                    value,
                                    unit?.symbol,
                                    unit?.position
                                  )}
                                </li>
                              </ul>
                            </ReactTooltip>
                          </>
                        )}
                      </div>
                    );
                  })}
                </div>
              ))}
            </div>
          </>
        ) : null}
      </Panel>
    </ErrorBoundary>
  );
};

export { HeartbeatHeatmapChart };
export default HeartbeatHeatmapChart;
