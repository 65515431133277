import {
  HEARTBEAT_TICKER_INTERVAL,
  HeartbeatTickerInterval,
} from '../get-ticker-interval';
import {
  makeDayPlaceholder,
  makeGeneralPlaceholder,
  makeHourPlaceholder,
  makeWeeklyPlaceholder,
} from './placeholder-maker-utils';
import { dateTime } from '@tapestry/shared/utils';
import dayjs from 'dayjs';

export const getDiffWeeks = (startDate: string, endDate: string) => {
  return dayjs(endDate).diff(dayjs(startDate), 'week');
};

export const getDiffDays = (startDate: string, endDate: string) => {
  return dayjs(endDate).diff(dayjs(startDate), 'day');
};

export const getDiffHours = (startDate: string, endDate: string) => {
  return dayjs(endDate).diff(dayjs(startDate), 'hour');
};

function getParsedStartingHour(
  startTime: string,
  endTime: string,
  currentShopTimezone: string
) {
  const isHourly = getDiffHours(startTime, endTime) === 0;
  const startParsedHour = dateTime.convertToTimezone(
    isHourly ? startTime : undefined,
    currentShopTimezone
  );

  return startParsedHour;
}

/**
 * Creates a placeholder data array for given date range.
 * This allows us to have flexibility in api data parsing
 */
export const createDateArrayForPeriodStarting = (
  startTime: string,
  endTime: string,
  tickerInterval: HeartbeatTickerInterval,
  currentShopTimezone: string
) => {
  const start = dateTime.convertToTimezone(startTime, currentShopTimezone);
  const end = dateTime.convertToTimezone(endTime, currentShopTimezone);

  switch (tickerInterval) {
    case HEARTBEAT_TICKER_INTERVAL.Weekly:
      return makeWeeklyPlaceholder(start, end);

    case HEARTBEAT_TICKER_INTERVAL.Hourly:
      return makeDayPlaceholder(start);

    case HEARTBEAT_TICKER_INTERVAL.FiveMins: {
      const startParsedHour = getParsedStartingHour(
        startTime,
        endTime,
        currentShopTimezone
      );

      return makeHourPlaceholder(startParsedHour);
    }

    default: {
      const diffDay = getDiffDays(startTime, endTime);

      return makeGeneralPlaceholder(start, diffDay);
    }
  }
};
