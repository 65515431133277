import { RETAIL_ONBOARDING_ANNOUNCEMENT } from '@tapestry/shared/constants';
import { Annoucement } from '@tapestry/types';

export const getHeartbeatAnnouncement = ({
  onAction,
  onClose,
  shopAnnouncementBanner,
}): Annoucement => {
  return {
    ...RETAIL_ONBOARDING_ANNOUNCEMENT,
    backgroundImage:
      shopAnnouncementBanner || RETAIL_ONBOARDING_ANNOUNCEMENT.backgroundImage,
    buttonAction: onAction,
    onClose,
  };
};
