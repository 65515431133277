import { FC } from 'react';
import { IDataTopShop } from '@tapestry/shared/graphql';
import { ImageIcon } from '@tapestry/shared/icons';
import { Pill } from '@tapestry/weave';
import Skeleton from 'react-loading-skeleton';
import { parseToCurrencyString } from '@tapestry/shared/utils';
import { Maybe } from 'graphql/jsutils/Maybe';
import { IMetric, Nullable } from '@tapestry/types';
import Link from 'next/link';
import Image from 'next/image';
import { mapUnitSymbolToIcon } from '../../utils';

interface IShopListItemProps {
  shop: IDataTopShop | undefined;
  handleMakeURL: (shopId: Maybe<string>) => string;
  activeMeasureUnit: Nullable<IMetric['unit']>;
}

export const TopShopsLoadingState = () => {
  return (
    <div
      className={`min-w-56 max-w-56 mx-auto box-border block w-56 cursor-pointer overflow-hidden rounded-lg bg-white shadow-md focus:outline-none focus:ring-2`}
    >
      <div className="-mt-1 w-full ">
        <Skeleton height={100} />
      </div>

      <div className="p-4 text-left">
        <h3
          className={`truncate-2-lines text-base font-bold leading-6 text-black sm:text-lg`}
        >
          <Skeleton />
        </h3>
        <p className="w-1/2">
          <Skeleton />
        </p>

        <div className="mt-3 flex flex-row items-end justify-between">
          <Skeleton circle height={40} width={40} />

          <Skeleton width={100} height={16} />
        </div>
      </div>
    </div>
  );
};

export const ShopListItem: FC<IShopListItemProps> = ({
  shop,
  handleMakeURL,
  activeMeasureUnit,
}) => {
  const unitSymbol = activeMeasureUnit?.symbol;
  const unitSymbolPosi = activeMeasureUnit?.position;
  const Icon = mapUnitSymbolToIcon(unitSymbol);

  return (
    <div
      key={shop?.id}
      className={`min-w-56 max-w-56 border-gray-border h-72 w-56 rounded-lg border bg-white focus:outline-none focus:ring-2`}
    >
      <Link
        href={handleMakeURL(shop?.id)}
        className="flex h-full w-full flex-col"
        data-cy={`top-shop-card-${shop?.id}`}
      >
        <div className="min-h-36 h-36 max-h-36 w-full flex-shrink-0">
          {shop?.background ? (
            <Image
              src={shop?.background ?? ''}
              alt={`${shop?.name} shop`}
              className="h-full w-full rounded-t-lg object-cover object-center"
              width={222}
              height={144}
            />
          ) : (
            <div className="flex h-full items-center justify-center rounded-t-lg bg-gray-100 text-gray-200">
              <ImageIcon className="h-16 w-auto" fillColor="currentColor" />
            </div>
          )}
        </div>

        <div className="flex h-full flex-col justify-between p-4 text-left">
          <h3
            className={`truncate-2-lines text-base font-bold leading-6 text-black`}
          >
            {shop?.name}
          </h3>

          <div
            className={`mt-4 flex flex-row items-end ${
              shop?.logo ? 'justify-between' : 'justify-end'
            }`}
          >
            {shop?.logo && (
              <img
                src={shop?.logo}
                alt="shop's logo"
                className="min-w-10 lg:h-15 lg:w-15 lg:min-w-15 h-10 w-10 rounded-full bg-white object-contain object-center"
              />
            )}

            <Pill
              label={parseToCurrencyString(
                shop?.value || 0,
                unitSymbol,
                unitSymbolPosi
              )}
              size="small"
              textColor="#000"
              bgColor="bg-gray-100"
              iconLeft={
                Icon ? (
                  <Icon className="h-full w-auto" fillColor="currentColor" />
                ) : null
              }
            />
          </div>
        </div>
      </Link>
    </div>
  );
};
