import * as React from 'react';
import { Button, ListItem } from '@tapestry/weave';
import {
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form';
import { METRICS } from '@tapestry/shared/constants';
import { IMetric, THREAD_TYPE } from '@tapestry/types';
import { TickIcon } from '@tapestry/shared/icons';
import { twMerge } from 'tailwind-merge';
import { SupplierInsightInvitationForm } from '../permission-wizard-types';
import { checkMetricPermissions } from '../../../../../utils';
import { getCurrentAppInfo } from '@tapestry/shared/utils';

const {
  appInfo: { slug },
} = getCurrentAppInfo();

const onlySupplierMeasures = (measure: IMetric) =>
  checkMetricPermissions(measure, THREAD_TYPE.SUPPLIER, null, {
    app: slug,
  });

interface ISelectProductsScreenProps {
  getValues: UseFormGetValues<SupplierInsightInvitationForm>;
  register: UseFormRegister<SupplierInsightInvitationForm>;
  setValue: UseFormSetValue<SupplierInsightInvitationForm>;
}

const SetPermissionsScreen: React.FC<ISelectProductsScreenProps> = ({
  getValues,
  register,
  setValue,
}) => {
  const filteredMeasures = METRICS.filter(onlySupplierMeasures);
  const allFilterredKeys = React.useMemo(
    () => filteredMeasures.map((measure) => measure.key),
    [filteredMeasures]
  );

  const [selectedPermissions, setSelectedPermissions] = React.useState(
    (getValues?.().permissions as Array<IMetric['key']>) || allFilterredKeys
  );

  const hasAllSelected = filteredMeasures.length === selectedPermissions.length;

  const handleToggleButtonClick = (key: IMetric['key']) => {
    setSelectedPermissions((pervPermissionKey) => {
      const isSelected = !!pervPermissionKey.find((_key) => _key === key);

      const newValue = isSelected
        ? pervPermissionKey.filter((_key) => _key !== key)
        : [...pervPermissionKey, key];

      setValue('permissions', newValue);

      return newValue;
    });
  };

  const handleSelectAll = () => {
    setSelectedPermissions(allFilterredKeys);
    setValue('permissions', allFilterredKeys);
  };

  const handleUnselectAll = () => {
    setSelectedPermissions([]);
    setValue('permissions', []);
  };

  React.useEffect(() => {
    register('permissions', { value: selectedPermissions });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div className="mb-2 flex items-center justify-end space-x-2">
        <Button
          status="text"
          spacing="none"
          bold
          className={hasAllSelected ? '' : 'text-blue'}
          onClick={handleSelectAll}
        >
          Select All
        </Button>
        <Button
          status="text"
          spacing="none"
          bold
          className={hasAllSelected ? 'text-blue' : ''}
          onClick={handleUnselectAll}
        >
          Unselect All
        </Button>
      </div>

      <div className="no-scrollbar flex max-h-[500px] flex-col gap-2 overflow-hidden overflow-y-auto">
        {filteredMeasures.map(({ key: metricKey, title, icon }) => {
          const isSelected = selectedPermissions.find(
            (_key) => _key === metricKey
          );

          return (
            <ListItem key={metricKey} tag="div" justify="between">
              <div
                className="flex w-full cursor-pointer items-center justify-between"
                onClick={() => handleToggleButtonClick(metricKey)}
                onKeyDown={({ key }) => {
                  if (key === 'Enter') {
                    handleToggleButtonClick(metricKey);
                  }
                }}
              >
                <div className="flex items-center overflow-x-hidden">
                  <span className="bg-blue mr-4 inline-flex h-12 w-12 items-center justify-center rounded-full p-2 text-white">
                    {React.cloneElement(icon as React.ReactElement, {
                      fillColor: '#FFFFFF',
                    })}
                  </span>
                  <p className=" truncate font-semibold text-black">
                    {title || ''}
                  </p>
                </div>
                <div
                  className={twMerge(
                    'border-gray-broder  inline-flex h-7 w-7 items-center justify-center rounded-full border text-white sm:h-10 sm:w-10 sm:p-2',
                    isSelected ? 'bg-green' : ''
                  )}
                >
                  <TickIcon light fillColor="currentColor" />
                </div>
              </div>
            </ListItem>
          );
        })}
      </div>
    </div>
  );
};

export { SetPermissionsScreen };
