import { dateTime } from '@tapestry/shared/utils';
import { IsoStringWithTZOffset, ObjectValues } from '@tapestry/types';

export type DateObject = {
  startDate: IsoStringWithTZOffset;
  endDate: IsoStringWithTZOffset;
};

const SHIFTING_PERIOD = {
  calendarYear: 'calendarYear',
  quarter: 'quarter',
  month: 'month',
} as const;

export type ShiftingPeriod = ObjectValues<typeof SHIFTING_PERIOD>;

const QUARTER_LENGTH_IN_DAYS = [90, 91, 92];
const YEAR_LENGTH_IN_DAYS = [365, 366];
// ! Because we are diffing a datetime starting at 00H00 with and datetime ending at 23H59, it adds an extra day to the month, this does not seems to be a problem for the year and quarter
const MAX_MONTH_LENGTH_IN_DAYS = 32;

const isItTheFirstDay = (isoDate: IsoStringWithTZOffset) => {
  const date = isoDate.split('T')[0];

  return dateTime.getDay(date, 'YYYY-MM-DD') === 1;
};

const isItTheLastDayOfAMonth = (isoDate: IsoStringWithTZOffset) => {
  const date = isoDate.split('T')[0];
  const daysInMonth = dateTime.parse(date, 'YYYY-MM-DD').daysInMonth();

  return dateTime.getDay(date, 'YYYY-MM-DD') === daysInMonth;
};

export const getPeriodFromDates = (
  dates: DateObject
): ShiftingPeriod | null => {
  const isFirstDay = isItTheFirstDay(dates.startDate);
  const isLastDay = isItTheLastDayOfAMonth(dates.endDate);

  if (isFirstDay && isLastDay) {
    const periodLengthInDays =
      dateTime.diff(dates.startDate, dates.endDate, 'day') + 1;

    if (YEAR_LENGTH_IN_DAYS.includes(periodLengthInDays)) return 'calendarYear';

    if (QUARTER_LENGTH_IN_DAYS.includes(periodLengthInDays)) return 'quarter';

    if (periodLengthInDays <= MAX_MONTH_LENGTH_IN_DAYS) return 'month';
  }

  return null;
};
