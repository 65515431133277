import isEmpty from 'lodash/isEmpty';
import { DEFAULT_FILTERS } from '../../constants';
import { UserSetting } from '@tapestry/types';
import { IUserProfile } from '@tapestry/shared/graphql';
import { Maybe } from 'graphql/jsutils/Maybe';

/**
 * If the profile default filter is an empty [], it would override the `DEFAULT_FILTERS` which is a incorrect behaviour. This is procting againt that
 */
export const getDefaultFilters = (profile: Maybe<IUserProfile>) => {
  const filtersUserSetting =
    profile?.user_settings?.[UserSetting.applet_analytic_filters];

  const paramsDefaultFilters =
    Array.isArray(filtersUserSetting) && isEmpty(filtersUserSetting)
      ? DEFAULT_FILTERS
      : filtersUserSetting;

  return paramsDefaultFilters;
};
