import { IMetric, THREAD_TYPE } from '@tapestry/types';

export const shouldItBeExportable = (
  measure: IMetric | null,
  activeThreadType: THREAD_TYPE
): boolean => {
  if (!measure || !measure.isExportable) return false;

  const { isExportable } = measure;

  if (Array.isArray(isExportable)) {
    return isExportable.includes(activeThreadType);
  }

  return isExportable;
};
