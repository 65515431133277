import {
  HearbeatComparisonKey,
  HeartbeatComparisonPeriod,
  HeartbeatComparisonQueriesVariable,
  IComparisonQueryVariable,
  IsoStringWithTZOffset,
} from '@tapestry/types';
import { getCompareWithDates } from '../get-compare-with-dates';

const getDefaultVars = (
  startDate: IsoStringWithTZOffset,
  endDate: IsoStringWithTZOffset
) => {
  return {
    measureCompare: null,
    startDateCompare: startDate,
    endDateCompare: endDate,
  };
};

const getPeriodComparisonQueryVariables = (
  startDate: IsoStringWithTZOffset,
  endDate: IsoStringWithTZOffset,
  comparisonVariables: HeartbeatComparisonQueriesVariable,
  currentShopTimezone: string
) => {
  const refDates = { startDate, endDate };
  const isValidUserDefined =
    comparisonVariables.value === HeartbeatComparisonPeriod.UserDefined;

  if (isValidUserDefined && comparisonVariables?.meta) {
    refDates.startDate = comparisonVariables?.meta[0];
    refDates.endDate = comparisonVariables?.meta[1];
  }

  const compareDates = getCompareWithDates(
    refDates,
    comparisonVariables.value as HeartbeatComparisonPeriod,
    currentShopTimezone
  );

  return {
    measureCompare: null,
    ...compareDates,
  };
};

export const getComparisonQueryVariable = (
  comparisonVariables: HeartbeatComparisonQueriesVariable,
  startDate: IsoStringWithTZOffset,
  endDate: IsoStringWithTZOffset,
  currentShopTimezone: string
): IComparisonQueryVariable => {
  switch (comparisonVariables.key) {
    case HearbeatComparisonKey.Measure:
      return {
        ...getDefaultVars(startDate, endDate),
        measureCompare: comparisonVariables.value,
      };

    case HearbeatComparisonKey.GroupId:
      return {
        ...getDefaultVars(startDate, endDate),
        compareFilter: {
          groupId: comparisonVariables.value,
        },
      };

    case HearbeatComparisonKey.ShopId:
      return {
        ...getDefaultVars(startDate, endDate),
        compareFilter: {
          shopId: comparisonVariables.value,
        },
      };

    case HearbeatComparisonKey.Period:
      return getPeriodComparisonQueryVariables(
        startDate,
        endDate,
        comparisonVariables,
        currentShopTimezone
      );

    case HearbeatComparisonKey.ListId:
      return {
        ...getDefaultVars(startDate, endDate),
        compareFilter: {
          listId: comparisonVariables.value,
        },
      };

    default:
      return getDefaultVars(startDate, endDate);
  }
};
